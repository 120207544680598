import { useEffect } from "react";
import { on } from "../utils/on.ts";
import { useSyncRef } from "./useSyncRef.ts";

export const useOn = <E extends keyof WindowEventMap>(
  type: E,
  listener: (ev: WindowEventMap[E]) => any,
  useCapture?: boolean,
) => {
  const listenerRef = useSyncRef(listener);
  useEffect(
    () => on(type, (...props) => listenerRef.current(...props), useCapture),
    [type, listenerRef, useCapture],
  );
};
