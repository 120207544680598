export const run = <T>(cb: () => T) => cb();

// outputs [a, a+1, ..., b]
export const range = (from: number, to: number) => {
  if (to > from) return [...Array(to - from + 1)].map((_, i) => from + i);
  return [...Array(from - to + 1)].map((_, i) => from - i);
};

export const isDef = <T>(a: T): a is NonNullable<T> =>
  a !== undefined && a !== null;

export const listOfNotNull = <T extends unknown>(
  value: (T | null | undefined)[],
) => value.filter(isDef);

export const stableKeys = <K extends string>(map: Record<K, unknown>) =>
  Object.keys(map) as K[];

export const unsafeEntries = <K extends string, V>(entries: Record<K, V>) =>
  Object.entries(entries) as [K, V][];

export const safeCast = <T extends unknown>(value: T) => value;
