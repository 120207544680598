import classNames from "classnames";
import { useSelectedAsset } from "../../../hooks/useSelectedAsset.ts";
import { ItemSectionDetails, SectionDetails } from "../SectionDetails.tsx";
import { ImageGenerationParameters } from "./ImageGenerationParameters.tsx";
import { InformationHeader } from "./InformationHeader.tsx";

export const GeneralInformationSection = ({
  variant = "light",
}: {
  variant?: "light" | "dark";
}) => {
  const { selectedAsset: asset } = useSelectedAsset();

  if (!asset) return null;

  return (
    <SectionDetails name="General" variant={variant}>
      <div className="flex-col">
        <ItemSectionDetails name="Tool used" variant={variant}>
          <InformationHeader image={asset.content} variant={variant} />
        </ItemSectionDetails>
        <ItemSectionDetails name="Created" variant={variant}>
          <div
            className={classNames(
              "label-md-default",
              variant === "dark" ? "text-inverse-primary" : "text-primary",
            )}
          >
            {new Date(asset.created_at).getDate()}/
            {new Date(asset.created_at).getMonth() + 1}/
            {new Date(asset.created_at).getFullYear()}
          </div>
        </ItemSectionDetails>
        <ItemSectionDetails name="Creator" variant={variant}>
          <div
            className={classNames(
              "label-md-default overflow-x-hidden text-ellipsis",
              variant === "dark" ? "text-inverse-primary" : "text-primary",
            )}
          >
            {asset.content.generation_model_params?.user_email ?? ""}
          </div>
        </ItemSectionDetails>
        {asset.content.generation_model_type !== null && (
          <ImageGenerationParameters image={asset.content} variant={variant} />
        )}
      </div>
    </SectionDetails>
  );
};
