import { EditableText } from "../../../../components/EditableText/EditableText.tsx";
import { useAppMutation } from "../../../../http/useAppMutation.ts";
import { useBoard } from "../../hooks/useBoard.ts";

export const CreateHeaderContent = () => {
  const { board } = useBoard();

  const { mutate: nameMutate, isLoading: nameIsLoading } = useAppMutation({
    path: "boards/update",
    invalidate: ["boards", `boards/${board.uuid}`],
  }).mutation;

  return (
    <EditableText
      text={board.name}
      onTextChange={(name) => nameMutate({ uuid: board.uuid, name })}
      className="max-w-[300px] flex items-center"
      textClassName="text-primary body-lg-semibold"
      inputClassName="text-primary body-lg-semibold"
      placeholder="Untitled project"
      isLoading={nameIsLoading}
    />
  );
};
