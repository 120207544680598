export const Valid = ({ className }: { className?: string }) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="Group 1400003154">
      <circle id="Ellipse 1182" cx="10.7109" cy="10" r="9" fill="#F8F8F8" />
      <path
        id="Vector"
        d="M15.3245 6.92188L8.97837 13.845L6.09375 10.6981"
        stroke="#0890FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
