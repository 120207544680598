import classNames from "classnames";
import { useState } from "react";
import { BaseButton } from "../../components/Button/BaseButton.tsx";
import { Button } from "../../components/Button/Button.tsx";
import { trackEvent } from "../../utils/trackEvent.ts";
import { ErrorMessage } from "../../views/Board/Workspaces/components/ErrorMessage.tsx";
import { upgradeDialogStore } from "./upgradeDialogStore.ts";

export const CancellingReasonsFormContent = ({
  className,
  onPreviousClick,
}: {
  className?: string;
  onPreviousClick: () => void;
}) => {
  const [selectedOptions, setSelectedOptions] = useState<FreeVersionFormType[]>(
    [],
  );
  const [textReason, setTextReason] = useState("");
  const [isError, setIsError] = useState(false);
  return (
    <div
      className={classNames(
        "flex-col px-800 gap-1000 justify-center bg-surface-primary-rest text-primary",
        className,
      )}
    >
      <div className="flex-col">
        <span className="heading-2xl">Why not upgrading now?</span>
        <span className="body-lg-default">
          Help us improve our offer so it will suit you better in the future.
        </span>
      </div>
      <div className="flex-col gap-400">
        <div className="flex-col gap-200">
          <div className="flex-row items-center flex-wrap gap-200">
            {CANCELLING_REASONS_FORM_OPTIONS.map((option) => (
              <BaseButton
                key={option}
                className={classNames(
                  "p-200 rounded-400 border label-md-default",
                  selectedOptions.includes(option)
                    ? "bg-surface-primary-hover border-primary-active"
                    : "text-primary",
                )}
                onClick={() => {
                  if (selectedOptions.includes(option)) {
                    setSelectedOptions((options) =>
                      options.filter((op) => op !== option),
                    );
                  } else {
                    setSelectedOptions((options) => [...options, option]);
                  }
                }}
              >
                {formOptionsToLabel[option]}
              </BaseButton>
            ))}
          </div>
          {isError && <ErrorMessage text="Please select a reason" />}
        </div>
        <div className="flex-col gap-200">
          <div className="body-lg-semibold">
            Anything else you want to share?
          </div>
          <textarea
            className="w-full resize-none h-[84px] rounded-100 p-200 border body-md-default text-primary placeholder:text-secondary focus:border-primary-rest hover:border-primary-rest"
            placeholder="Comments"
            value={textReason}
            onChange={(event) => {
              setTextReason(event.target.value);
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
          />
        </div>
      </div>
      <div className="flex-col gap-200 items-center">
        <Button
          className="w-full"
          size="md"
          onClick={() => {
            if (selectedOptions.length === 0) {
              setIsError(true);
            } else {
              setIsError(false);
              trackEvent("upgrade:continue-as-free", {
                selected_reasons: selectedOptions,
                text_reason: textReason,
              });
              upgradeDialogStore.closeDialog();
            }
          }}
        >
          Send feedback and close
        </Button>
        <BaseButton
          className="w-full p-200 flex-row-center rounded-100 hover:bg-surface-primary-hover active:bg-input-surface-rest label-md-semibold"
          onClick={onPreviousClick}
        >
          I’ve changed my mind
        </BaseButton>
      </div>
    </div>
  );
};

const CANCELLING_REASONS_FORM_OPTIONS = [
  "too-expensive",
  "still-testing",
  "quality",
  "other",
] as const;

const formOptionsToLabel = {
  "too-expensive": "Too expensive",
  "still-testing": "I’m still testing",
  quality: "Output quality is not good enough",
  other: "Other",
} as const;

type FreeVersionFormType = (typeof CANCELLING_REASONS_FORM_OPTIONS)[number];
