export const RectangleVertical = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="22"
    fill="none"
    viewBox="0 0 14 22"
    className={className}
  >
    <rect
      width="20"
      height="13"
      x="0.5"
      y="21"
      rx="1.5"
      transform="rotate(-90 .5 21)"
    />
  </svg>
);
