import classNames from "classnames";
import type { ReactNode } from "react";
import { BaseClickableIcon } from "../../../../components/Icon/BaseClickableIcon.tsx";
import { Popover } from "../../../../components/Popover/Popover.tsx";

export const ExtraMenu = ({
  isOpen,
  onOpenChange,
  dialog,
  popoverContent,
  className,
}: {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  dialog: ReactNode;
  popoverContent: ReactNode;
  className: string;
}) => (
  <div className={classNames(isOpen ? "visible" : className)}>
    {dialog}
    <Popover
      isOpen={isOpen}
      onOpenChange={(open) => {
        onOpenChange(open);
      }}
      align="center"
      side="right"
      className="z-30 m-050 p-200 flex-auto rounded-100 border-none flex-row-center body-lg-semibold max-w-[280px]"
      content={popoverContent}
    >
      <BaseClickableIcon
        name="MoreHorizontal"
        className="tile-action"
        rotate={90}
      />
    </Popover>
  </div>
);
