import classNames from "classnames";
import { BaseClickableIcon } from "../../../components/Icon/BaseClickableIcon.tsx";
import { CDNImage } from "../../../components/Image/CDNImage.tsx";
import { useAppMutation } from "../../../http/useAppMutation.ts";
import type { StyleImage } from "../../types.ts";

export const StyleCreationImageTile = ({
  image,
  className,
  innerClassName,
  styleUuid,
}: {
  image: StyleImage;
  styleUuid: string;
  className?: string;
  innerClassName?: string;
}) => {
  const deleteContent = useAppMutation({
    path: "contents/delete",
    invalidate: ["styles", `styles/${styleUuid}`],
  }).mutation;

  return (
    <div className={classNames("group relative", className)}>
      <BaseClickableIcon
        name="Trash"
        className="absolute top-300 right-300 p-100 invisible group-hover:visible rounded-full border-025 bg-surface-primary-rest z-10"
        disabled={deleteContent.isLoading}
        onClick={(event) => {
          event.stopPropagation();
          deleteContent.mutate({
            uuids: [image.uuid],
          });
        }}
      />
      <div
        className={classNames(innerClassName, "h-full w-full overflow-clip")}
      >
        <CDNImage
          className="h-full w-full"
          imageClassName="h-full w-full object-cover object-center"
          src={image.url}
          srcDimension="medium256"
        />
      </div>
    </div>
  );
};
