import { SELECT_TOOL, UPSCALE_TOOL, VARIATION_TOOL } from "../../../routes.ts";
import { ToolMenuNavigation } from "../Workspaces/components/ToolMenu/ToolMenuButton.tsx";
import { ToolMenuTooltipContent } from "../Workspaces/components/ToolMenu/ToolMenuTooltipContent.tsx";

export const EditToolMenu = () => (
  <div className="flex-col">
    <ToolMenuNavigation
      to={SELECT_TOOL}
      title="Fill"
      iconName="Select"
      tooltipContent={
        <ToolMenuTooltipContent
          title="Fill"
          description="Select a zone and edit"
          beforeImageUrl="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/navbar/tools/select_1.png"
          afterImageUrl="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/navbar/tools/select_2.png"
          learnMoreLink="https://pimento.crisp.help/en/article/regenerate-part-of-your-image-beta-1k5h2d8/"
        />
      }
    />
    <ToolMenuNavigation
      to={VARIATION_TOOL}
      title="Variations"
      iconName="Layers"
      tooltipContent={
        <ToolMenuTooltipContent
          title="Variations"
          description="Generate versions of your image"
          beforeImageUrl="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/navbar/tools/variation_1.png"
          afterImageUrl="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/navbar/tools/variation_2.png"
          learnMoreLink="https://pimento.crisp.help/en/article/get-variations-of-your-image-lwsde5/"
        />
      }
    />
    <ToolMenuNavigation
      to={UPSCALE_TOOL}
      title="Upscale"
      iconName="Expand"
      tooltipContent={
        <ToolMenuTooltipContent
          title="Upscale"
          description="Improve the quality and remove artifacts"
          beforeImageUrl="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/navbar/tools/upscale_1.png"
          afterImageUrl="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/navbar/tools/upscale_2.png"
          learnMoreLink="https://pimento.crisp.help/en/article/quick-upscale-and-enhance-your-images-ck3gea/"
        />
      }
    />
    <div />
  </div>
);
