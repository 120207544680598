import { useQueryClient } from "@tanstack/react-query";
import { useAppMutation } from "../../../../../../../http/useAppMutation.ts";
import { useBoard } from "../../../../../hooks/useBoard.ts";
import { useUpscaleSettings } from "./useUpscaleSettings.ts";

export const useUpscaleImageGeneration = ({
  onSuccess,
}: {
  onSuccess?: () => void;
}) => {
  const { board } = useBoard();
  const generateUpscaleImage = useAppMutation({
    path: "boards/upscale-image",
    invalidate: [`boards/${board.uuid}`, "boards", "users/me"],
    onSuccess,
  }).mutation;

  const { upscaleSettings } = useUpscaleSettings();

  const queryClient = useQueryClient();

  return {
    mutation: ({
      prompt,
      styleUuid,
      assetUuid,
    }: {
      prompt: string;
      styleUuid: string;
      assetUuid: string;
    }) => {
      generateUpscaleImage.mutate(
        {
          board_uuid: board.uuid,
          prompt,
          style_uuid: styleUuid,
          asset_uuid: assetUuid,
          imagination_strength_preset:
            upscaleSettings.imagination_strength_preset,
          resemblance:
            upscaleSettings.imagination_strength_preset === "custom"
              ? upscaleSettings.resemblance
              : undefined,
          creativity:
            upscaleSettings.imagination_strength_preset === "custom"
              ? upscaleSettings.creativity
              : undefined,
          scale_factor: upscaleSettings.scale_factor,
          negative_prompt: upscaleSettings.negative_prompt,
          lora_scale:
            upscaleSettings.imagination_strength_preset === "custom"
              ? upscaleSettings.lora_scale
              : undefined,
        },
        {
          onSuccess: () => {
            [`assets/${assetUuid}`, `assets/${assetUuid}/history`].map(
              (queryKey) =>
                void queryClient.invalidateQueries({ queryKey: [queryKey] }),
            );
          },
        },
      );
    },
    isLoading: generateUpscaleImage.isLoading,
  };
};
