export const PimentoHome = ({
  className,
  size,
}: {
  className?: string;
  size: number;
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      id="Union"
      d="M10.2388 0.561459C10.0892 0.479513 9.90808 0.479514 9.75845 0.561461L7.42326 1.84037C7.26305 1.92811 7.16344 2.09624 7.16344 2.27891V4.20921C7.16344 4.27329 7.17576 4.33678 7.19972 4.39621L7.68794 5.6069L4.35676 3.78268C4.20714 3.70075 4.02608 3.70075 3.87646 3.78267L1.54111 5.06144C1.38087 5.14918 1.28124 5.31733 1.28125 5.50002L1.28138 8.0577C1.28138 8.24036 1.381 8.40848 1.54122 8.49622L3.30367 9.46137C3.35508 9.48952 3.41103 9.50842 3.46898 9.51719L4.80069 9.7188L1.54118 11.5038C1.38096 11.5915 1.28134 11.7596 1.28133 11.9423L1.28125 14.5C1.28124 14.6827 1.38087 14.8508 1.54111 14.9385L3.87641 16.2173C4.02603 16.2992 4.2071 16.2992 4.35672 16.2173L6.11917 15.2522C6.17222 15.2231 6.21955 15.1847 6.25888 15.1387L7.1634 14.0818V17.7211C7.1634 17.9038 7.26301 18.0719 7.42322 18.1596L9.75845 19.4385C9.90808 19.5205 10.0892 19.5205 10.2388 19.4385L12.574 18.1596C12.7342 18.0719 12.8338 17.9038 12.8338 17.7211V15.7908C12.8338 15.7267 12.8215 15.6632 12.7976 15.6038L12.3093 14.3931L15.6406 16.2173C15.7902 16.2992 15.9712 16.2992 16.1209 16.2173L18.4562 14.9386C18.6164 14.8508 18.716 14.6827 18.716 14.5L18.7159 11.9423C18.7159 11.7596 18.6163 11.5915 18.4561 11.5038L16.6937 10.5386C16.6422 10.5105 16.5863 10.4916 16.5283 10.4828L15.1966 10.2812L18.4561 8.49622C18.6163 8.40848 18.7159 8.24036 18.7159 8.05769L18.716 5.50002C18.716 5.31733 18.6164 5.14919 18.4561 5.06145L16.1208 3.78268C15.9712 3.70075 15.7901 3.70075 15.6405 3.78269L13.8781 4.74784C13.8251 4.77689 13.7777 4.81533 13.7384 4.86128L12.8338 5.9182V2.27891C12.8338 2.09624 12.7342 1.92811 12.574 1.84037L10.2388 0.561459Z"
      strokeLinejoin="round"
    />
  </svg>
);
