import Masonry from "react-responsive-masonry";
import { useAppQuery } from "../../../http/useAppQuery.ts";
import { EmptyStateInformation } from "../../Board/Workspaces/components/EmptyStateInformation.tsx";
import { MasonryContentTile } from "./components/MasonryContentTile.tsx";
import type { UserPurchasedContents } from "./types.ts";

export const PurchasedView = () => {
  const { data: userPurchasedContents } = useAppQuery<UserPurchasedContents>({
    queryKey: "users/get-purchased-contents",
  });

  return (
    <div className="h-full w-full flex-col flex-fill px-800 py-400 overflow-auto">
      <div className="min-h-1600 py-400 heading-xl flex-row items-center">
        All purchases
      </div>
      <div id="AllPurchasedContents" className="flex-col w-full">
        {userPurchasedContents && userPurchasedContents.contents.length > 0 ? (
          <Masonry columnsCount={4} gutter="16px">
            {userPurchasedContents.contents.map((content) => (
              <MasonryContentTile key={content.uuid} content={content} />
            ))}
          </Masonry>
        ) : (
          <EmptyStateInformation
            title="nothing here yet!"
            body="Purchase images to see them here."
          />
        )}
      </div>
    </div>
  );
};
