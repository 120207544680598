import { Navigate, Route } from "react-router";
import { Routes } from "react-router-dom";
import { PURCHASES } from "../../routes.ts";
import { HomeIndex } from "./HomeIndex/HomeIndex.tsx";
import { HomeSidebar } from "./HomeSidebar/HomeSidebar.tsx";
import { PurchasedView } from "./Purchased/PurchasedView.tsx";

export const HomeView = () => (
  <div className="h-full w-full flex-row">
    <HomeSidebar />
    <div className="flex-fill h-full">
      <Routes>
        <Route index element={<HomeIndex />} />
        <Route path={PURCHASES} element={<PurchasedView />} />
        <Route path="*" element={<Navigate replace to="" />} />
      </Routes>
    </div>
  </div>
);
