export const updateSearch = (params: Record<string, string | null>) => {
  const newParams = new URLSearchParams(window.location.search);
  for (const [key, value] of Object.entries(params)) {
    if (value === null) newParams.delete(key);
    else newParams.set(key, value);
  }
  return newParams;
};

export const applyCurrentSearchParamsToPath = (path: string) => {
  const newParams = new URLSearchParams(window.location.search);
  return `${path}?${newParams.toString()}`;
};

export const addSearchParamsToPath = (
  path: string,
  params: Record<string, string | null>,
) => {
  const newParams = new URLSearchParams();
  for (const [key, value] of Object.entries(params)) {
    if (value === null) newParams.delete(key);
    else newParams.set(key, value);
  }
  return `${path}?${newParams.toString()}`;
};
