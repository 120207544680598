import type {
  StyleTransferInitImageInfluence,
  StyleTransferStrengthPreset,
} from "./types.ts";

export const TRANSFER_STRENGTH_PRESET: StyleTransferStrengthPreset[] = [
  "light",
  "strong",
  "custom",
];

export const INIT_IMAGE_COLOR_INFLUENCES: StyleTransferInitImageInfluence[] = [
  "black_and_white",
  "color",
];

export const initImageColorInfluenceLabelMapping: {
  [K in StyleTransferInitImageInfluence]: string;
} = {
  black_and_white: "Sketch or Logo",
  color: "Picture",
};
