import classNames from "classnames";
import { type ButtonHTMLAttributes, forwardRef, type Ref } from "react";
import {
  Button,
  type ButtonSize,
  type ButtonVariant,
} from "../Button/Button.tsx";
import { Tooltip, type TooltipProps } from "../Tooltip/Tooltip.tsx";
import type { IconName } from "./Icon.tsx";

export type ClickableIconSize = ButtonSize;
export type ClickableIconVariant = ButtonVariant;

export const ClickableIcon = forwardRef(
  (
    {
      name,
      className,
      size,
      variant,
      tooltip,
      isLoading = false,
      isDisabled = false,
      type = "button",
      ...rest
    }: ButtonHTMLAttributes<HTMLButtonElement> & {
      name: IconName;
      size?: ClickableIconSize;
      variant?: ClickableIconVariant;
      tooltip?: Omit<TooltipProps, "children">;
      isLoading?: boolean;
      isDisabled?: boolean;
    },
    ref: Ref<HTMLButtonElement>,
  ) => {
    if (!tooltip) {
      return (
        <IconButton
          ref={ref}
          type={type}
          isLoading={isLoading}
          isDisabled={isDisabled}
          className={className}
          name={name}
          size={size}
          variant={variant}
          {...rest}
        />
      );
    }
    return (
      <Tooltip {...tooltip}>
        <IconButton
          ref={ref}
          type={type}
          isLoading={isLoading}
          isDisabled={isDisabled}
          className={className}
          name={name}
          size={size}
          variant={variant}
          {...rest}
        />
      </Tooltip>
    );
  },
);

const IconButton = forwardRef(
  (
    {
      name,
      className,
      size,
      variant,
      isLoading = false,
      isDisabled = false,
      type = "button",
      ...rest
    }: ButtonHTMLAttributes<HTMLButtonElement> & {
      name: IconName;
      className?: string;
      size?: ClickableIconSize;
      variant?: ClickableIconVariant;
      isLoading: boolean;
      isDisabled: boolean;
    },
    ref: Ref<HTMLButtonElement>,
  ) => (
    <Button
      ref={ref}
      type={type}
      disabled={isDisabled}
      loading={isLoading}
      size={size}
      variant={variant}
      iconName={name}
      className={classNames("aspect-square p-0", className)}
      {...rest}
    />
  ),
);
