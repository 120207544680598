import React from "react";
import ReactDOM from "react-dom/client";
import "./extensions/index.ts";
import TagManager from "react-gtm-module";
import { App } from "./App.tsx";
import { getConfig } from "./utils/environment.ts";

TagManager.initialize({
  gtmId: "GTM-PXZ2XFGW",
  auth: getConfig({
    dev: "7t8MysyUjeXiQfL-19OK3g",
    staging: "-4V9GITQLn8ap1_L-7Od4w",
    prod: "bFY9p3AVo0WwIkNou9TMsA",
  }),
  preview: getConfig({ dev: "env-12", staging: "env-13", prod: "env-14" }),
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
