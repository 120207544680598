export const Discord = ({ className }: { className?: string }) => (
  <svg
    className={className}
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.4207 2.00799C13.3887 1.536 12.2928 1.192 11.1488 1C11.0047 1.248 10.8447 1.584 10.7328 1.848C9.51597 1.67199 8.30795 1.67199 7.10796 1.848C6.99595 1.584 6.82794 1.248 6.69194 1C5.53996 1.192 4.44396 1.536 3.41916 2.00799C1.34717 5.06399 0.787164 8.04797 1.06716 10.9919C2.44316 11.9919 3.77117 12.6 5.07597 13C5.39597 12.568 5.68398 12.104 5.93198 11.616C5.45997 11.44 5.01198 11.224 4.57998 10.9679C4.69197 10.888 4.80398 10.8 4.90798 10.712C7.51597 11.904 10.3407 11.904 12.9168 10.712C13.0287 10.8 13.1328 10.888 13.2447 10.9679C12.8127 11.224 12.3648 11.44 11.8927 11.616C12.1407 12.104 12.4288 12.568 12.7488 13C14.0527 12.6 15.3888 11.9919 16.7575 10.9919C17.1015 7.58402 16.2127 4.62401 14.4207 2.00799ZM6.29197 9.17598C5.50796 9.17598 4.86796 8.464 4.86796 7.59197C4.86796 6.72002 5.49196 6.00798 6.29197 6.00798C7.08396 6.00798 7.73193 6.72002 7.71593 7.59197C7.71593 8.464 7.08396 9.17598 6.29197 9.17598ZM11.5488 9.17598C10.7647 9.17598 10.1239 8.464 10.1239 7.59197C10.1239 6.72002 10.7487 6.00798 11.5488 6.00798C12.3408 6.00798 12.9888 6.72002 12.9727 7.59197C12.9727 8.464 12.3488 9.17598 11.5488 9.17598Z"
      strokeLinejoin="round"
    />
  </svg>
);
