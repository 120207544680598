import { useSelectedAsset } from "../../../hooks/useSelectedAsset.ts";
import { RightDetailsLayout } from "../RightDetailsLayout.tsx";
import { ImageDebugSectionDetails } from "./ImageDebugSectionDetails.tsx";

export const ImageDebugDetails = ({
  onCloseButtonClick,
}: {
  onCloseButtonClick: () => void;
}) => {
  const { selectedAsset: asset } = useSelectedAsset();

  if (!asset) return null;

  return (
    <RightDetailsLayout
      name="Debug"
      className="w-[400px]"
      onCloseButtonClick={onCloseButtonClick}
    >
      <ImageDebugSectionDetails asset={asset} />
    </RightDetailsLayout>
  );
};
