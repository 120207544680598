import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export const useAppQuery = <T>({
  queryKey,
  queryParamsKey,
  refetchOnWindowFocus = true,
  enabled = true,
}: {
  queryKey: string | null /* null implies enabled: false */;
  queryParamsKey?: { [x: string]: string };
  refetchOnWindowFocus?: boolean;
  enabled?: boolean;
}) => {
  const { getAccessTokenSilently } = useAuth0();

  let queryUrl = queryKey ? `/api/${queryKey}` : null;
  if (queryUrl && queryParamsKey) {
    queryUrl = `${queryUrl}?${new URLSearchParams(queryParamsKey).toString()}`;
  }

  return useQuery<T>({
    staleTime: 2000,
    queryKey: queryParamsKey ? [queryKey, queryParamsKey] : [queryKey],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently();
      const { data } = await axios.get(queryUrl!, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return data;
    },
    retry: false,
    refetchOnWindowFocus,
    enabled: queryKey ? enabled : false,
  });
};
