import type { LottieComponentProps } from "lottie-react";
import { lazy, useEffect, useState } from "react";
import { Spinner } from "../Spinner/Spinner.tsx";

const Lottie = lazy(() => import("lottie-react"));

export const UrlAnimation = ({
  url,
  ...props
}: Omit<LottieComponentProps, "animationData" | "ref"> & {
  url: string;
}) => {
  const [animationData, setAnimationData] = useState<Record<string, unknown>>();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    void fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setAnimationData(data);
        setLoading(false);
      });
  }, [url]);

  return loading ? (
    <Spinner />
  ) : (
    <Lottie animationData={animationData} {...props} />
  );
};
