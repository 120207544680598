import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BaseClickableIcon } from "../../components/Icon/BaseClickableIcon.tsx";
import styles from "./notificationsContainer.module.css";

export const NotificationsContainer = () => (
  <ToastContainer
    className={styles["container"]}
    bodyClassName={styles["toastBody"]}
    position="bottom-left"
    draggable={false}
    closeOnClick={false}
    icon={false}
    hideProgressBar
    closeButton={({ closeToast }) => (
      <BaseClickableIcon name="X" onClick={closeToast} />
    )}
  />
);
