import { useAppMutation } from "../../../../http/useAppMutation.ts";

export const useCreateCustomerPortalUrl = () => {
  const {
    mutate: createCustomerPortalUrl,
    isLoading: isCreateCustomerPortalUrlLoading,
  } = useAppMutation<{ url: string | undefined }>({
    path: "payments/create-customer-portal-url",
    invalidate: [],
    onSuccess: (response) => {
      if (response.data.url) window.open(response.data.url, "_self");
    },
  }).mutation;

  return {
    createCustomerPortalUrl,
    isCreateCustomerPortalUrlLoading,
  };
};
