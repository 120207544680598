import classNames from "classnames";
import { Icon } from "../../../components/Icon/Icon.tsx";

export const LearnMoreLink = ({
  url,
  className,
  variant = "primary",
}: {
  url: string;
  className?: string;
  variant?: "primary" | "secondary";
}) => (
  <a
    href={url}
    className={classNames("flex-row hover:opacity-70", className)}
    target="_blank"
    rel="noopener noreferrer"
  >
    <span
      className={classNames(
        "underline body-md-default",
        variant === "primary" ? "text-primary" : "text-white",
      )}
    >
      Learn More
    </span>
    <Icon
      name="ArrowUpRight"
      size="sm"
      className={variant === "primary" ? "" : "stroke-inverse-rest"}
    />
  </a>
);
