import { useState } from "react";
import { Button } from "../../../components/Button/Button.tsx";
import { Dialog } from "../../../components/Dialog/Dialog.tsx";
import { TextField } from "../../../components/TextField/TextField.tsx";
import { useUser } from "../../../hooks/useUser.ts";
import { useAppMutation } from "../../../http/useAppMutation.ts";
import type { User } from "../../../types.ts";

const MAIL_TO = "mailto:contact@pimento.design";

export const ProfileSettingsDialog = ({
  isOpen,
  onOpenChange,
}: {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
}) => (
  <Dialog
    title="Profile settings"
    isOpen={isOpen}
    onOpenChange={onOpenChange}
    content={<ProfileSettingsDialogContent onOpenChange={onOpenChange} />}
  />
);

const ProfileSettingsDialogContent = ({
  onOpenChange,
}: {
  onOpenChange: (open: boolean) => void;
}) => {
  const { user } = useUser();

  return (
    user && (
      <ProfileSettings
        user={user}
        onCancel={() => onOpenChange(false)}
        onUpdate={() => onOpenChange(false)}
      />
    )
  );
};

const ProfileSettings = ({
  user,
  onCancel,
  onUpdate,
}: {
  user: User;
  onCancel: () => void;
  onUpdate: () => void;
}) => {
  const [firstName, setFirstName] = useState(user.first_name);
  const [lastName, setLastName] = useState(user.last_name);
  const [phoneNumber, setPhoneNumber] = useState(user.phone_number);

  const {
    mutate: userInformationMutate,
    isLoading: isUserInformationMutationLoading,
  } = useAppMutation({
    path: "users/add-information",
    invalidate: ["users/me"],
  }).mutation;

  return (
    <div className="flex-col">
      <div className="px-400 py-100 flex-col">
        <div className="py-200 grid grid-cols-[148px_240px] gap-x-300 gap-y-400">
          <div className="h-800 body-md-semibold flex-row items-center">
            First name
          </div>
          <TextField
            size="md"
            value={firstName ?? ""}
            onChange={setFirstName}
          />
          <div className="h-800 body-md-semibold flex-row items-center">
            Last name
          </div>
          <TextField size="md" value={lastName ?? ""} onChange={setLastName} />
          <div className="h-800 body-md-semibold flex-row items-center">
            Phone number
          </div>
          <TextField
            size="md"
            value={phoneNumber ?? ""}
            onChange={setPhoneNumber}
            type="number"
            inputClassName="hide-controls"
          />
        </div>
        <div className="pt-400 mb-400 border-b" />
        <div className="py-200 flex-row justify-between items-center">
          <div className="flex-col gap-100">
            <div className="body-md-semibold">Email</div>
            <div className="text-secondary">{user.email}</div>
          </div>
          <Button
            variant="secondary"
            size="md"
            onClick={() =>
              window.open(
                MAIL_TO + "?subject=Change my Pimento email",
                "_blank",
              )
            }
          >
            Request email update
          </Button>
        </div>
        <div className="py-200 flex-row justify-between items-center">
          <div className="flex-col gap-100">
            <div className="body-md-semibold">Password</div>
            <div className="text-secondary">Only you know</div>
          </div>
          <Button
            variant="secondary"
            size="md"
            onClick={() =>
              window.open(
                MAIL_TO + "?subject=Change my Pimento password",
                "_blank",
              )
            }
          >
            Request password update
          </Button>
        </div>
        <div className="pt-400 mb-400 border-b" />
        <div className="py-200">
          <a
            className="body-md-semibold text-error underline"
            href={MAIL_TO + "?subject=Delete my Pimento account"}
            target="_blank"
          >
            Request account deletion
          </a>
        </div>
      </div>
      <div className="px-400 py-300 flex-row items-center justify-end gap-200">
        <Button variant="tertiary" size="md" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          size="md"
          className="w-[104px]"
          loading={isUserInformationMutationLoading}
          disabled={
            !firstName ||
            !lastName ||
            !phoneNumber ||
            (user.first_name === firstName &&
              user.last_name === lastName &&
              user.phone_number === phoneNumber)
          }
          onClick={() =>
            userInformationMutate(
              {
                first_name: firstName,
                last_name: lastName,
                phone_number: phoneNumber === "" ? null : phoneNumber,
              },
              {
                onSuccess: onUpdate,
              },
            )
          }
        >
          Save changes
        </Button>
      </div>
    </div>
  );
};
