import { useState } from "react";
import { Animation } from "../../components/Animation/Animation.tsx";
import { BaseDialog } from "../../components/Dialog/BaseDialog.tsx";
import { useUser } from "../../hooks/useUser.ts";
import { isUserAllowedTo } from "../../types.ts";
import { CancellingReasonsFormContent } from "./CancellingReasonsFormContent.tsx";
import { PricingTable } from "./PricingTable.tsx";
import { upgradeDialogStore } from "./upgradeDialogStore.ts";
import { UpgradeSubscriptionContent } from "./UpgradeSubscriptionContent.tsx";

export const UpgradeDialog = () => {
  const { isDialogOpen, isClosable } = upgradeDialogStore.useState();

  return (
    <BaseDialog
      isOpen={isDialogOpen}
      onOpenChange={(open) => {
        if (open) {
          upgradeDialogStore.openDialog();
        } else {
          upgradeDialogStore.closeDialog();
        }
      }}
      clickOutsideAuthorized={isClosable}
      displayCloseButton={isClosable}
      escapeKeyDownAuthorized={isClosable}
      content={isDialogOpen && <DialogContent />}
    />
  );
};

const DialogContent = () => {
  const { user } = useUser();

  const [upgradeStep, setUpgradeStep] = useState<
    "upgrade" | "cancellingReasons"
  >("upgrade");

  return (() => {
    switch (upgradeStep) {
      case "upgrade":
        return (
          <div className="flex-col gap-100 bg-surface-primary-rest">
            {user && !isUserAllowedTo(user, "subscription:upgrade:hidden") ? (
              <PricingTable
                onClickContinueWithCurrentVersion={() =>
                  setUpgradeStep("cancellingReasons")
                }
              />
            ) : (
              <UpgradeSubscriptionContent
                onClickContinueWithCurrentVersion={() =>
                  setUpgradeStep("cancellingReasons")
                }
              />
            )}
          </div>
        );
      case "cancellingReasons":
        return (
          <div className="flex-row">
            <div className="w-[25%] bg-surface-inverse-rest content-center">
              <Animation
                loadData={() => import("../../assets/boardInitLoading.json")}
                loop
                autoplay
              />
            </div>
            <CancellingReasonsFormContent
              className="h-[500px] w-[75%]"
              onPreviousClick={() => setUpgradeStep("upgrade")}
            />
          </div>
        );
    }
  })();
};
