import type { ReactNode } from "react";
import { useAppQuery } from "../../http/useAppQuery.ts";
import { QueryResult } from "./QueryResult.tsx";

export const Query = ({
  children,
  queryKey,
  queryParamsKey,
}: {
  children: (output: any) => ReactNode | null;
  queryKey: string;
  queryParamsKey?: { [x: string]: string };
}) => {
  const query = useAppQuery({ queryKey, queryParamsKey });

  return <QueryResult query={query}>{children}</QueryResult>;
};
