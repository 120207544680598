import { createStore } from "../../../../../../../utils/createStore.ts";

export const transferPromptStore = createStore(
  {
    prompt: "",
  },
  ({ setter, set }) => ({
    setPrompt: setter("prompt"),
    reset: () => {
      set({
        prompt: "",
      });
    },
  }),
);
