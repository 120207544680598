import { Component, type ReactNode } from "react";
import { Button } from "../components/Button/Button.tsx";

export class ErrorBoundary extends Component<{ children: ReactNode }> {
  state: { error?: Error } = {};

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  // eslint-disable-next-line @typescript-eslint/class-methods-use-this
  componentDidCatch(_error: Error) {
    // Sentry.captureException(error);
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;
    if (error) {
      return (
        <div className="flex-col-center m-auto p-800 text-center">
          <span className="body-lg-semibold text-primary">
            An error has occurred
          </span>
          {import.meta.env.DEV && (
            <div className="text-error" style={{ whiteSpace: "pre-wrap" }}>
              {error.toString()}
            </div>
          )}
          <Button size="md" onClick={() => window.location.reload()}>
            Reload
          </Button>
        </div>
      );
    }

    return children;
  }
}
