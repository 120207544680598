export const OnboardingSquareFormat = ({
  className,
}: {
  className?: string;
}) => (
  <svg
    width="35"
    height="34"
    viewBox="0 0 36 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="icon">
      <rect
        id="Rectangle 40036"
        x="1.61719"
        y="1.57812"
        width="22.5"
        height="22.5"
        rx="2.25"
      />
      <g id="Group 1400003153">
        <circle
          id="Ellipse 1182"
          cx="24.9688"
          cy="23.1914"
          r="10"
          fill="#F8F8F8"
        />
        <path
          id="Vector"
          d="M29.5823 20.1133L23.2362 27.0364L20.3516 23.8895"
          stroke="#0890FF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);
