import { useUser } from "../../../../hooks/useUser.ts";
import { useAppMutation } from "../../../../http/useAppMutation.ts";
import { isUserAllowedTo } from "../../../../types.ts";
import { useBoards } from "./useBoards.ts";

export const useCreateBoard = ({
  onBoardCreationForbidden,
  onSuccess,
}: {
  onBoardCreationForbidden?: () => void;
  onSuccess?: (uuid: string) => void;
}) => {
  const { user } = useUser();
  const { remainingUserBoards } = useBoards();

  const { mutate, isLoading } = useAppMutation({
    path: "boards",
    invalidate: ["boards"],
    waitForInvalidation: false,
  }).mutation;

  return {
    createBoard: ({
      styleUuid,
      name = "",
    }: {
      styleUuid?: string;
      name?: string;
    } = {}) => {
      if (
        user &&
        (remainingUserBoards > 0 ||
          isUserAllowedTo(user, "boards:create-unlimited"))
      ) {
        mutate(
          { name, last_used_style_uuid: styleUuid },
          {
            onSuccess: ({ data }) => {
              onSuccess?.(data.uuid as string);
            },
          },
        );
      } else {
        onBoardCreationForbidden?.();
      }
    },
    isCreateBoardLoading: isLoading,
  };
};
