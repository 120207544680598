import type { UseQueryResult } from "@tanstack/react-query";
import type { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../Button/Button.tsx";
import { Pimento } from "../Icon/assets/Pimento.tsx";
import { Spinner } from "../Spinner/Spinner.tsx";

export const QueryResult = ({
  query,
  children,
}: {
  query: UseQueryResult<any>;
  children: (output: any) => ReactNode | null;
}) => {
  const navigate = useNavigate();

  if (query.isLoading) return <Spinner />;
  if (query.status === "success" && query.data !== undefined) {
    return children(query.data);
  }

  return (
    <div className="flex-col-center h-full gap-[100px] text-primary">
      <Pimento size={240} />
      <div className="flex-col-center gap-400">
        <div className="heading-2xl">Page not found</div>
        <div className="body-lg-default">{(query.error as any)?.message}</div>
      </div>
      <Button onClick={() => navigate("/")}>
        <div className="label-lg-default text-inherit">Go back to home</div>
      </Button>
    </div>
  );
};
