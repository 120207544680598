import { PresetButton } from "../../../../components/PresetButton.tsx";
import { TRANSFER_STRENGTH_PRESET } from "./constants.ts";
import { useStyleTransferSettings } from "./hooks/useStyleTransferSettings.ts";

export const TransferStrengthSection = () => {
  const { styleTransferSettings, setStyleTransferSettings } =
    useStyleTransferSettings();

  return (
    <div className="flex-row-center gap-100">
      {TRANSFER_STRENGTH_PRESET.map((preset) => (
        <PresetButton
          key={preset}
          isSelected={styleTransferSettings.transfer_strength_preset === preset}
          onClick={() =>
            setStyleTransferSettings({ transfer_strength_preset: preset })
          }
        >
          {preset}
        </PresetButton>
      ))}
    </div>
  );
};
