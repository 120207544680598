import type { ParametersOverloads, WidenLiteral } from "../types.ts";

declare global {
  interface ReadonlyArray<T> {
    /* Alias with type narrowing */
    includesWiden(
      searchElement: T | WidenLiteral<T>,
      fromIndex?: number,
    ): searchElement is T;
    /* ES2022 */
    at(index: number): T | undefined;
    /* ES2023 */
    findLast<S extends T>(
      predicate: (value: T, index: number, array: T[]) => value is S,
    ): S | undefined;
    findLast(
      predicate: (value: T, index: number, array: T[]) => unknown,
    ): T | undefined;
    findLastIndex(
      predicate: (value: T, index: number, array: T[]) => unknown,
    ): number;
    toReversed(): T[];
    toSorted(compareFn?: (a: T, b: T) => number): T[];
    toSpliced(start: number, deleteCount: number, ...items: T[]): T[];
    with(index: number, value: T): T[];
    /* Non Standard */
    sortAsc(getValue: ((el: T) => number) | ((el: T) => string)): T[];
    sortDesc(getValue: ((el: T) => number) | ((el: T) => string)): T[];
    count(predicate: (value: T, index: number, array: T[]) => unknown): number;
    filterNotNull(): NonNullable<T>[];
    mapNotNull<U>(
      callbackFn: (value: T, index: number, array: T[]) => U | null | undefined,
    ): U[];
    flatMapNotNull<U>(
      callbackFn: (
        value: T,
        index: number,
        array: T[],
      ) => U | null | undefined | (U | null | undefined)[],
    ): U[];
    findMap<U>(
      callbackFn: (value: T, index: number, array: T[]) => U | null | undefined,
    ): U | undefined;
    isEmpty(): boolean;
    isNotEmpty(): boolean;
    distinct(): T[];
    distinctBy(selector: (value: T) => unknown): T[];
    groupBy(selector: (value: T) => string): Record<string, T[]>;
    groupByTypedKey<Key extends string>(
      selector: (value: T) => Key,
    ): Record<Key, T[] | undefined>;
    groupByEntries<Key extends string>(
      selector: (value: T) => Key,
    ): [Key, T[]][];
    findIndexOrUndefined(
      predicate: (value: T, index: number, array: readonly T[]) => unknown,
    ): number | undefined;
    findLastIndexOrUndefined(
      predicate: (value: T, index: number, array: readonly T[]) => unknown,
    ): number | undefined;
    toggle(element: T, selector?: (value: T) => unknown): T[];
    concatIf(
      condition: boolean | undefined | null,
      ...items: (T | ConcatArray<T>)[]
    ): T[];
    unshiftIf<U>(
      condition: boolean | undefined | null,
      ...items: U[]
    ): (T | U)[];
    none(predicate: (value: T, index: number, array: T[]) => unknown): boolean;
    insert(index: number, ...items: T[]): T[];
    sum(
      this: number[],
      selector?: (value: number, index: number) => number,
    ): number;
    sum(selector: (value: T, index: number) => number): number;
    min(selector: (value: T, index: number) => number): number;
    max(selector: (value: T, index: number) => number): number;
    partition(predicate: (value: T) => boolean): [T[], T[]];
    chunk(chunkSize: number): T[][];
  }
  interface Array<T> {
    /* Alias with type narrowing */

    includesWiden(
      searchElement: T | WidenLiteral<T>,
      fromIndex?: number,
    ): searchElement is T;

    /* ES2022 */

    /**
     * Relative indexing method with nullable type
     */
    at(index: number): T | undefined;

    /* ES2023 */

    /**
     * Returns the value of the last element in the array where predicate is true, and undefined otherwise.
     */
    findLast<S extends T>(
      predicate: (value: T, index: number, array: T[]) => value is S,
    ): S | undefined;
    findLast(
      predicate: (value: T, index: number, array: T[]) => unknown,
    ): T | undefined;

    /**
     * Returns the index of the last element in the array where predicate is true, and -1 otherwise.
     */
    findLastIndex(
      predicate: (value: T, index: number, array: T[]) => unknown,
    ): number;

    /**
     * Immutable version of {@link Array.reverse}
     */
    toReversed(): T[];

    /**
     * Immutable version of {@link Array.sort}
     */
    toSorted(compareFn?: (a: T, b: T) => number): T[];

    /**
     * Immutable version of {@link Array.splice}
     */
    toSpliced(start: number, deleteCount: number, ...items: T[]): T[];

    /**
     * Copies an array, then overwrites the value at the provided index with the
     * given value. If the index is negative, then it replaces from the end
     * of the array.
     */
    with(index: number, value: T): T[];

    /* Non Standard */

    /**
     * Shortcut for {@link Array.toSorted}
     * - sortAsc((fn) => number) === toSorted((a, b) => fn(a) - fn(b))
     * - sortAsc((fn) => string) === toSorted((a, b) => fn(a).localCompare(fn(b)))
     */
    sortAsc(getValue: ((el: T) => number) | ((el: T) => string)): T[];

    /**
     * Shortcut for {@link Array.toSorted}
     * - sortDesc((fn) => number) === toSorted((a, b) => fn(b) - fn(a))
     * - sortDesc((fn) => string) === toSorted((a, b) => fn(b).localCompare(fn(a)))
     */
    sortDesc(getValue: ((el: T) => number) | ((el: T) => string)): T[];

    /**
     * Efficient shortcut for {@link Array.filter}.length
     */
    count(predicate: (value: T, index: number, array: T[]) => unknown): number;

    /**
     * Typed shortcut of {@link Array.filter} for removing undefined and null
     * filterNotNull() === filter((el) => el !== undefined && el !== null)
     */
    filterNotNull(): NonNullable<T>[];

    /**
     * Typed shortcut of {@link Array.map} and {@link Array.filterNotNull}
     * mapNotNull(predicate) === map(predicate).filterNotNull()
     */
    mapNotNull<U>(
      callbackFn: (value: T, index: number, array: T[]) => U | null | undefined,
    ): U[];

    /**
     * Typed shortcut of {@link Array.flatMap} and {@link Array.filterNotNull}
     * flatMapNotNull(predicate) === flatMap(predicate).filterNotNull()
     */
    flatMapNotNull<U>(
      callbackFn: (
        value: T,
        index: number,
        array: T[],
      ) => U | null | undefined | (U | null | undefined)[],
    ): U[];

    /**
     * Return the first non-null value from the predicate, or undefined if no match
     * findMap(predicate) === mapNotNull(predicate).at(0)
     */
    findMap<U>(
      callbackFn: (value: T, index: number, array: T[]) => U | null | undefined,
    ): U | undefined;

    /**
     * Shortcut for comparing {@link Array.length} to 0
     */
    isEmpty(): boolean;

    /**
     * Shortcut for comparing {@link Array.length} to 0
     */
    isNotEmpty(): boolean;

    /**
     * Returns a list containing only distinct (by reference) elements
     * Keeps the first in the array among duplicates
     */
    distinct(): T[];

    /**
     * Returns a list containing only elements having distinct (by reference)
     * results for the selector function
     * Keeps the first in the array among duplicates
     */
    distinctBy(selector: (value: T) => unknown): T[];

    /**
     * Groups elements of the original array by the key returned
     * by the given selector function
     */
    groupBy(selector: (value: T) => string): Record<string, T[]>;
    groupByTypedKey<Key extends string>(
      selector: (value: T) => Key,
    ): Record<Key, T[] | undefined>;
    groupByEntries<Key extends string>(
      selector: (value: T) => Key,
    ): [Key, T[]][];

    /**
     * Same as {@link Array.findIndex}, but returns undefined instead of -1
     * when there is no match
     */
    findIndexOrUndefined(
      predicate: (value: T, index: number, array: readonly T[]) => unknown,
    ): number | undefined;

    /**
     * Same as {@link Array.findIndexOrUndefined}, but starting from the end
     */
    findLastIndexOrUndefined(
      predicate: (value: T, index: number, array: readonly T[]) => unknown,
    ): number | undefined;

    /**
     * Removes the element is present, otherwise adds it
     */
    toggle(element: T, selector?: (value: T) => unknown): T[];

    /**
     * If first argument is true, uses {@link Array.concat} with the following arguments
     */
    concatIf(
      condition: boolean | undefined | null,
      ...items: (T | ConcatArray<T>)[]
    ): T[];

    /**
     * Immutable chainable conditional {@link Array.unshift}
     */
    unshiftIf<U>(
      condition: boolean | undefined | null,
      ...items: U[]
    ): (T | U)[];

    /**
     * Returns true if no element matches the predicate.
     */
    none(predicate: (value: T, index: number, array: T[]) => unknown): boolean;

    /**
     * Insert elements at the given index
     * Out of bound index fallback to 0 or this.length
     */
    insert(index: number, ...items: T[]): T[];

    /**
     * Compute the sum of the array with a selector
     */
    sum(
      this: number[],
      selector?: (value: number, index: number) => number,
    ): number;
    sum(selector: (value: T, index: number) => number): number;

    /**
     * Compute the min of the array with a selector
     */
    min(selector: (value: T, index: number) => number): number;

    /**
     * Compute the max of the array with a selector
     */
    max(selector: (value: T, index: number) => number): number;

    /**
     * Split an array in two, returning positive match first
     */
    partition(predicate: (value: T) => boolean): [T[], T[]];

    /**
     * Split an array in chunks of the given size
     */
    chunk(chunkSize: number): T[][];
  }
}

const defineArrayProperty = <K extends keyof Array<unknown>>(
  key: K,
  value: (
    this: unknown[],
    ...args: ParametersOverloads<Array<unknown>[K]>
  ) => Array<unknown>[K] extends (...args: any) => infer R ? R : never,
) => {
  Object.defineProperty(Array.prototype, key, { value });
};

defineArrayProperty("includesWiden", function (searchElement, fromIndex) {
  return this.includes(searchElement, fromIndex);
});

defineArrayProperty("at", function (index) {
  return this[index < 0 ? index + this.length : index];
});

defineArrayProperty("findLast", function (predicate) {
  for (let i = this.length - 1; i >= 0; i--) {
    if (predicate(this[i], i, this)) return this[i];
  }
});

defineArrayProperty("findLastIndex", function (predicate) {
  for (let i = this.length - 1; i >= 0; i--) {
    if (predicate(this[i], i, this)) return i;
  }
  return -1;
});

defineArrayProperty("toReversed", function () {
  return this.slice().reverse();
});

defineArrayProperty("toSorted", function (compareFn) {
  return this.slice().sort(compareFn);
});

defineArrayProperty("toSpliced", function (start, deleteCount, ...items) {
  return this.slice().splice(start, deleteCount, ...items);
});

defineArrayProperty("with", function (index, value) {
  const actualIndex = index >= 0 ? index : this.length + index;
  if (actualIndex < 0 || actualIndex >= this.length) {
    throw new RangeError("index is out of range");
  }
  return this.map((el, i) => (i === actualIndex ? value : el));
});

const isStringSort = <T>(
  getValue: (value: T) => string | number | Date,
  value: T,
): getValue is (value: T) => string => typeof getValue(value) === "string";

defineArrayProperty("sortAsc", function (getValue) {
  if (this.isEmpty()) return this;
  if (isStringSort(getValue, this[0])) {
    return this.toSorted((a, b) => getValue(a).localeCompare(getValue(b)));
  }
  return this.toSorted((a, b) => getValue(a) - getValue(b));
});

defineArrayProperty("sortDesc", function (getValue) {
  if (this.isEmpty()) return this;
  if (isStringSort(getValue, this[0])) {
    return this.toSorted((a, b) => getValue(b).localeCompare(getValue(a)));
  }
  return this.toSorted((a, b) => getValue(b) - getValue(a));
});

defineArrayProperty("count", function (predicate) {
  let count = 0;
  for (const [index, el] of this.entries()) {
    if (predicate(el, index, this)) count++;
  }
  return count;
});

defineArrayProperty("filterNotNull", function () {
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  return this.filter((el) => el !== undefined && el !== null) as {}[];
});

defineArrayProperty("mapNotNull", function (predicate) {
  const newArray: any[] = [];
  for (const [index, el] of this.entries()) {
    const result = predicate(el, index, this);
    if (result !== undefined && result !== null) newArray.push(result);
  }
  return newArray;
});

defineArrayProperty("flatMapNotNull", function (predicate) {
  return this.flatMap(predicate).filterNotNull();
});

defineArrayProperty("findMap", function (predicate) {
  for (let index = 0; index < this.length; index++) {
    const el = this[index];
    const result = predicate(el, index, this);
    if (result !== undefined && result !== null) return result;
  }
  return undefined;
});

defineArrayProperty("isEmpty", function () {
  return this.length === 0;
});

defineArrayProperty("isNotEmpty", function () {
  return this.length > 0;
});

defineArrayProperty("distinct", function () {
  return this.distinctBy((e) => e);
});

defineArrayProperty("distinctBy", function (selector) {
  const set = new Set();
  return this.filter((e) => {
    if (set.has(selector(e))) return false;
    set.add(selector(e));
    return true;
  });
});

defineArrayProperty("groupBy", function (selector) {
  return this.reduce<Record<string, Array<unknown>>>((acc, it) => {
    if (selector(it) in acc) {
      acc[selector(it)].push(it);
    } else {
      acc[selector(it)] = [it];
    }
    return acc;
  }, {});
});

defineArrayProperty("groupByTypedKey", function (selector) {
  return this.groupBy(selector);
});

defineArrayProperty("groupByEntries", function (selector) {
  return Object.entries(this.groupBy(selector)) as [any, unknown[]][];
});

defineArrayProperty("findIndexOrUndefined", function (predicate) {
  const index = this.findIndex(predicate);
  return index === -1 ? undefined : index;
});

defineArrayProperty("findLastIndexOrUndefined", function (predicate) {
  const index = this.findLastIndex(predicate);
  return index === -1 ? undefined : index;
});

defineArrayProperty("toggle", function (element, selector = (it) => it) {
  const newArray: unknown[] = [];
  let found = false;
  for (const el of this) {
    if (selector(el) === selector(element)) {
      found = true;
    } else {
      newArray.push(el);
    }
  }
  if (!found) newArray.push(element);
  return newArray;
});

defineArrayProperty("concatIf", function (condition, ...items) {
  return condition ? this.concat(...items) : this;
});

defineArrayProperty("unshiftIf", function (condition, ...items) {
  if (condition) this.unshift(...items);
  return this;
});

defineArrayProperty("none", function (predicate) {
  return !this.some(predicate);
});

defineArrayProperty("insert", function (start, ...items) {
  return [...this.slice(0, start), ...items, ...this.slice(start)];
});

defineArrayProperty("sum", function (...[selector]) {
  return this.reduce<number>(
    (acc, v, i) => acc + (selector ? selector(v as any, i) : (v as any)),
    0,
  );
});

defineArrayProperty("min", function (predicate) {
  return this.reduce<number>((acc, v, i) => {
    const nb = predicate(v, i);
    return acc < nb ? acc : nb;
  }, Infinity);
});

defineArrayProperty("max", function (predicate) {
  return this.reduce<number>((acc, v, i) => {
    const nb = predicate(v, i);
    return acc > nb ? acc : nb;
  }, -Infinity);
});

defineArrayProperty("partition", function (predicate) {
  const match: unknown[] = [];
  const nonMatch: unknown[] = [];
  for (const el of this) (predicate(el) ? match : nonMatch).push(el);
  return [match, nonMatch] satisfies [unknown[], unknown[]];
});

defineArrayProperty("chunk", function (chunkSize) {
  const result: unknown[][] = [];
  for (let i = 0; i < this.length; i += chunkSize) {
    result.push(this.slice(i, i + chunkSize));
  }
  return result;
});
