import { useUser } from "../../../hooks/useUser.ts";
import { isUserAllowedTo } from "../../../types.ts";
import { editAssetMenuStore } from "../stores/editAssetMenuStore.ts";
import { EditionHistory } from "../Workspaces/components/EditionHistory/EditionHistory.tsx";
import { ImageDebugDetails } from "../Workspaces/components/ImageDebugDetails/ImageDebugDetails.tsx";
import { InformationDetails } from "../Workspaces/components/InformationDetails/InformationDetails.tsx";
import { TagsDetails } from "../Workspaces/components/TagsDetails/TagsDetails.tsx";

export const EditAssetDetails = () => {
  const { user } = useUser();
  const { currentAssetMenuType } = editAssetMenuStore.useState();

  if (!currentAssetMenuType) return null;

  switch (currentAssetMenuType) {
    case "history":
      return <EditionHistory onCloseButtonClick={editAssetMenuStore.reset} />;
    case "info":
      return (
        <InformationDetails onCloseButtonClick={editAssetMenuStore.reset} />
      );
    case "tags":
      return <TagsDetails onCloseButtonClick={editAssetMenuStore.reset} />;
    case "debug":
      if (user && isUserAllowedTo(user, "mode:debug")) {
        return (
          <ImageDebugDetails onCloseButtonClick={editAssetMenuStore.reset} />
        );
      }
  }
};
