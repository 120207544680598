export const RectangleHorizontal = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="14"
    fill="none"
    viewBox="0 0 21 14"
    className={className}
  >
    <rect width="20" height="13" x="0.5" y="0.5" rx="1.5" />
  </svg>
);
