import { useSelectedAsset } from "../../../hooks/useSelectedAsset.ts";
import { RightDetailsLayout } from "../RightDetailsLayout.tsx";
import { AssetTagsSection } from "./AssetTagsSection.tsx";

export const TagsDetails = ({
  onCloseButtonClick,
}: {
  onCloseButtonClick: () => void;
}) => {
  const { selectedAsset: asset } = useSelectedAsset();

  if (!asset) return null;

  return (
    <RightDetailsLayout name="Tags" onCloseButtonClick={onCloseButtonClick}>
      <div className="flex-col flex-shrink px-400 py-300">
        <AssetTagsSection asset={asset} />
      </div>
    </RightDetailsLayout>
  );
};
