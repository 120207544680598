import { PresetButton } from "../../../../components/PresetButton.tsx";
import {
  INIT_IMAGE_COLOR_INFLUENCES,
  initImageColorInfluenceLabelMapping,
} from "./constants.ts";
import { useStyleTransferSettings } from "./hooks/useStyleTransferSettings.ts";

export const InitImageColorInfluenceSection = () => {
  const { styleTransferSettings, setStyleTransferSettings } =
    useStyleTransferSettings();

  return (
    <div className="flex-row-center gap-100">
      {INIT_IMAGE_COLOR_INFLUENCES.map((influence) => (
        <PresetButton
          key={influence}
          className="normal-case"
          isSelected={
            styleTransferSettings.init_image_color_influence === influence
          }
          onClick={() =>
            setStyleTransferSettings({ init_image_color_influence: influence })
          }
        >
          {initImageColorInfluenceLabelMapping[influence]}
        </PresetButton>
      ))}
    </div>
  );
};
