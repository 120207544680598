import { useAppQuery } from "../../../../../http/useAppQuery.ts";
import type { Asset, ContentGenerationInformation } from "../../../types.ts";
import { SectionDetails, TextItemSectionDetails } from "../SectionDetails.tsx";

export const ImageDebugSectionDetails = ({ asset }: { asset: Asset }) => {
  const image = asset.content;
  const { data: imageGenerationParameters } =
    useAppQuery<ContentGenerationInformation>({
      queryKey: `contents/${image.uuid}/generation-parameters`,
    });
  const deletedAt = asset.deleted_at;

  return (
    <>
      <SectionDetails name="Debug mode">
        <>
          {(image.generation_model_type === "text_to_image" ||
            image.generation_model_type === "image_to_image" ||
            image.generation_model_type === "generative_fill") &&
            image.generation_model_params.ml_model_architecture && (
              <TextItemSectionDetails
                name="Model"
                value={
                  image.generation_model_params.ml_model_architecture === "flux"
                    ? "Pimento V2"
                    : "Pimento V1"
                }
              />
            )}
          {deletedAt !== null && (
            <TextItemSectionDetails
              name="Deleted at"
              value={new Date(deletedAt).toLocaleString()}
            />
          )}
        </>
      </SectionDetails>
      <SectionDetails name="Node parameters">
        {imageGenerationParameters?.node_generation_parameters.map(
          (it, index) => (
            <div
              key={index}
              className="flex-col py-100 border-t-025 border-primary-rest"
            >
              <TextItemSectionDetails name="Node" value={it.name} />
              <TextItemSectionDetails name="Status" value={it.status} />
              {it.started_at && (
                <TextItemSectionDetails
                  name="Started at"
                  value={String(new Date(it.started_at).toLocaleString())}
                />
              )}
              {it.ended_at && (
                <TextItemSectionDetails
                  name="Ended at"
                  value={String(new Date(it.ended_at).toLocaleString())}
                />
              )}
              <div className="flex-col gap-050">
                <span className="label-md-default text-secondary">
                  Parameters
                </span>
                {Object.keys(it.parameters).map((key) => (
                  <div key={key} className="flex-row overflow-x-auto pl-800">
                    <span className="label-md-semibold text-primary">
                      {key}{" "}
                    </span>
                    <span className="text-wrap label-md-default text-secondary pl-400">
                      {JSON.stringify(
                        it.parameters[key as keyof typeof it.parameters],
                      )}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ),
        )}
      </SectionDetails>
    </>
  );
};
