import type { ReactNode } from "react";
import { Switch } from "../../../../../components/Switch/Switch.tsx";
import { Info } from "../../../components/Info.tsx";

export const SwitchSection = ({
  value,
  onChange,
  switchName,
  switchInformationSection,
  isBeta = false,
}: {
  value: boolean;
  onChange: (value: boolean) => void;
  switchName: ReactNode;
  switchInformationSection?: ReactNode;
  isBeta?: boolean;
}) => (
  <div className="h-1000 flex-row items-center w-full justify-between">
    <div className="flex-row items-center gap-100 text-primary body-md-default">
      {switchName}
      {isBeta && (
        <div className="text-pimento-blue label-md-semibold px-150 rounded-full bg-blue-100">
          Beta
        </div>
      )}
      {switchInformationSection ? (
        <Info content={switchInformationSection} />
      ) : null}
    </div>
    <Switch value={value} onChange={onChange} />
  </div>
);
