export const OnboardingVerticalFormat = ({
  className,
}: {
  className?: string;
}) => (
  <svg
    width="35"
    height="49"
    viewBox="0 0 36 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="icon">
      <rect
        id="Rectangle 40026"
        x="1.71875"
        y="0.75"
        width="22.5"
        height="37.3085"
        rx="2.25"
      />
      <g id="Group 1400003152">
        <circle
          id="Ellipse 1183"
          cx="24.9141"
          cy="38.1914"
          r="10"
          fill="#F8F8F8"
        />
        <g id="Group 1400003136">
          <path
            id="Vector"
            d="M28.7626 34.3457L21.0703 42.6936"
            stroke="#FF3366"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M21.0703 34.4961L28.7626 42.844"
            stroke="#FF3366"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </g>
  </svg>
);
