import { useAppQuery } from "../http/useAppQuery.ts";
import type { User } from "../types.ts";

export const useUser = () => {
  const { data, ...rest } = useAppQuery<User>({
    queryKey: "users/me",
    refetchOnWindowFocus: false,
  });
  return { user: data, ...rest };
};
