export const Draw = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="23"
    height="23"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.25 5.5L16.1945 7.44454"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.625 3.21017C15.9897 2.8455 16.4843 2.64063 17 2.64062C17.2554 2.64062 17.5082 2.69092 17.7441 2.78864C17.9801 2.88637 18.1944 3.0296 18.375 3.21017C18.5556 3.39074 18.6988 3.6051 18.7965 3.84102C18.8942 4.07695 18.9445 4.32981 18.9445 4.58517C18.9445 4.84053 18.8942 5.09339 18.7965 5.32931C18.6988 5.56524 18.5556 5.7796 18.375 5.96017L6.91667 17.4185L3.25 18.3352L4.16667 14.6685L15.625 3.21017Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 2.75C6 4.15196 4.65196 5.5 3.25 5.5C4.65196 5.5 6 6.84802 6 8.25C6 6.84802 7.34802 5.5 8.75 5.5C7.34802 5.5 6 4.15196 6 2.75Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.4585 16.5C17.4585 17.201 16.7845 17.875 16.0835 17.875C16.7845 17.875 17.4585 18.549 17.4585 19.25C17.4585 18.549 18.1325 17.875 18.8335 17.875C18.1325 17.875 17.4585 17.201 17.4585 16.5Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
