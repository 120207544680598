import mixpanel from "mixpanel-browser";
import { getConfig } from "./environment.ts";

let email: string | undefined;

export const initEventTracking = (user?: { uuid: string; email: string }) => {
  const projectToken = getConfig({
    dev: "92acec2068138fd47cf453a221573383",
    staging: "d417d87b0b9d5ff993a47919ebb3e7b5",
    prod: "7096d75ca49af7f199a6d5127992abf7",
  });
  mixpanel.init(projectToken, { persistence: "localStorage" });
  // XXX: Extra call at login to set all browser and location information in mixpanel user profile
  mixpanel.people.set({});
  if (user) {
    mixpanel.identify(user.uuid);
    email = user.email;
  }
};

export const trackEvent = (
  eventName: string,
  payload: Record<string, unknown>,
) => {
  const timestamp = new Date().toISOString();
  email
    ? mixpanel.track(eventName, { ...payload, user_email: email, timestamp })
    : mixpanel.track(eventName, { ...payload, timestamp });
};
