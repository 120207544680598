import classNames from "classnames";
import {
  CDNImage,
  type SrcDimension,
} from "../../../components/Image/CDNImage.tsx";
import type { ImageContent } from "../types.ts";
import { LoadingTile } from "./LoadingTile.tsx";

export const ImageTile = ({
  image,
  loadingContentClassName,
  loading = "eager",
  fit,
  srcDimension,
}: {
  image: ImageContent;
  fit: "width" | "height";
  loadingContentClassName?: string;
  loading?: "eager" | "lazy";
  srcDimension: SrcDimension;
}) => {
  if (image.status === "processing") {
    return (
      <LoadingTile
        image={image}
        className={classNames(
          fit === "width" ? "w-full" : "h-full",
          "aspect-square",
          loadingContentClassName,
        )}
      />
    );
  }

  return (
    <div className={fit === "width" ? "w-full" : "h-full"}>
      <CDNImage
        className={fit === "width" ? "w-full" : "h-full"}
        imageClassName={fit === "width" ? "w-full" : "h-full"}
        src={image.url}
        loading={loading}
        srcDimension={srcDimension}
        widthConstrained={fit === "width"}
        heightConstrained={fit === "height"}
      />
    </div>
  );
};
