import { Route } from "react-router";
import { Routes } from "react-router-dom";
import { CREATE_WORKSPACE, EDIT_WORKSPACE } from "../../../routes.ts";
import { CreateHeaderContent } from "../Workspaces/CreateWorkspace/CreateHeaderContent.tsx";
import { EditHeaderContent } from "../Workspaces/EditWorkspace/EditHeaderContent/EditHeaderContent.tsx";

export const Header = () => (
  <div className="px-400 h-[54px] w-full bg-surface-primary-rest border-b flex-row items-center">
    <Routes>
      <Route path={`${CREATE_WORKSPACE}/*`} element={<CreateHeaderContent />} />
      <Route path={`${EDIT_WORKSPACE}/*`} element={<EditHeaderContent />} />
    </Routes>
  </div>
);
