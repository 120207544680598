import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Carousel } from "../../../../components/Carousel/Carousel.tsx";
import { TextField } from "../../../../components/TextField/TextField.tsx";
import { BOARDS, GENERATE_TOOL_PATH } from "../../../../routes.ts";
import { trackEvent } from "../../../../utils/trackEvent.ts";
import { useBoards } from "../hooks/useBoards.ts";
import type { Board } from "../types.ts";
import { BoardTile } from "./BoardTile.tsx";
import { NewBoardOrUpgrade } from "./NewBoardOrUpgrade.tsx";

export const BoardSection = () => {
  const [searchText, setSearchText] = useState("");

  const navigate = useNavigate();

  const { userBoards } = useBoards();

  const filteredBoards = useMemo(
    () =>
      userBoards?.filter((board: Board) =>
        board.name.toLowerCase().includes(searchText),
      ),
    [userBoards, searchText],
  );

  return userBoards && userBoards.length > 0 ? (
    <div className="flex-col">
      <div className="min-h-1600 px-800 py-400 flex-row items-center justify-between">
        <div className="heading-xl">Recent projects</div>
        <div className="flex-row-center gap-200">
          <TextField
            placeholder="Search..."
            size="md"
            value={searchText}
            onChange={(text) => {
              setSearchText(text.toLowerCase());
            }}
            rightIconName="Search"
            className="w-[192px]"
          />
          <NewBoardOrUpgrade />
        </div>
      </div>
      <div className="w-full">
        {filteredBoards && filteredBoards.length > 0 ? (
          <Carousel
            items={filteredBoards
              .sortDesc((board) => board.edited_at)
              .map((board) => (
                <BoardTile
                  key={board.uuid}
                  board={board}
                  onClick={() => {
                    trackEvent("project:open", { project_uuid: board.uuid });
                    navigate(`${BOARDS}/${board.uuid}/${GENERATE_TOOL_PATH}`);
                  }}
                />
              ))}
            leftButtonClassName="top-[86px] left-1000"
            rightButtonClassName="top-[86px] right-1000"
            className="px-800 gap-400"
          />
        ) : (
          <div className="h-[258px] flex-col-center body-lg-semibold text-secondary">
            No results found
          </div>
        )}
      </div>
    </div>
  ) : null;
};
