export const convertAlphaToMaskAndDilate = ({
  canvas,
  dilationWidth,
}: {
  canvas: HTMLCanvasElement;
  dilationWidth: number;
}) => {
  const ctx = canvas.getContext("2d");
  const size = {
    x: canvas.width,
    y: canvas.height,
  };

  const imageData = ctx?.getImageData(0, 0, size.x, size.y);
  const originalData = Uint8ClampedArray.from(imageData?.data ?? []);

  if (imageData) {
    for (let y = 0; y < size.y; y++) {
      for (let x = 0; x < size.x; x++) {
        let isMask = false;
        // eslint-disable-next-line no-labels
        neighborCheck: for (let j = -dilationWidth; j <= dilationWidth; j++) {
          for (let i = -dilationWidth; i <= dilationWidth; i++) {
            if (y + j < 0 || y + j >= size.y || x + i < 0 || x + i >= size.x) {
              break;
            }
            const dilationAlphaCoord = ((y + j) * size.x + (x + i)) * 4;
            if (originalData[dilationAlphaCoord] > 0) {
              isMask = true;
              // eslint-disable-next-line no-labels
              break neighborCheck;
            }
          }
        }
        const initCoord = (y * size.x + x) * 4;
        const pixelColor = isMask ? [255, 255, 255] : [0, 0, 0];
        imageData.data[initCoord] = pixelColor[0];
        imageData.data[initCoord + 1] = pixelColor[1];
        imageData.data[initCoord + 2] = pixelColor[2];
        imageData.data[initCoord + 3] = 255;
      }
    }

    ctx?.putImageData(imageData, 0, 0);
  }

  return new Promise<Blob>((resolve) => {
    void fetch(canvas.toDataURL()).then((res) => resolve(res.blob()));
  });
};
