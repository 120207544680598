import { useAppQuery } from "../../../http/useAppQuery.ts";
import type { Asset } from "../types.ts";
import { useSelectedAssetUuid } from "./useSelectedAssetUuid.ts";

export const useSelectedAsset = () => {
  const { selectedAssetUuid } = useSelectedAssetUuid();
  const assetQuery = useAppQuery<Asset>({
    queryKey: `assets/${selectedAssetUuid!}`,
  });

  return {
    selectedAsset: assetQuery.data,
  };
};
