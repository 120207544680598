import classNames from "classnames";
import { useMemo } from "react";
import Masonry from "react-responsive-masonry";
import { Navigate, Route } from "react-router";
import { Routes } from "react-router-dom";
import { BaseButton } from "../../../../components/Button/BaseButton.tsx";
import { Icon } from "../../../../components/Icon/Icon.tsx";
import { useOnMount } from "../../../../hooks/useOnMount.ts";
import { useUser } from "../../../../hooks/useUser.ts";
import { GENERATE_TOOL, TRANSFER_TOOL } from "../../../../routes.ts";
import { isUserAllowedTo } from "../../../../types.ts";
import { MasonryAssetTile } from "../../components/AssetImageTile/MasonryAssetTile.tsx";
import { FAVORITE_TAG_NAME } from "../../constants.ts";
import { useBoard } from "../../hooks/useBoard.ts";
import { useSelectedAssetUuid } from "../../hooks/useSelectedAssetUuid.ts";
import { showDeletedAssetsStore } from "../../stores/showDeletedAssetsStore.ts";
import { tagsFilterStore } from "../../stores/tagsFilterStore.ts";
import { EmptyStateInformation } from "../components/EmptyStateInformation.tsx";
import { TagsFilterBar } from "./TagsFilterBar.tsx";
import { filterAssetsByTags } from "./utils/tagFiltering.ts";

export const CreateWorkspace = () => (
  <Routes>
    <Route index element={<CreateWorkspaceImageGrid />} />
    <Route path={GENERATE_TOOL} element={<CreateWorkspaceImageGrid />} />
    <Route path={TRANSFER_TOOL} element={<CreateWorkspaceImageGrid />} />
    <Route path="*" element={<Navigate replace to={GENERATE_TOOL} />} />
  </Routes>
);

const CreateWorkspaceImageGrid = () => {
  const { board } = useBoard();
  const { user } = useUser();
  const { selectedTagsFilter } = tagsFilterStore.useState();
  const { selectedAssetUuid, clearSelectedAssetUuid } = useSelectedAssetUuid();

  useOnMount(() => {
    if (selectedAssetUuid) {
      document
        .getElementById(`AssetImageTile-${selectedAssetUuid}`)
        ?.scrollIntoView({ block: "center", behavior: "instant" });
      clearSelectedAssetUuid();
    }
  });

  const filteredAssets = useMemo(
    () =>
      filterAssetsByTags({
        assets: board.assets,
        filterTags: selectedTagsFilter,
      }),
    [board.assets, selectedTagsFilter],
  );

  return (
    <div className="pt-400 relative flex-fill flex-col w-full gap-100">
      <div className="px-600 flex-row justify-between">
        <TagsFilterBar tagsFilter={board.tags} />
        {user && isUserAllowedTo(user, "mode:debug") && (
          <ShowDeletedAssetsButton />
        )}
      </div>
      <div
        id="CreateWorkspaceGrid"
        className="py-400 px-600 flex-fill flex-col w-full overflow-auto"
      >
        {filteredAssets.length > 0 ? (
          <Masonry columnsCount={4} gutter="6px">
            {filteredAssets.map((asset) => (
              <MasonryAssetTile
                key={asset.uuid}
                asset={asset}
                favoriteTagUuid={
                  board.tags.find((it) => it.name === FAVORITE_TAG_NAME)?.uuid
                }
              />
            ))}
          </Masonry>
        ) : board.assets.length === 0 ? (
          <EmptyStateInformation
            title="Nothing here yet!"
            body="Use the generation bar to start creating."
          />
        ) : selectedTagsFilter.length === 1 &&
          selectedTagsFilter[0].name === FAVORITE_TAG_NAME ? (
          <EmptyStateInformation
            title="No favorites yet!"
            body="Click on the heart on any image to save them here."
          />
        ) : (
          <EmptyStateInformation
            title="Nothing here yet!"
            body="Tag images to see them here."
          />
        )}
      </div>
    </div>
  );
};

const ShowDeletedAssetsButton = () => {
  const { showDeletedAssets } = showDeletedAssetsStore.useState();
  return (
    <BaseButton
      className={classNames(
        "relative h-600 w-600 rounded-100 flex-col-center",
        showDeletedAssets
          ? "bg-pimento-red hover:bg-red-800"
          : "hover:bg-surface-primary-active",
      )}
      onClick={() => showDeletedAssetsStore.toggleShowDeletedAssets()}
    >
      <Icon
        name="Trash"
        size="sm"
        className={showDeletedAssets ? "stroke-inverse-rest" : ""}
      />
      <Icon
        name="Search"
        size="sm"
        className={classNames(
          "absolute top-[7px]",
          showDeletedAssets ? "stroke-inverse-rest" : "",
        )}
      />
    </BaseButton>
  );
};
