import type { LottieComponentProps } from "lottie-react";
import { lazy, Suspense, useEffect, useState } from "react";

const Lottie = lazy(() => import("lottie-react"));

type AnimationData = { layers: unknown[] };
export type LoadAnimationData = () => Promise<{ default: AnimationData }>;

export const Animation = ({
  loadData,
  ...props
}: Omit<LottieComponentProps, "animationData" | "ref"> & {
  loadData: LoadAnimationData;
}) => {
  const [animationData, setAnimationData] = useState<Record<string, unknown>>();

  useEffect(() => {
    void loadData().then((data) => {
      setAnimationData(data.default);
    });
  }, [loadData]);

  return (
    <Suspense fallback={null}>
      <Lottie animationData={animationData} {...props} />
    </Suspense>
  );
};
