import { Icon } from "../../../components/Icon/Icon.tsx";
import { EmbeddedImage } from "../../../components/Image/EmbeddedImage.tsx";
import type { StyleType } from "../../types.ts";

export const TipsSection = ({ styleType }: { styleType?: StyleType }) => (
  <div className="flex-row flex-wrap justify-center gap-1000 items-center text-secondary body-lg-semibold">
    {styleType === "style" ? (
      <div className="flex-col gap-400 items-center">
        <div className="flex-row gap-600">
          <div className="relative">
            <div className="flex-row rounded-200 overflow-hidden">
              <EmbeddedImage
                loadData={() => import("../assets/image_illustration_2.png")}
                height={50}
                width={50}
              />
              <EmbeddedImage
                loadData={() => import("../assets/image_illustration_1.png")}
                height={50}
                width={50}
              />
            </div>
            <Icon
              name="Valid"
              className="absolute -right-200 -bottom-200 stroke-inverse-rest stroke-md"
            />
          </div>
          <div className="relative ">
            <div className="flex-row rounded-200 overflow-hidden">
              <EmbeddedImage
                loadData={() => import("../assets/image_illustration_2.png")}
                height={50}
                width={50}
              />
              <EmbeddedImage
                loadData={() => import("../assets/image_illustration_3.png")}
                height={50}
                width={50}
              />
            </div>
            <Icon
              name="Invalid"
              className="absolute -right-200 -bottom-200 stroke-inverse-rest stroke-md"
            />
          </div>
        </div>
        <span>Same style</span>
      </div>
    ) : styleType === "object" ? (
      <div className="flex-col items-center gap-400">
        <div className="flex-row gap-600 items-end">
          <Icon name="OnboardingObjectAngle1" className="stroke-inverse-rest" />
          <Icon name="OnboardingObjectAngle2" className="stroke-inverse-rest" />
        </div>
        <span>Different angles</span>
      </div>
    ) : styleType === "character" ? (
      <div className="flex-col items-center gap-400">
        <div className="flex-row gap-600 items-end">
          <Icon
            name="OnboardingCharacterAngle1"
            className="stroke-inverse-rest"
          />
          <Icon
            name="OnboardingCharacterAngle2"
            className="stroke-inverse-rest"
          />
        </div>
        <span>Different angles</span>
      </div>
    ) : null}
    <div className="flex-col items-center gap-400">
      <div className="flex-row gap-600 items-end">
        <Icon name="OnboardingSquareFormat" className="stroke-inverse-rest" />
        <Icon name="OnboardingVerticalFormat" className="stroke-inverse-rest" />
        <Icon
          name="OnboardingHorizontalFormat"
          className="stroke-inverse-rest"
        />
      </div>
      <span>Squared</span>
    </div>
    <div className="flex-col gap-400 items-center">
      <Icon name="OnboardingNoTextTip" className="stroke-inverse-rest" />
      <span>No text</span>
    </div>
    <div className="flex-col gap-400 items-center">
      <div className="flex-row gap-600">
        <Icon name="OnboardingCenteredTip" className="stroke-inverse-rest" />
        <Icon name="OnboardingNonCenteredTip" className="stroke-inverse-rest" />
      </div>
      <span>Centered</span>
    </div>
    <div className="flex-col gap-400 items-center">
      <Icon name="OnboardingSizeTip" className="stroke-inverse-rest" />
      <span>Min 768px</span>
    </div>
  </div>
);
