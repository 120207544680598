import type { fabric } from "fabric";
import { BaseEditor } from "../BaseEditor/BaseEditor.tsx";

export const MaskEditor = ({
  fabricCanvas,
  onCanvasReady,
  initWidth,
  initHeight,
  backgroundImageUrl,
  maxWidth,
  maxHeight,
}: {
  fabricCanvas?: fabric.Canvas;
  initWidth: number;
  initHeight: number;
  onCanvasReady: (canvas: fabric.Canvas) => void;
  maxWidth: number;
  maxHeight: number;
  backgroundImageUrl?: string;
}) => (
  <BaseEditor
    fabricCanvas={fabricCanvas}
    initWidth={initWidth}
    initHeight={initHeight}
    onCanvasReady={(canvas) => {
      onCanvasReady(canvas);
    }}
    backgroundImageUrl={backgroundImageUrl}
    maxWidth={maxWidth}
    maxHeight={maxHeight}
  />
);
