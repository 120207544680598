import classNames from "classnames";
import type { ReactNode } from "react";
import { BaseClickableIcon } from "../../../components/Icon/BaseClickableIcon.tsx";
import { Popover } from "../../../components/Popover/Popover.tsx";

export const Info = ({
  content,
  className,
}: {
  content: string | ReactNode;
  className?: string;
}) => (
  <Popover
    content={content}
    side="right"
    className={classNames(
      "z-50 rounded-100 border bg-surface-primary-rest m-050 p-400 max-w-[280px] text-primary body-md-default",
      className,
    )}
  >
    <BaseClickableIcon
      name="Info"
      size="sm"
      className="p-0"
      iconClassName="pb-0 hover:opacity-60"
    />
  </Popover>
);
