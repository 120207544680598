import axios from "axios";
import { useState } from "react";
import { downloadFile } from "../utils/file.ts";

export const useDownload = () => {
  const [downloading, setDownloading] = useState(false);

  return [
    ({
      url,
      filename,
      onSuccess,
      onError,
    }: {
      url: string;
      filename: string;
      onSuccess?: () => void;
      onError?: () => void;
    }) => {
      setDownloading(true);
      void axios
        .get<Blob>(url, { responseType: "blob" })
        .then(({ data }) => {
          downloadFile(data, filename);
          onSuccess?.();
        })
        .catch(() => {
          onError?.();
        })
        .finally(() => setDownloading(false));
    },
    downloading,
  ] as const;
};
