import { useAppMutation } from "../../../http/useAppMutation.ts";
import { useUploadImage } from "../../Board/hooks/useUploadImage.ts";

export const useAddImageOnStyle = ({
  styleUuid,
  onUploaded,
  onSuccess,
  maxSize,
}: {
  styleUuid: string;
  onUploaded?: () => void;
  onSuccess?: (image_uuid: string) => void;
  maxSize: number;
}) => {
  const {
    mutate: upload,
    isLoading,
    progress,
  } = useUploadImage({
    onSuccess: (imageUuid) => {
      linkToStyle({
        image_uuid: imageUuid,
        style_uuid: styleUuid,
      });
    },
    describeImage: true,
    maxSize,
  });

  const {
    mutation: { mutate: linkToStyle },
  } = useAppMutation({
    path: "styles/add-training-image",
    invalidate: ["styles", `styles/${styleUuid}`],
    onCacheUpdated: () => {
      onUploaded?.();
    },
    onSuccess: (response: { data: { image_uuid: string } }) =>
      onSuccess?.(response.data.image_uuid),
  });

  return {
    mutate: ({ image }: { image: Blob }) => {
      upload({ image });
    },
    isLoading,
    progress,
  };
};
