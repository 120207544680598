import { z } from "zod";
import { createStorageStore } from "../../../../../../../utils/createStore.ts";

export const useIsFirstGenerationTooltipOpen = createStorageStore(
  localStorage,
  "first-generation-indication",
  z.object({ isOpen: z.boolean() }),
  { isOpen: true },
  ({ setter }) => ({
    setIsOpen: setter("isOpen"),
  }),
);
