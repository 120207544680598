import type { CSSProperties } from "react";

export const PimentoStar = ({
  className,
  filter,
  size,
  style,
}: {
  className?: string;
  filter?: string;
  style?: CSSProperties;
  size: number;
}) => (
  <svg
    width={size}
    height={(size * 112) / 97}
    viewBox="0 0 97 112"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    filter={filter}
    style={style}
  >
    <path
      d="M48.4999 0L34.7174 7.95765V19.9684L42.4582 40.2054L34.5879 32.054L13.7835 20.0432L0 28L0.000742713 43.9144L10.4029 49.9198L31.8 53.3349L20.8049 56.0748L0.000495142 68.0856L0 84L13.7833 91.9567L24.1854 85.9513L37.8417 69.1292L34.7171 80.0208V104.042L48.4999 112L62.2826 104.042V92.0316L54.5418 71.7943L62.4124 79.946L83.2167 91.9568L97 84L96.9995 68.0856L86.5973 62.0802L65.2 58.6651L76.1951 55.9252L96.9993 43.9144L96.9997 28L83.2165 20.0432L72.8146 26.0486L59.1583 42.8706L62.2826 31.9792V7.95765L48.4999 0Z"
      stroke="none"
    />
  </svg>
);
