import * as AccordionPrimitive from "@radix-ui/react-accordion";
import classNames from "classnames";
import { forwardRef, type ReactNode } from "react";
import { Icon, type IconSize } from "../Icon/Icon.tsx";

export const Accordion = ({
  elements,
  value,
  onValueChange,
  iconPosition,
  accordionItemClassName,
  accordionTriggerClassName,
  iconClassName,
  iconSize,
  iconTriggerOnly = false,
}: {
  elements: { title: ReactNode; content: ReactNode; value: string }[];
  value?: string;
  onValueChange?: (value: string) => void;
  iconPosition?: "left" | "right";
  accordionTriggerClassName?: string;
  accordionItemClassName?: string;
  iconClassName?: string;
  iconSize?: IconSize;
  iconTriggerOnly?: boolean;
}) => (
  <AccordionRoot
    type="single"
    value={value}
    onValueChange={iconTriggerOnly ? undefined : onValueChange}
    collapsible
    className="w-full"
  >
    {elements.map((element, index) => (
      <AccordionItem
        key={index}
        value={element.value}
        className={classNames("py-300 pt-050", accordionItemClassName)}
      >
        <AccordionTrigger
          iconPosition={iconPosition}
          className={accordionTriggerClassName}
          iconClassName={iconClassName}
          iconSize={iconSize}
          iconTriggerHook={() => {
            value ? onValueChange?.("") : onValueChange?.(element.value);
          }}
        >
          {element.title}
        </AccordionTrigger>
        <AccordionContent>{element.content}</AccordionContent>
      </AccordionItem>
    ))}
  </AccordionRoot>
);

const AccordionRoot = AccordionPrimitive.Root;

const AccordionItem = forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={classNames("border-b", className)}
    {...props}
  />
));
AccordionItem.displayName = "AccordionItem";

const AccordionTrigger = forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> & {
    iconPosition?: "left" | "right";
    iconClassName?: string;
    iconSize?: IconSize;
    iconTriggerHook?: () => void;
  }
>(
  (
    {
      className,
      children,
      iconPosition,
      iconClassName,
      iconSize,
      iconTriggerHook,
      ...props
    },
    ref,
  ) => (
    <AccordionPrimitive.Header className="flex">
      <AccordionPrimitive.Trigger
        ref={ref}
        className={classNames(
          "flex flex-1 items-center py-100 font-medium transition-all hover:underline [&[data-state=open]>svg]:rotate-180",
          iconPosition !== "left" ? "justify-between" : "justify-start",
          className,
        )}
        {...props}
      >
        {iconPosition === "left" ? (
          <>
            <Icon
              name="ChevronDown"
              size={iconSize}
              className={classNames(
                "shrink-0 transition-transform duration-200",
                iconClassName,
              )}
              onClick={iconTriggerHook}
            />
            {children}
          </>
        ) : (
          <>
            {children}
            <Icon
              name="ChevronDown"
              size={iconSize}
              className={classNames(
                "shrink-0 transition-transform duration-200",
                iconClassName,
              )}
              onClick={iconTriggerHook}
            />
          </>
        )}
      </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
  ),
);
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className={classNames(
      "overflow-hidden label-md-default transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down",
      className,
    )}
    {...props}
  >
    <div className="pt-0">{children}</div>
  </AccordionPrimitive.Content>
));
AccordionContent.displayName = AccordionPrimitive.Content.displayName;
