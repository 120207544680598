const host = window.location.host;

export const getConfig = <T>({
  dev,
  staging,
  prod,
}: {
  dev: T;
  staging: T;
  prod: T;
}) => {
  if (
    host.includes("localhost") ||
    host.startsWith("192") ||
    host.startsWith("172")
  ) {
    return dev;
  }
  if (host.includes("staging.")) return staging;
  return prod;
};
