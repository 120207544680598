// FIXME: make the fill path color dynamically changeable. This icon may change soon.
export const RemoveBackground = ({
  className,
  size,
}: {
  className?: string;
  size: number;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    className={className}
  >
    <path
      d="M16.3335 2.08337H3.66683C2.79238 2.08337 2.0835 2.79226 2.0835 3.66671V16.3334C2.0835 17.2078 2.79238 17.9167 3.66683 17.9167H16.3335C17.2079 17.9167 17.9168 17.2078 17.9168 16.3334V3.66671C17.9168 2.79226 17.2079 2.08337 16.3335 2.08337Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.83333 8.41667C7.70778 8.41667 8.41667 7.70778 8.41667 6.83333C8.41667 5.95888 7.70778 5.25 6.83333 5.25C5.95888 5.25 5.25 5.95888 5.25 6.83333C5.25 7.70778 5.95888 8.41667 6.83333 8.41667Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.95264 7.95276L9.99989 10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.2168 12.2167L14.7501 14.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.83333 14.75C7.70778 14.75 8.41667 14.0412 8.41667 13.1667C8.41667 12.2923 7.70778 11.5834 6.83333 11.5834C5.95888 11.5834 5.25 12.2923 5.25 13.1667C5.25 14.0412 5.95888 14.75 6.83333 14.75Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.7499 5.25L7.95264 12.0473"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
