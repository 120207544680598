import { memo } from "react";
import { BaseClickableIcon } from "../../../../components/Icon/BaseClickableIcon.tsx";
import { useDownload } from "../../../../hooks/useDownload.ts";
import { notifier } from "../../../../utils/notifier.ts";
import { ImageTile } from "../../../Board/components/ImageTile.tsx";
import type { ImageContent } from "../../../Board/types.ts";

export const MasonryContentTile = memo(
  ({ content }: { content: ImageContent }) => {
    const [download, isDownloading] = useDownload();
    return (
      <div className="w-full group relative overflow-clip block">
        {content.status === "succeeded" && (
          <>
            <div className="flex-row absolute bottom-200 right-200 gap-200  invisible group-hover:visible">
              <BaseClickableIcon
                name="Download"
                className="z-10 tile-action"
                tooltip={{ side: "top", content: "Download" }}
                onClick={() => {
                  // FIXME: Duplication of the download function in ImageDownloadButton. To be refactored.
                  download({
                    url: content.url,
                    filename: `${content.uuid}.png`,
                    onError: () => {
                      notifier.error(
                        `Failed to download image from URL ${content.url}`,
                      );
                    },
                  });
                }}
                isDisabled={isDownloading}
                isLoading={isDownloading}
              />
            </div>
            <ImageTile image={content} fit="width" srcDimension="xlarge1024" />
          </>
        )}
      </div>
    );
  },
);
MasonryContentTile.displayName = "MasonryContentTile";
