import type { fabric } from "fabric";
import type { IEvent } from "fabric/fabric-impl";
import { convertAlphaToMaskAndDilate } from "../BaseEditor/canvasUtils.ts";

const LASSO_STROKE_WIDTH = 3;

export const setOnCreateLasso = ({
  fabricCanvas,
  color,
}: {
  fabricCanvas: fabric.Canvas;
  color: string;
}) => {
  const onCreate = (
    options: fabric.IEvent<MouseEvent> & { path: fabric.Path },
  ) => {
    const fabricPath = options.path;
    fabricPath.set("fill", color);
    if (fabricPath.path) {
      fabricPath.set("path", [...fabricPath.path, ["Z"]] as fabric.Point[]);
    }
    const animateStroke = (path: fabric.Path) => {
      if (path.canvas) {
        path.animate("strokeDashOffset", "-=3", {
          duration: 100,
          onChange: path.canvas.renderAll.bind(fabricPath.canvas),
          onComplete: () => animateStroke(path),
        });
      }
    };
    animateStroke(fabricPath);
  };

  fabricCanvas.on("path:created", onCreate as (e: IEvent) => void);

  return () => {
    fabricCanvas.off("path:created", onCreate as (e: IEvent) => void);
  };
};

export const setLassoFreeDrawingBrush = ({
  fabricCanvas,
}: {
  fabricCanvas: fabric.Canvas;
}) => {
  fabricCanvas.freeDrawingBrush.color = "rgba(32, 200, 245, 0.99)";
  fabricCanvas.freeDrawingBrush.width = LASSO_STROKE_WIDTH;
  fabricCanvas.freeDrawingBrush.strokeDashArray = [10, 5];
  fabricCanvas.freeDrawingCursor = "crosshair";
};

export const updateExistingLassosColors = ({
  fabricCanvas,
  color,
}: {
  fabricCanvas: fabric.Canvas;
  color: string;
}) => {
  const fabricObjects = fabricCanvas.getObjects();
  for (const fabricObject of fabricObjects) {
    if (fabricObject.type === "path" && fabricObject.fill) {
      fabricObject.set("fill", color);
    }
    fabricCanvas.requestRenderAll();
  }
};

export const generateLassosMask = ({
  fabricCanvas,
}: {
  fabricCanvas: fabric.Canvas;
}) => {
  const backgroundImage = fabricCanvas.backgroundImage;
  fabricCanvas.backgroundImage = undefined;
  updatePathsStrokeWidth({ fabricCanvas, strokeWidth: 0 });

  fabricCanvas.renderAll();
  const objectsCanvas = fabricCanvas.toCanvasElement();
  if (backgroundImage) {
    fabricCanvas.setBackgroundImage(
      backgroundImage,
      fabricCanvas.renderAll.bind(fabricCanvas),
    );
  }
  updatePathsStrokeWidth({ fabricCanvas, strokeWidth: LASSO_STROKE_WIDTH });
  fabricCanvas.requestRenderAll();

  return convertAlphaToMaskAndDilate({
    canvas: objectsCanvas,
    dilationWidth: 0,
  });
};

const updatePathsStrokeWidth = ({
  fabricCanvas,
  strokeWidth,
}: {
  fabricCanvas: fabric.Canvas;
  strokeWidth: number;
}) => {
  for (const fabricObject of fabricCanvas.getObjects()) {
    if (fabricObject.type === "path") {
      fabricObject.set("strokeWidth", strokeWidth);
    }
  }
};
