import { Auth0Provider } from "@auth0/auth0-react";
import type { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { getConfig } from "../utils/environment.ts";

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState: any) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={getConfig({
        dev: "gopimento-dev.eu.auth0.com",
        staging: "gopimento-staging.eu.auth0.com",
        prod: "gopimento.eu.auth0.com",
      })}
      clientId={getConfig({
        dev: "oTJCvAzrwgAraRPHY4b7NjqzHjjrQClG",
        staging: "OnpYeHMxXm7t45mAjqtVWswMCjyKBFPO",
        prod: "gyPSoL0KnbXDKdeWhbJn7unPTVTwMZMJ",
      })}
      redirectUri={getConfig({
        dev: "http://localhost:3000",
        staging: "https://staging.app.pimento.design/",
        prod: "https://app.pimento.design/",
      })}
      audience={getConfig({
        dev: "https://api.gopimento.co",
        staging: "https://api.gopimento.co",
        prod: "https://api.gopimento.co",
      })}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
    >
      {children}
    </Auth0Provider>
  );
};
