import type { fabric } from "fabric";
import { createStore } from "../../../../../../../utils/createStore.ts";
import { clearObjects } from "../../../components/BaseEditor/utils.ts";

type FillMode = "brush" | "lasso";

export const generativeFillCanvasStore = createStore(
  {
    fabricCanvas: undefined as fabric.Canvas | undefined,
    numObjectsCreated: 0 as number,
    fillMode: "brush" as FillMode,
    brushSize: 90 as number,
  },
  ({ get, setter, set }) => ({
    setFabricCanvas: setter("fabricCanvas"),
    addObject: () => {
      setter("numObjectsCreated")(get().numObjectsCreated + 1);
    },
    removeObject: () => {
      setter("numObjectsCreated")(get().numObjectsCreated - 1);
    },
    setFillMode: (fillMode: FillMode) => {
      if (get().fillMode !== fillMode) {
        const fabricCanvas = get().fabricCanvas;
        if (fabricCanvas) {
          clearObjects(fabricCanvas);
          // @ts-expect-error history.js is not typed
          fabricCanvas.clearHistory();
        }
        set({
          fillMode,
        });
      }
    },
    setBrushSize: setter("brushSize"),
  }),
);
