import { createStore } from "../../../../../utils/createStore.ts";

export const overlayDisplayedContentUuidStore = createStore(
  {
    overlayDisplayedContentUuid: undefined as string | undefined,
  },
  ({ set }) => ({
    setOverlayDisplayedContentUuid: (contentUuid: string) =>
      set({
        overlayDisplayedContentUuid: contentUuid,
      }),
    clearOverlayDisplayedContentUuid: () =>
      set({
        overlayDisplayedContentUuid: undefined,
      }),
  }),
);
