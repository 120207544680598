export const OnboardingHorizontalFormat = ({
  className,
}: {
  className?: string;
}) => (
  <svg
    width="52"
    height="34"
    viewBox="0 0 52 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="icon">
      <rect
        id="Rectangle 40027"
        x="40.8594"
        y="0.917969"
        width="22.5"
        height="39.1956"
        rx="2.25"
        transform="rotate(90 40.8594 0.917969)"
      />
      <g id="Group 1400003152">
        <circle
          id="Ellipse 1183"
          cx="41.3203"
          cy="23.1914"
          r="10"
          fill="#F8F8F8"
        />
        <g id="Group 1400003136">
          <path
            id="Vector"
            d="M45.1689 19.3457L37.4766 27.6936"
            stroke="#FF3366"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M37.4766 19.4961L45.1689 27.844"
            stroke="#FF3366"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </g>
  </svg>
);
