import classNames from "classnames";
import type { ReactNode } from "react";
import { BaseButton } from "../../../components/Button/BaseButton.tsx";
import { Icon, type IconName } from "../../../components/Icon/Icon.tsx";
import { Tooltip } from "../../../components/Tooltip/Tooltip.tsx";

export const AssetMenuButton = ({
  iconName,
  title,
  isActive,
  onClick,
  tooltipContent,
}: {
  iconName: IconName;
  title: string;
  isActive: boolean;
  onClick: () => void;
  tooltipContent?: ReactNode;
}) => (
  <Tooltip
    side="left"
    align="start"
    arrowDisplayed
    delayDuration={500}
    content={tooltipContent}
    className="!bg-inverse !rounded-200 label-md-default !text-inverse-primary"
  >
    <BaseButton
      onClick={onClick}
      className="flex-col-center py-200 gap-150 group"
    >
      <div
        className={classNames(
          "flex-col-center p-200 rounded-200",
          isActive
            ? "bg-surface-primary-active"
            : "group-hover:bg-surface-primary-hover",
        )}
      >
        <Icon name={iconName} />
      </div>
      <div className="flex-col-center gap-100">
        <div className="label-sm-semibold text-primary">{title}</div>
      </div>
    </BaseButton>
  </Tooltip>
);
