import { type ImgHTMLAttributes, useMemo } from "react";
import { Image } from "./Image.tsx";

export type SrcDimension = keyof typeof SRC_DIMENSION_OPTIONS;

const SRC_DIMENSION_OPTIONS = {
  thumbnail64: "64",
  small128: "128",
  medium256: "256",
  large512: "512",
  xlarge1024: "1024",
} as const;

export const CDNImage = ({
  src,
  className,
  imageClassName,
  onLoad,
  loading = "eager",
  srcDimension,
  widthConstrained = true,
  heightConstrained = true,
  ...rest
}: {
  imageClassName?: string;
  srcDimension: SrcDimension;
  widthConstrained?: boolean;
  heightConstrained?: boolean;
} & ImgHTMLAttributes<HTMLImageElement>) => {
  const sizedSrc = useMemo(() => {
    if (src) {
      const url = new URL(src);
      if (widthConstrained) {
        url.searchParams.set("width", SRC_DIMENSION_OPTIONS[srcDimension]);
      }
      if (heightConstrained) {
        url.searchParams.set("height", SRC_DIMENSION_OPTIONS[srcDimension]);
      }
      return url.toString();
    }
    return src;
  }, [srcDimension, heightConstrained, src, widthConstrained]);
  return (
    <Image
      className={className}
      imageClassName={imageClassName}
      onLoad={onLoad}
      loading={loading}
      src={sizedSrc}
      {...rest}
    />
  );
};
