import { useEffect, useState } from "react";
import { useUser } from "../../../hooks/useUser.ts";
import { QuickActionsSection } from "./Action/QuickActionsSection.tsx";
import { BoardSection } from "./Board/BoardSection.tsx";
import { UserInformationDialog } from "./components/UserInformationDialog.tsx";
import { HowToSection } from "./HowToSection.tsx";
import { StyleSection } from "./Style/StyleSection.tsx";

export const HomeIndex = () => {
  const { user } = useUser();
  const [isUserInformationOpen, setIsUserInformationOpen] = useState(false);

  // XXX: We display the user information dialog only for new users meaning users who haven't filled out their
  // occupation, industry
  useEffect(() => {
    if (user && (user.occupation === null || user.industry === null)) {
      setIsUserInformationOpen(true);
    }
  }, [user]);

  return (
    <>
      <div className="h-full w-full py-400 flex-col overflow-y-auto gap-400">
        <QuickActionsSection />
        <BoardSection />
        <StyleSection />
        <HowToSection />
      </div>
      <UserInformationDialog
        isOpen={isUserInformationOpen}
        onOpenChange={setIsUserInformationOpen}
      />
    </>
  );
};
