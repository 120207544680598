import classNames from "classnames";
import { CDNImage } from "../../../components/Image/CDNImage.tsx";
import type { Style, StyleImage } from "../../types.ts";

export const StylePreview = ({
  style,
  title,
}: {
  style: Style;
  title: string;
}) => (
  <div className="flex-col gap-1200 items-center">
    {style.training_images.length >= 3 && (
      <div className="relative flex-row h-[17Opx] w-[170px] aspect-square">
        <div
          style={{
            transform: "translateY(12%) translateX(90%) rotate(4deg)",
          }}
          className="absolute h-5/6 aspect-square shadow-200 rounded-100"
        >
          <TrainingImage image={style.training_images[0]} />
        </div>
        <div
          style={{
            transform: "translateY(12%) translateX(-90%) rotate(-4deg)",
          }}
          className="absolute h-5/6 aspect-square shadow-200 rounded-100"
        >
          <TrainingImage image={style.training_images[1]} />
        </div>
        <div className="absolute h-full aspect-square shadow-200 rounded-100">
          <TrainingImage image={style.training_images[2]} />
        </div>
      </div>
    )}
    <div className="text-primary heading-2xl">{title}</div>
  </div>
);

const TrainingImage = ({ image }: { image: StyleImage }) => (
  <div className={classNames("h-full w-full overflow-clip rounded-100")}>
    <CDNImage
      className="h-full w-full"
      imageClassName="h-full w-full object-cover object-center"
      src={image.url}
      srcDimension="medium256"
    />
  </div>
);
