export const Invalid = ({ className }: { className?: string }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="Group 1400003152">
      <circle id="Ellipse 1183" cx="10" cy="10" r="9" fill="#F8F8F8" />
      <g id="Group 1400003136">
        <path
          id="Vector"
          d="M13.8486 6.1543L6.15625 14.5022"
          stroke="#FF3366"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M6.15625 6.30469L13.8486 14.6526"
          stroke="#FF3366"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);
