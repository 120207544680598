export const downloadFile = (blob: Blob, fileName: string) => {
  const hiddenLink = document.createElement("a");
  // We are revoking it just after so that ok
  const mediaObjectUrl = URL.createObjectURL(blob);
  hiddenLink.href = mediaObjectUrl;
  hiddenLink.download = fileName;
  hiddenLink.click();
  hiddenLink.remove();
  URL.revokeObjectURL(mediaObjectUrl);
};
