import classNames from "classnames";
import type { ReactNode } from "react";
import { BaseButton } from "../../../components/Button/BaseButton.tsx";
import { Icon } from "../../../components/Icon/Icon.tsx";
import { useStyles } from "../../Home/HomeIndex/hooks/useStyles.ts";
import { POPULAR_TAG } from "../../Home/HomeIndex/Style/constants.ts";
import type { Style } from "../../Home/HomeIndex/types.ts";
import type { StyleCategory } from "../../types.ts";

export const StylePickerTagSection = ({
  searchText,
  onSearchTextChange,
  selectedTag,
  onSelectedTagChange,
  selectedCategory,
  onSelectedCategoryChange,
  styles,
}: {
  searchText: string;
  onSearchTextChange: (text: string) => void;
  selectedTag: string | undefined;
  onSelectedTagChange: (tag: string | undefined) => void;
  selectedCategory: StyleCategory;
  onSelectedCategoryChange: (category: StyleCategory) => void;
  styles: Style[];
}) => {
  const { userStyles, libraryStylesCountByTags } = useStyles();

  return (
    <div className="flex-col h-full w-[204px] bg-input-surface-rest border-r text-primary">
      <div className="h-1200 w-full flex-row py-200 px-400 relative items-center">
        <input
          className="h-full w-full pl-200 pr-600 rounded-100 border body-md-default placeholder:text-disabled"
          placeholder="Search..."
          value={searchText}
          autoFocus
          onChange={(event) => {
            onSearchTextChange(event.target.value.toLowerCase());
            // XXX:  when the user writes a word, it automatically selects the "All" section to have broad results
            //  the input is cleaned when the user select another tag
            onSelectedCategoryChange("all");
            onSelectedTagChange(undefined);
          }}
        />
        <Icon
          name="Search"
          className="absolute right-600 stroke-inverse-rest"
        />
      </div>
      <div className="flex-col flex-shrink overflow-y-auto">
        {libraryStylesCountByTags &&
          Object.keys(libraryStylesCountByTags).includes(POPULAR_TAG) && (
            <TagButton
              onClick={() => {
                onSelectedTagChange(POPULAR_TAG);
                onSelectedCategoryChange("library");
                onSearchTextChange("");
              }}
              isActive={
                selectedCategory === "library" && selectedTag === POPULAR_TAG
              }
            >
              {POPULAR_TAG} ({libraryStylesCountByTags[POPULAR_TAG]})
            </TagButton>
          )}
        <TagButton
          onClick={() => {
            onSelectedCategoryChange("user");
            onSelectedTagChange(undefined);
            onSearchTextChange("");
          }}
          isActive={selectedCategory === "user"}
        >
          My models ({userStyles ? userStyles.length : 0})
        </TagButton>
        {libraryStylesCountByTags &&
          Object.keys(libraryStylesCountByTags)
            .filter((tagName) => tagName !== POPULAR_TAG)
            .sort()
            .map((tagName) => (
              <TagButton
                key={tagName}
                onClick={() => {
                  onSelectedTagChange(tagName);
                  onSelectedCategoryChange("library");
                  onSearchTextChange("");
                }}
                isActive={tagName === selectedTag}
              >
                {tagName} ({libraryStylesCountByTags[tagName]})
              </TagButton>
            ))}
        <TagButton
          onClick={() => {
            onSelectedCategoryChange("all");
            onSelectedTagChange(undefined);
          }}
          isActive={selectedCategory === "all"}
        >
          All ({styles.length})
        </TagButton>
      </div>
    </div>
  );
};

const TagButton = ({
  onClick,
  isActive,
  children,
}: {
  onClick: () => void;
  isActive: boolean;
  children: ReactNode;
}) => (
  <BaseButton
    onClick={onClick}
    className={classNames(
      "h-1000 px-400 label-lg-default",
      isActive
        ? "bg-surface-primary-active text-primary"
        : "hover:bg-surface-primary-hover text-secondary",
    )}
  >
    {children}
  </BaseButton>
);
