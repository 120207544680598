import { useCallback, useEffect, useRef } from "react";
import { useStableCallback } from "./useSyncRef.ts";

export const useDebounceFunction = <T extends (...args: any[]) => void>(
  cb: T,
  delay: number,
) => {
  const timer = useRef<ReturnType<typeof setTimeout>>();
  const cbRef = useStableCallback(cb);

  useEffect(
    () => () => {
      if (timer.current) clearTimeout(timer.current);
    },
    [],
  );

  return useCallback(
    (...args: Parameters<T>) => {
      if (timer.current) clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        cbRef(...args);
      }, delay);
    },
    [cbRef, delay],
  );
};
