import { useAppMutation } from "../../../http/useAppMutation.ts";
import { trackEvent } from "../../../utils/trackEvent.ts";
import { TOOL_TRACKING_NAMES } from "../constants.ts";
import { getSelectedTool } from "../utils/getSelectedTool.ts";
import { useBoard } from "./useBoard.ts";
import { useUploadImage } from "./useUploadImage.ts";

export const useAddImageOnBoard = ({
  onUploaded,
  onSuccess,
  maxSize,
}: {
  onUploaded?: (asset_uuid: string) => void;
  onSuccess?: (asset_uuid: string) => void;
  maxSize: number;
}) => {
  const { board } = useBoard();
  const tool = getSelectedTool();

  const {
    mutation: { mutate: linkToBoard },
  } = useAppMutation({
    path: "boards/add-asset",
    invalidate: ["boards", `boards/${board.uuid}`],
    onCacheUpdated: (response: { data: { asset_uuid: string } }) => {
      onUploaded?.(response.data.asset_uuid);
    },
    onSuccess: (response: { data: { asset_uuid: string } }) =>
      onSuccess?.(response.data.asset_uuid),
  });

  const {
    mutate: upload,
    isLoading,
    progress,
  } = useUploadImage({
    onSuccess: (contentUuid) =>
      linkToBoard({
        content_uuid: contentUuid,
        board_uuid: board.uuid,
      }),
    maxSize,
  });

  return {
    mutate: ({ image }: { image: Blob }) => {
      upload({ image });
      trackEvent(
        "content:upload",
        tool ? { generation_type: TOOL_TRACKING_NAMES[tool] } : {},
      );
    },
    isLoading,
    progress,
  };
};
