import { Icon } from "../../../../components/Icon/Icon.tsx";

export const ErrorMessage = ({ text }: { text: string }) => (
  <div className="flex-row items-center gap-100">
    <Icon name="TriangleAlert" size="sm" className="stroke-error-rest" />
    <span className="flex-shrink text-error body-md-default text-wrap">
      {text}
    </span>
  </div>
);
