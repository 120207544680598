export const Square = ({
  className,
  size = 14,
}: {
  className?: string;
  size?: number;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox={`0 0 ${size} ${size}`}
    className={className}
  >
    <rect width={size - 1} height={size - 1} x="0.5" y="0.5" rx="1.5" />
  </svg>
);
