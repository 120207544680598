import { toast } from "react-toastify";

export const notifier = {
  error: (message: string) =>
    toast.error(message, { className: "toast bg-[#fc2e2d] text-white" }),
  success: (message: string) =>
    toast.success(message, {
      className: "toast bg-[#86efac] text-primary",
    }),
};
