import { type ReactNode, useRef } from "react";
import { useOnMount } from "../../hooks/useOnMount.ts";

export const SizeObservableWrapper = ({
  children,
  className,
  onSizeChange,
}: {
  children: ReactNode;
  onSizeChange: ({ width, height }: { width: number; height: number }) => void;
  className?: string;
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useOnMount(() => {
    if (!wrapperRef.current) return;
    const resizeObserver = new ResizeObserver((entries) => {
      onSizeChange({
        width: entries[0].contentRect.width,
        height: entries[0].contentRect.height,
      });
    });
    resizeObserver.observe(wrapperRef.current);
    return () => resizeObserver.disconnect(); // clean up
  });
  return (
    <div className={className} ref={wrapperRef}>
      {children}
    </div>
  );
};
