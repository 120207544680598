import classNames from "classnames";
import { useState } from "react";
import { BaseButton } from "../Button/BaseButton.tsx";

export const EditableText = ({
  text,
  onTextChange,
  className,
  textClassName,
  inputClassName,
  placeholder,
  isLoading = false,
}: {
  text: string;
  onTextChange: (text: string) => void;
  placeholder?: string;
  className?: string;
  textClassName?: string;
  inputClassName?: string;
  isLoading?: boolean;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(text);

  const validate = (newText: string) => {
    setIsEditing(false);
    if (newText !== text && !newText.isEmpty()) onTextChange(newText);
  };
  const reset = () => {
    setIsEditing(false);
    setInputValue(text);
  };

  return (
    <div className={classNames("w-full h-600", className)}>
      {isEditing ? (
        <input
          autoFocus
          placeholder={placeholder}
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          className={classNames("w-full max-h-full", inputClassName)}
          onBlur={(event) => {
            validate(event.target.value);
          }}
          onKeyDown={(e) => {
            e.stopPropagation();
            if (inputValue && e.key === "Enter") validate(inputValue);
            if (e.key === "Escape") reset();
          }}
        />
      ) : (
        <BaseButton
          onClick={() => {
            setIsEditing(true);
          }}
          className={classNames(
            "w-full max-h-full",
            isLoading ? "flex-row-center" : "truncate",
            textClassName,
          )}
          loading={isLoading}
        >
          {placeholder && !text ? (
            <div className={classNames(textClassName, "!text-secondary")}>
              {placeholder}
            </div>
          ) : (
            text
          )}
        </BaseButton>
      )}
    </div>
  );
};
