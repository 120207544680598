export const OnboardingSizeTip = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="49"
    height="49"
    viewBox="0 0 49 49"
    fill="none"
    className={className}
  >
    <rect x="0.75" y="1.85156" width="38.5" height="38.5" rx="4.25" />
    <path
      d="M45.736 0.717157C45.5798 0.560948 45.3265 0.560948 45.1703 0.717157L42.6247 3.26274C42.4685 3.41895 42.4685 3.67222 42.6247 3.82843C42.7809 3.98464 43.0342 3.98464 43.1904 3.82843L45.4531 1.56569L47.7159 3.82843C47.8721 3.98464 48.1253 3.98464 48.2816 3.82843C48.4378 3.67222 48.4378 3.41895 48.2816 3.26274L45.736 0.717157ZM45.1703 41.2828C45.3265 41.4391 45.5798 41.4391 45.736 41.2828L48.2816 38.7373C48.4378 38.581 48.4378 38.3278 48.2816 38.1716C48.1253 38.0154 47.8721 38.0154 47.7159 38.1716L45.4531 40.4343L43.1904 38.1716C43.0342 38.0154 42.7809 38.0154 42.6247 38.1716C42.4685 38.3278 42.4685 38.581 42.6247 38.7373L45.1703 41.2828ZM45.0531 1V41H45.8531V1H45.0531Z"
      fill="#B3B3B3"
      stroke="#B3B3B3"
    />
    <path
      d="M40.736 46.2828C40.8922 46.1266 40.8922 45.8734 40.736 45.7172L38.1904 43.1716C38.0342 43.0154 37.7809 43.0154 37.6247 43.1716C37.4685 43.3278 37.4685 43.581 37.6247 43.7373L39.8874 46L37.6247 48.2627C37.4685 48.419 37.4685 48.6722 37.6247 48.8284C37.7809 48.9846 38.0342 48.9846 38.1904 48.8284L40.736 46.2828ZM0.170278 45.7172C0.0140687 45.8734 0.0140687 46.1266 0.170278 46.2828L2.71586 48.8284C2.87207 48.9846 3.12534 48.9846 3.28155 48.8284C3.43776 48.6722 3.43776 48.419 3.28155 48.2627L1.01881 46L3.28155 43.7373C3.43776 43.581 3.43776 43.3278 3.28155 43.1716C3.12534 43.0154 2.87207 43.0154 2.71586 43.1716L0.170278 45.7172ZM40.4531 45.6L0.453121 45.6V46.4L40.4531 46.4V45.6Z"
      fill="#B3B3B3"
      stroke="#B3B3B3"
    />
  </svg>
);
