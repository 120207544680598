import classNames from "classnames";

export const RadialProgress = ({
  progress,
  minProgress = 0,
  maxProgress = 100,
  counterClockwise = false,
  backgroundClassName,
}: {
  progress: number;
  minProgress?: number;
  maxProgress?: number;
  counterClockwise?: boolean;
  backgroundClassName?: string;
}) => {
  const cappedProgress = Math.max(minProgress, Math.min(progress, maxProgress));
  const progressPercentage =
    (cappedProgress / (maxProgress - minProgress)) * 100;
  return (
    <div className="relative flex items-center justify-center">
      <div
        className="rounded-full h-600 w-600"
        style={{
          background: counterClockwise
            ? `conic-gradient(var(--grey-10) ${
                100 - progressPercentage
              }%, rgb(var(--white)) ${100 - progressPercentage}%)`
            : `conic-gradient(rgb(var(--white)) ${progressPercentage}%, var(--grey-10) ${progressPercentage}%)`,
        }}
      />
      <div
        className={classNames(
          "absolute flex items-center justify-center w-500 h-500 rounded-full",
          backgroundClassName,
        )}
      >
        <span className="text-white label-md-default">{progress}</span>
      </div>
    </div>
  );
};
