import { Animation } from "../components/Animation/Animation.tsx";

export const MobileView = () => (
  <div className="flex-col-center h-full gap-1000 text-primary text-center pb-800">
    <div className="max-w-[250px]">
      <Animation
        loadData={() => import("../assets/boardInitLoading.json")}
        loop
        autoplay
        className="w-full"
      />
    </div>
    <div className="flex-col-center">
      <span className="heading-2xl">We're desktop only!</span>
      <div className="flex-col-center body-lg-default py-400">
        <span>Pimento is currently built for desktop.</span>
        <span> See you there!</span>
      </div>
    </div>
  </div>
);
