import type { ReactNode } from "react";
import { Accordion } from "../../../../../components/Accordion/Accordion.tsx";

const ACCORDION_UNIQUE_VALUE = "collapsable";
export const CollapsableSettingsSectionWrapper = ({
  name,
  content,
  open,
  onOpenChange,
}: {
  name: string;
  content: ReactNode;
  open?: boolean;
  onOpenChange?: (value: boolean) => void;
}) => (
  <div className="py-200 px-400">
    <Accordion
      value={
        open === undefined ? undefined : open ? ACCORDION_UNIQUE_VALUE : ""
      }
      onValueChange={(value) => {
        onOpenChange?.(value === ACCORDION_UNIQUE_VALUE);
      }}
      accordionTriggerClassName="hover:!no-underline !py-0"
      accordionItemClassName="border-b-0 !p-0"
      iconSize="sm"
      elements={[
        {
          title: (
            <div className="h-1000 flex-row-center body-md-semibold text-primary">
              {name}
            </div>
          ),
          content: <div>{content}</div>,
          value: ACCORDION_UNIQUE_VALUE,
        },
      ]}
    />
  </div>
);
