import { useAppQuery } from "../../../../../http/useAppQuery.ts";
import type { ImageContent, UpscalerGeneratedImage } from "../../../types.ts";
import { initImageColorInfluenceLabelMapping } from "../../CreateWorkspace/Tools/StyleTransferView/constants.ts";
import type { ImaginationStrengthPresets } from "../../EditWorkspace/Tools/UpscaleView/types.ts";
import { TextItemSectionDetails } from "../SectionDetails.tsx";

export const ImageGenerationParameters = ({
  image,
  variant = "light",
}: {
  image: ImageContent;
  variant?: "light" | "dark";
}) => {
  // FIXME: We should display default value from preset if it's not set (as done with upscale type)

  switch (image.generation_model_type) {
    case "text_to_image":
      return (
        <TextItemSectionDetails
          name="Exclude"
          value={image.generation_model_params.negative_prompt || "Nothing"}
          variant={variant}
        />
      );
    case "image_to_image":
      return (
        <>
          <TextItemSectionDetails
            name="Creativity preset"
            value={image.generation_model_params.creativity_strength_preset}
            variant={variant}
          />
          {image.generation_model_params.prompt_strength ? (
            <TextItemSectionDetails
              name="Creativity"
              value={
                Math.round(
                  image.generation_model_params.prompt_strength * 100,
                ) + "%"
              }
              variant={variant}
            />
          ) : null}
          <TextItemSectionDetails
            name="Exclude"
            value={image.generation_model_params.negative_prompt || "Nothing"}
            variant={variant}
          />
        </>
      );
    case "style_transfer":
      return (
        <>
          <TextItemSectionDetails
            name="Transfer strength"
            value={image.generation_model_params.transfer_strength_preset}
            variant={variant}
          />
          <TextItemSectionDetails
            name="Input type"
            value={
              initImageColorInfluenceLabelMapping[
                image.generation_model_params.init_image_color_influence
              ]
            }
            variant={variant}
          />
          {image.generation_model_params.colors_strength ? (
            <TextItemSectionDetails
              name="Input color"
              value={
                Math.round(
                  image.generation_model_params.colors_strength * 100,
                ) + "%"
              }
              variant={variant}
            />
          ) : null}
          {image.generation_model_params.structure_strength ? (
            <TextItemSectionDetails
              name="Input structure"
              value={
                Math.round(
                  image.generation_model_params.structure_strength * 100,
                ) + "%"
              }
              variant={variant}
            />
          ) : null}
          {image.generation_model_params.creativity ? (
            <TextItemSectionDetails
              name="Creativity"
              value={
                Math.round(image.generation_model_params.creativity * 100) + "%"
              }
              variant={variant}
            />
          ) : null}
          <TextItemSectionDetails
            name="Exclude"
            value={image.generation_model_params.negative_prompt || "Nothing"}
            variant={variant}
          />
        </>
      );
    case "upscaler":
      return <UpscaleGenerationParameters image={image} variant={variant} />;
    case "generative_fill":
      return (
        <>
          <TextItemSectionDetails
            name="Preset"
            value={image.generation_model_params.creativity_strength_preset}
            variant={variant}
          />
          {image.generation_model_params.prompt_strength ? (
            <TextItemSectionDetails
              name="Creativity"
              value={
                Math.round(
                  image.generation_model_params.prompt_strength * 100,
                ) + "%"
              }
              variant={variant}
            />
          ) : null}
          <TextItemSectionDetails
            name="Exclude"
            value={image.generation_model_params.negative_prompt || "Nothing"}
            variant={variant}
          />
        </>
      );
    case null:
      return undefined;
  }
};

const UpscaleGenerationParameters = ({
  image,
  variant = "light",
}: {
  image: UpscalerGeneratedImage;
  variant?: "light" | "dark";
}) => {
  const { data: upscaleImaginationStrengthPresets } =
    useAppQuery<ImaginationStrengthPresets>({
      queryKey: "presets/upscale",
    });

  return (
    <>
      <TextItemSectionDetails
        name="Preset"
        value={image.generation_model_params.imagination_strength_preset}
        variant={variant}
      />
      <TextItemSectionDetails
        name="Scale factor"
        value={String(image.generation_model_params.scale_factor) + "x"}
        variant={variant}
      />
      {image.generation_model_params.creativity ??
      upscaleImaginationStrengthPresets?.[
        image.generation_model_params.imagination_strength_preset
      ].creativity ? (
        <TextItemSectionDetails
          name="Creativity"
          value={
            Math.round(
              (image.generation_model_params.creativity ??
                upscaleImaginationStrengthPresets?.[
                  image.generation_model_params.imagination_strength_preset
                ].creativity ??
                0) * 100,
            ) + "%"
          }
          variant={variant}
        />
      ) : null}
      {image.generation_model_params.resemblance ??
      upscaleImaginationStrengthPresets?.[
        image.generation_model_params.imagination_strength_preset
      ].resemblance ? (
        <TextItemSectionDetails
          name="Similarity"
          value={
            Math.round(
              (image.generation_model_params.resemblance ??
                upscaleImaginationStrengthPresets?.[
                  image.generation_model_params.imagination_strength_preset
                ].resemblance ??
                0) * 100,
            ) + "%"
          }
          variant={variant}
        />
      ) : null}
      <TextItemSectionDetails
        name="Exclude"
        value={image.generation_model_params.negative_prompt || "Nothing"}
        variant={variant}
      />
    </>
  );
};
