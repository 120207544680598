import { Info } from "../../../components/Info.tsx";
import { PresetButton } from "../../../components/PresetButton.tsx";
import { QUALITY_PRESETS } from "../../../constants.ts";
import type { QualityPreset } from "../../../types.ts";

export const QualityPresetSection = ({
  value,
  onChange,
}: {
  value: QualityPreset;
  onChange: (preset: QualityPreset) => void;
}) => (
  <div className="flex-col gap-100">
    <div className="flex-row gap-100 items-center text-primary body-md-default">
      Quality
      <Info
        content={
          <ul className="text-primary">
            <li>
              <span className="label-md-semibold">Fast</span> - Generations are
              faster but can be of lower quality.
            </li>
            <li>
              <span className="label-md-semibold">High</span> - Generations are
              slower and will be of higher quality.
            </li>
          </ul>
        }
      />
    </div>
    <div className="flex-row-center gap-100">
      {QUALITY_PRESETS.map((preset) => (
        <PresetButton
          key={preset}
          isSelected={value === preset}
          onClick={() => onChange(preset)}
        >
          {preset}
        </PresetButton>
      ))}
    </div>
  </div>
);
