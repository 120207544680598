export const EmptyStateInformation = ({
  title,
  body,
}: {
  title: string;
  body: string;
}) => (
  <div className="flex-col-center flex-fill text-primary">
    <div className="heading-2xl">{title}</div>
    <span className="body-lg-default">{body}</span>
  </div>
);
