import classNames from "classnames";
import type { ReactNode } from "react";

export const PresetButton = ({
  isSelected,
  onClick,
  className,
  children,
}: {
  isSelected: boolean;
  onClick: () => void;
  className?: string;
  children: ReactNode;
}) => (
  <button
    type="button"
    className={classNames(
      "flex-fill px-200 rounded-100 border h-800 label-md-default text-primary text-center capitalize",
      className,
      isSelected
        ? "bg-surface-primary-active cursor-auto"
        : "hover:bg-surface-primary-hover",
    )}
    onClick={onClick}
  >
    {children}
  </button>
);
