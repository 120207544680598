import { useEffect, useRef } from "react";

export const useOnMount = (cb: () => void) => {
  const hasRun = useRef(false);

  useEffect(() => {
    if (hasRun.current) return;
    hasRun.current = true;
    cb();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
