import { createStore } from "../../utils/createStore.ts";

export const upgradeDialogStore = createStore(
  { isDialogOpen: false as boolean, isClosable: false as boolean },
  ({ setter }) => ({
    openDialog: ({ isClosable = false }: { isClosable?: boolean } = {}) => {
      setter("isDialogOpen")(true);
      setter("isClosable")(isClosable);
    },
    closeDialog: () => {
      setter("isDialogOpen")(false);
    },
  }),
);
