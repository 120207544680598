import { Icon } from "../../../../../../components/Icon/Icon.tsx";
import { Toggle } from "../../../../../../components/Toggle/Toggle.tsx";
import { generativeFillCanvasStore } from "./stores/generativeFillCanvasStore.ts";

export const FillModeToggle = () => {
  const { fillMode } = generativeFillCanvasStore.useState();
  return (
    <div>
      <Toggle
        leftItem={
          <div className="flex-row-center text-primary body-md-default gap-050">
            <Icon name="Paintbrush" />
            Brush
          </div>
        }
        rightItem={
          <div className="flex-row-center text-primary body-md-default gap-050">
            <Icon name="LassoSelect" />
            Lasso
          </div>
        }
        value={fillMode === "brush" ? "left" : "right"}
        onValueChange={(value) =>
          generativeFillCanvasStore.setFillMode(
            value === "left" ? "brush" : "lasso",
          )
        }
      />
    </div>
  );
};
