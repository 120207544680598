import classNames from "classnames";
import { useEffect, useState } from "react";
import { BaseClickableIcon } from "../../../components/Icon/BaseClickableIcon.tsx";
import { Icon } from "../../../components/Icon/Icon.tsx";
import { ImageTile } from "../components/ImageTile.tsx";
import { useBoard } from "../hooks/useBoard.ts";
import { useSelectedAssetUuid } from "../hooks/useSelectedAssetUuid.ts";

export const ImageStripe = () => {
  const { board } = useBoard();
  const { selectedAssetUuid, setSelectedAssetUuid } = useSelectedAssetUuid();

  const [selectedAssetIndex, setSelectedAssetIndex] = useState(0);

  useEffect(() => {
    if (
      selectedAssetUuid &&
      board.assets.findIndex((asset) => asset.uuid === selectedAssetUuid) !==
        selectedAssetIndex
    ) {
      setSelectedAssetIndex(
        board.assets.findIndex((asset) => asset.uuid === selectedAssetUuid),
      );
    }
  }, [board.assets, selectedAssetIndex, selectedAssetUuid]);

  useEffect(() => {
    document
      .getElementById(selectedAssetUuid ?? "")
      ?.scrollIntoView({ inline: "center", behavior: "instant" });
  }, [selectedAssetUuid]);

  return (
    <div
      className="w-full py-100 flex-row rounded-400"
      style={{ backgroundColor: "var(--grey-14)" }}
    >
      <BaseClickableIcon
        name="ChevronLeft"
        iconClassName="stroke-button-primary-rest"
        onClick={() => {
          setSelectedAssetIndex((index) => index - 1);
          setSelectedAssetUuid(board.assets[selectedAssetIndex - 1].uuid);
        }}
        disabled={selectedAssetIndex === 0}
      />
      <div className="flex-fill relative">
        <div className="absolute left-0 top-0 h-full w-200 z-10 bg-gradient-to-r from-[var(--grey-14)]" />
        <div id="image-stripe" className="w-full flex-row overflow-x-auto">
          <div className="pl-200" />
          {board.assets.map((asset) => (
            <div key={asset.uuid} id={asset.uuid} className="py-100">
              <div
                className={classNames(
                  "p-050 rounded-200",
                  asset.uuid === selectedAssetUuid && "border",
                )}
                style={{
                  backgroundColor:
                    asset.uuid === selectedAssetUuid
                      ? "var(--grey-14)"
                      : undefined,
                  borderColor:
                    asset.uuid === selectedAssetUuid
                      ? "var(--grey-6)"
                      : undefined,
                }}
              >
                <div
                  className="relative cursor-pointer h-1600 rounded-150 overflow-hidden"
                  onClick={() => {
                    setSelectedAssetUuid(asset.uuid);
                  }}
                >
                  {asset.deleted_at !== null && (
                    // FIXME: clean up the css colors
                    <Icon
                      name="Trash"
                      className="z-10 absolute bottom-0 right-0 p-050 bg-pimento-red stroke-inverse-rest"
                    />
                  )}
                  <ImageTile
                    image={asset.content}
                    loading="lazy"
                    fit="height"
                    loadingContentClassName="label-md-default !p-0 !bg-input-surface-rest"
                    srcDimension="medium256"
                  />
                </div>
              </div>
            </div>
          ))}
          <div className="pl-200" />
        </div>
        <div className="absolute right-0 top-0 h-full w-200 bg-gradient-to-l from-[var(--grey-14)]" />
      </div>
      <BaseClickableIcon
        name="ChevronRight"
        iconClassName="stroke-button-primary-rest"
        onClick={() => {
          setSelectedAssetIndex((index) => index + 1);
          setSelectedAssetUuid(board.assets[selectedAssetIndex + 1].uuid);
        }}
        disabled={selectedAssetIndex === board.assets.length - 1}
      />
    </div>
  );
};
