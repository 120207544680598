import { useSearchParams } from "react-router-dom";
import { updateSearch } from "../../../utils/url.ts";

export const useSelectedAssetUuid = () => {
  const [params, setParams] = useSearchParams();
  const assetUuidParams = params.get("assetUuid") ?? undefined;
  return {
    selectedAssetUuid: assetUuidParams,
    setSelectedAssetUuid: (assetUuid: string) => {
      setParams(updateSearch({ assetUuid }));
    },
    clearSelectedAssetUuid: () => {
      if (assetUuidParams) setParams(updateSearch({ assetUuid: null }));
    },
  };
};
