import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useAppMutation } from "../../../http/useAppMutation.ts";
import { useBoard } from "./useBoard.ts";
import { useSelectedAsset } from "./useSelectedAsset.ts";

export const useUpdateAsset = () => {
  const { selectedAsset } = useSelectedAsset();
  const { board } = useBoard();
  const { mutate: updateAssetContent } = useAppMutation({
    path: "assets/update",
    invalidate: [`boards/${board.uuid}`],
  }).mutation;

  const queryClient = useQueryClient();

  return useCallback(
    (contentUuid: string) => {
      if (selectedAsset) {
        updateAssetContent(
          {
            uuid: selectedAsset.uuid,
            content_uuid: contentUuid,
          },
          {
            onSuccess: () => {
              void queryClient.invalidateQueries({
                queryKey: [`assets/${selectedAsset.uuid}`],
              });
            },
          },
        );
      }
    },
    [selectedAsset, updateAssetContent, queryClient],
  );
};
