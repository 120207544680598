import classNames from "classnames";
import { BaseButton } from "../../../components/Button/BaseButton.tsx";
import { Icon } from "../../../components/Icon/Icon.tsx";
import { CDNImage } from "../../../components/Image/CDNImage.tsx";
import { GENERIC_STYLE_UUID } from "../../constants.ts";
import { useCreateStyle } from "../../Home/HomeIndex/hooks/useCreateStyle.ts";
import type { Style } from "../../Home/HomeIndex/types.ts";

export const StyleSelectionSection = ({
  styles,
  selectedStyleUuid,
  onStyleUuidSelect,
}: {
  styles: Style[];
  selectedStyleUuid: string | undefined;
  onStyleUuidSelect: (styleUuid: string) => void;
}) => {
  const { createStyle, isCreateStyleLoading } = useCreateStyle();

  return (
    <div className="flex-col w-[204px] border-r text-primary">
      <BaseButton
        loading={isCreateStyleLoading}
        onClick={createStyle}
        className="group flex-row items-center h-1200 px-400 gap-100 hover:bg-surface-primary-active"
      >
        <Icon name="Plus" className="mb-050" />
        <span className="label-lg-semibold">Create new model</span>
      </BaseButton>
      <div className="flex-col flex-fill overflow-y-auto">
        {styles.map((style) => (
          <BaseButton key={style.uuid}>
            <div
              className={classNames(
                selectedStyleUuid === style.uuid
                  ? "bg-surface-primary-active"
                  : "hover:bg-surface-primary-hover",
                "flex-row h-1000 px-400 gap-200 items-center",
              )}
              onClick={() => onStyleUuidSelect(style.uuid)}
            >
              <div className=" relative w-800 h-800">
                {style.thumbnail_url ? (
                  <CDNImage
                    src={style.thumbnail_url}
                    className="w-full h-full"
                    imageClassName="h-full w-full object-cover object-center"
                    srcDimension="thumbnail64"
                  />
                ) : style.uuid === GENERIC_STYLE_UUID ? (
                  <div className="w-full h-full flex-row-center bg-pimento-blue">
                    <Icon
                      size="sm"
                      name="PimentoStar"
                      className=" fill-button-primary-rest"
                    />
                  </div>
                ) : (
                  <div className="flex-col-center h-full w-full bg-surface-primary-active" />
                )}
                {["created", "training"].includes(style.status) ? (
                  <div className="absolute left-0 top-0 w-full h-full bg-surface-primary-rest" />
                ) : null}
              </div>
              <span
                className={classNames(
                  style.name?.isBlank() && "italic",
                  "truncate w-[70%] label-md-default",
                  ["created", "training"].includes(style.status)
                    ? "text-disabled"
                    : "text-primary",
                )}
              >
                {style.name ? style.name : "Untitled"}
              </span>
            </div>
          </BaseButton>
        ))}
      </div>
    </div>
  );
};
