/* eslint-disable */
// https://stackoverflow.com/a/2117523/1934675
// This function is useful to create temporary unique values.
// It is not as random as other sources of UUIDs, and is not meant to be persisted to a database
export const pseudoRandomUUID = (): string =>
  // @ts-ignore
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16),
  );

// https://stackoverflow.com/a/12043228/
export const isColorDark = (hex: string) => {
  const hexWithoutHash = hex.slice(1); // strip #
  const rgb = parseInt(hexWithoutHash, 16); // convert rrggbb to decimal
  const red = (rgb >> 16) & 0xff; // extract red
  const green = (rgb >> 8) & 0xff; // extract green
  const blue = (rgb >> 0) & 0xff; // extract blue

  const luma = 0.2126 * red + 0.7152 * green + 0.0722 * blue; // per ITU-R BT.709

  return luma < 80;
};
