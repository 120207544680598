import { Animation } from "../components/Animation/Animation.tsx";

export const Maintenance = () => (
  <div className="flex-col-center h-full gap-800">
    <Animation
      loadData={() => import("../assets/boardInitLoading.json")}
      loop
      autoplay
    />
    <div className="mb-1000 flex-col-center heading-xl">
      <span>spicing things up!</span>
      <span>pimento is undergoing maintenance due to our flaming success.</span>
      <span>please come back soon.</span>
    </div>
  </div>
);
