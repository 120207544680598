import classNames from "classnames";
import { BaseButton } from "../../../components/Button/BaseButton.tsx";
import { Icon } from "../../../components/Icon/Icon.tsx";

export const ModelCreationBackButton = ({
  className,
  onClick,
}: {
  className: string;
  onClick: () => void;
}) => (
  <BaseButton
    className={classNames(
      "rounded-100 border bg-surface-primary-rest hover:bg-surface-primary-hover",
      className,
    )}
    onClick={onClick}
  >
    <Icon name="ArrowLeft" size="sm" className="m-200" />
  </BaseButton>
);
