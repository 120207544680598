export const OnboardingNonCenteredTip = ({
  className,
}: {
  className?: string;
}) => (
  <svg
    width="50"
    height="48"
    viewBox="0 0 51 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="icon">
      <rect
        id="Rectangle 40023"
        x="0.859375"
        y="0.75"
        width="38.5"
        height="38.5"
        rx="4.25"
      />
      <path
        id="Union"
        d="M1.21875 10.3672L3.61185 11.7882V16.0778L3.06937 18.0227L5.44051 15.0187L7.24659 13.9463L9.63978 15.3672L9.6397 18.209L6.02748 20.3538L4.11839 20.8431L7.83361 21.4529L9.63974 22.5253L9.63982 25.3672L7.24664 26.788L3.63438 24.6433L2.26782 23.1876L3.61185 26.8014V28.9462L1.21875 30.3672"
      />
      <g id="Group 1400003152">
        <circle
          id="Ellipse 1183"
          cx="39.9766"
          cy="36.6328"
          r="10"
          fill="#F8F8F8"
        />
        <g id="Group 1400003136">
          <path
            id="Vector"
            d="M43.8251 32.7871L36.1328 41.135"
            stroke="#FF3366"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M36.1328 32.9375L43.8251 41.2854"
            stroke="#FF3366"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </g>
  </svg>
);
