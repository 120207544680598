import { type ReactNode, useState } from "react";
import { SizeObservableWrapper } from "../../../../../../components/SizeObservableWrapper/SizeObservableWrapper.tsx";
import { LoadingTile } from "../../../../components/LoadingTile.tsx";
import type { ImageContent } from "../../../../types.ts";

export const BaseImageContainer = ({
  image,
  children,
}: {
  image?: ImageContent;
  children?: ReactNode;
}) =>
  image === undefined ? null : image.status === "processing" ? (
    <SquareLoadingImageContainer image={image} />
  ) : (
    <div className="flex-row justify-center max-h-full max-w-full">
      {children}
    </div>
  );

const SquareLoadingImageContainer = ({ image }: { image: ImageContent }) => {
  const [wrapperSize, setWrapperSize] = useState<{
    width: number;
    height: number;
  }>();

  return (
    <SizeObservableWrapper
      className="flex-fill w-full flex-col-center"
      onSizeChange={({ width, height }) => setWrapperSize({ width, height })}
    >
      {wrapperSize !== undefined && (
        <div
          style={{
            width: Math.min(wrapperSize.width, wrapperSize.height),
            height: Math.min(wrapperSize.width, wrapperSize.height),
          }}
        >
          <LoadingTile image={image} className="w-full h-full" />
        </div>
      )}
    </SizeObservableWrapper>
  );
};
