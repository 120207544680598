import classNames from "classnames";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BaseButton } from "../../../components/Button/BaseButton.tsx";
import { BaseDialog } from "../../../components/Dialog/BaseDialog.tsx";
import { BaseClickableIcon } from "../../../components/Icon/BaseClickableIcon.tsx";
import { Icon } from "../../../components/Icon/Icon.tsx";
import { useAppMutation } from "../../../http/useAppMutation.ts";
import { BOARDS, EDIT_WORKSPACE } from "../../../routes.ts";
import { addSearchParamsToPath } from "../../../utils/url.ts";
import { HeaderGenerationInformation } from "../components/HeaderGenerationInformation.tsx";
import { FAVORITE_TAG_NAME } from "../constants.ts";
import { useBoard } from "../hooks/useBoard.ts";
import { useDeleteAssetOnBoard } from "../hooks/useDeleteAssetOnBoard.ts";
import { useSelectedAsset } from "../hooks/useSelectedAsset.ts";
import { ImageDownloadButton } from "../ImageDownload/ImageDownloadButton.tsx";
import type { Asset } from "../types.ts";
import { InformationDetails } from "../Workspaces/components/InformationDetails/InformationDetails.tsx";
import { ImageContainer } from "../Workspaces/EditWorkspace/components/ImageContainer/ImageContainer.tsx";
import { assetDetailDialogStore } from "./hooks/assetDetailDialogStore.ts";
import { ImageStripe } from "./ImageStripe.tsx";

export type DialogAssetDetailsType = "info";

export const AssetDetailDialog = () => {
  const { isAssetDetailDialogOpen } = assetDetailDialogStore.useState();

  return (
    <BaseDialog
      isOpen={isAssetDetailDialogOpen}
      onOpenChange={(open) =>
        assetDetailDialogStore.setIsAssetDetailDialogOpen(open)
      }
      displayCloseButton={false}
      className="shadow-200 rounded-300 bg-inverse overflow-hidden"
      overlayClassName="!bg-surface-transparent-primary-rest"
      content={isAssetDetailDialogOpen && <AssetDetailDialogContent />}
    />
  );
};

const AssetDetailDialogContent = () => {
  const { selectedAsset } = useSelectedAsset();
  const [currentAssetMenuType, setCurrentAssetMenuType] = useState(
    undefined as DialogAssetDetailsType | undefined,
  );

  return (
    <div className="w-[calc(100vw-24px)] h-[calc(100vh-24px)] flex-row">
      <div className="flex-col flex-fill px-400 ">
        <div className="relative h-1200 flex-row-center">
          <BaseButton
            onClick={() =>
              assetDetailDialogStore.setIsAssetDetailDialogOpen(false)
            }
            className="absolute left-0 h-800 flex-row-center px-300 gap-050 hover:bg-surface-inverse-hover active:bg-surface-inverse-active rounded-border-radius-button"
          >
            <Icon name="ChevronLeft" className="stroke-button-primary-active" />
            <span className="label-md-semibold text-button-primary-active">
              Back to grid
            </span>
          </BaseButton>
          {selectedAsset && (
            <div className="h-full w-[416px] flex-col-center">
              <HeaderGenerationInformation asset={selectedAsset} />
            </div>
          )}
          {selectedAsset && (
            <div className="absolute right-0">
              <HeaderActions
                asset={selectedAsset}
                isInfoSelected={currentAssetMenuType === "info"}
                onInfoButtonClick={() => {
                  setCurrentAssetMenuType(
                    currentAssetMenuType === "info" ? undefined : "info",
                  );
                }}
              />
            </div>
          )}
        </div>
        <div className="flex-fill flex-col-center">
          <ImageContainer />
        </div>
        <div className="flex-row-center pt-400 pb-200">
          <div className="w-[504px]">
            <ImageStripe />
          </div>
        </div>
      </div>
      {currentAssetMenuType === "info" && (
        <InformationDetails
          variant="dark"
          onCloseButtonClick={() => setCurrentAssetMenuType(undefined)}
        />
      )}
    </div>
  );
};

const HeaderActions = ({
  asset,
  isInfoSelected,
  onInfoButtonClick,
}: {
  asset: Asset;
  isInfoSelected: boolean;
  onInfoButtonClick: {
    (): void;
  };
}) => {
  const { board } = useBoard();
  const navigate = useNavigate();

  const favoriteTag = useMemo(
    () => board.tags.find((it) => it.name === FAVORITE_TAG_NAME),
    [board.tags],
  );
  const addTag = useAppMutation({
    path: "tags/add-assets",
    invalidate: [`assets/${asset.uuid}`, `boards/${board.uuid}`],
  }).mutation;
  const removeTag = useAppMutation({
    path: "tags/remove-assets",
    invalidate: [`assets/${asset.uuid}`, `boards/${board.uuid}`],
  }).mutation;

  const addOrRemoveFavoriteTag = () => {
    if (!favoriteTag) return;
    if (asset.is_in_favorite) {
      removeTag.mutate({
        asset_uuids: [asset.uuid],
        tag_uuid: favoriteTag.uuid,
      });
    } else {
      addTag.mutate({
        asset_uuids: [asset.uuid],
        tag_uuid: favoriteTag.uuid,
      });
    }
  };

  const { mutate: deleteAssetMutation, isLoading: deleteAssetIsLoading } =
    useDeleteAssetOnBoard();

  return (
    <div className="h-full flex-row-center gap-200">
      {favoriteTag && (
        <BaseClickableIcon
          name="Heart"
          iconClassName={
            asset.is_in_favorite
              ? "fill-pimento-red stroke-pimento-red"
              : "stroke-inverse-rest"
          }
          tooltip={{
            side: "bottom",
            content: asset.is_in_favorite
              ? "Remove from favorites"
              : "Add to favorites",
          }}
          onClick={() => {
            addOrRemoveFavoriteTag();
          }}
          className="h-800 w-800 rounded-100 hover:bg-surface-inverse-hover active:bg-surface-inverse-active"
        />
      )}
      <div className="h-500 border-inverse border-l" />

      <BaseClickableIcon
        name="Trash"
        tooltip={{ side: "bottom", content: "Delete" }}
        disabled={deleteAssetIsLoading || asset.deleted_at !== null}
        onClick={() => {
          deleteAssetMutation({
            uuids: [asset.uuid],
          });
        }}
        iconClassName="stroke-inverse-rest"
        className="h-800 w-800 rounded-100 hover:bg-surface-inverse-hover active:bg-surface-inverse-active"
      />
      <ImageDownloadButton asset={asset} variant="icon-inverse" />
      <BaseClickableIcon
        name="Info"
        tooltip={{ side: "bottom", content: "Display asset information" }}
        onClick={onInfoButtonClick}
        iconClassName="stroke-xl stroke-inverse-rest"
        className={classNames(
          "h-800 w-800 rounded-100",
          isInfoSelected
            ? "bg-surface-inverse-active"
            : "hover:bg-surface-inverse-hover",
        )}
      />
      <BaseButton
        className="z-10 h-800 px-300 bg-surface-primary-rest hover:bg-surface-primary-hover active:bg-surface-primary-active flex-row-center gap-050 rounded-border-radius-button label-md-semibold"
        onClick={() => {
          assetDetailDialogStore.setIsAssetDetailDialogOpen(false);
          navigate(
            addSearchParamsToPath(`${BOARDS}/${board.uuid}/${EDIT_WORKSPACE}`, {
              assetUuid: asset.uuid,
            }),
          );
        }}
      >
        <Icon name="PencilLine" size="sm" />
        Edit image
      </BaseButton>
    </div>
  );
};
