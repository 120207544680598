export const Assets = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    className={className}
  >
    <g
      strokeLinecap="round"
      strokeLinejoin="round"
      clipPath="url(#clip0_9605_589528)"
    >
      <path d="M16 18.667H6.666a1.333 1.333 0 01-1.333-1.334V8" />
      <path d="M18.666 12.666l-.864-.864a1.607 1.607 0 00-2.272 0L11.333 16M12 10.667A1.333 1.333 0 1012 8a1.333 1.333 0 000 2.667z" />
      <path d="M17.333 5.332h-8C8.597 5.332 8 5.929 8 6.665v8C8 15.402 8.597 16 9.333 16h8c.737 0 1.334-.597 1.334-1.334v-8c0-.736-.597-1.333-1.334-1.333z" />
    </g>
    <defs>
      <clipPath id="clip0_9605_589528">
        <path fill="#fff" d="M0 0H16V16H0z" transform="translate(4 4)" />
      </clipPath>
    </defs>
  </svg>
);
