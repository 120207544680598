import classNames from "classnames";
import { useState } from "react";
import { Button } from "../../../components/Button/Button.tsx";
import { useAppMutation } from "../../../http/useAppMutation.ts";
import type { Style } from "../../types.ts";
import { ModelCreationBackButton } from "../components/ModelCreationBackButton.tsx";
import { ModelCreationCloseButton } from "../components/ModelCreationCloseButton.tsx";
import { StylePreview } from "../components/StylePreview.tsx";

export const ObjectAndCharacterDescriptionForm = ({
  style,
  styleType,
  onClickNext,
  onClickPrevious,
  widthClassName,
}: {
  style: Style;
  styleType: "object" | "character";
  onClickNext: () => void;
  onClickPrevious: () => void;
  widthClassName?: string;
}) => {
  const [subjectDescription, setSubjectDescription] = useState<string>("");

  const updateStyleOnClickPrevious = useAppMutation({
    path: "styles/update",
    invalidate: [`styles/${style.uuid}`],
    onSuccess: () => onClickPrevious(),
  }).mutation;

  const updateStyleOnClickNext = useAppMutation({
    path: "styles/update",
    invalidate: [`styles/${style.uuid}`],
    onSuccess: () => onClickNext(),
  }).mutation;

  return (
    <div className="relative flex-col-center w-full flex-fill overflow-y-auto text-primary">
      <ModelCreationBackButton
        onClick={() =>
          updateStyleOnClickPrevious.mutate({
            uuid: style.uuid,
            subject_description: null,
          })
        }
        className="absolute top-400 left-400"
      />
      <ModelCreationCloseButton className="absolute top-400 right-400" />
      <div
        className={classNames(
          "flex-col flex-fill pt-2000 gap-1000 items-center",
          widthClassName,
        )}
      >
        <StylePreview style={style} title={`Describe your ${styleType}`} />
        <div className="w-full flex-col gap-400">
          <div className="body-lg-semibold">
            Be precise and use less than 5 words
          </div>
          <input
            type="text"
            className="w-full h-1000 py-200 px-400 rounded-100 border body-lg-default text-primary placeholder:text-disabled"
            placeholder={
              style.type === "object"
                ? "Minimalist black rectangular bag"
                : "Young blonde woman"
            }
            value={subjectDescription}
            onChange={(event) => {
              setSubjectDescription(event.target.value);
            }}
          />
          <div className="body-lg-default text-disabled">
            <div>Examples:</div>
            <ul className="pl-400 list-disc">
              {(style.type === "character"
                ? CHARACTER_EXAMPLES
                : OBJECT_EXAMPLES
              ).map((example) => (
                <li key={example} className="text-inherit">
                  {example}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <Button
          className="w-full"
          size="md"
          disabled={subjectDescription.isEmpty()}
          onClick={() => {
            updateStyleOnClickNext.mutate({
              uuid: style.uuid,
              subject_description: subjectDescription,
            });
          }}
        >
          {subjectDescription.isEmpty()
            ? `Describe your ${styleType}`
            : "Create my model"}
        </Button>
      </div>
    </div>
  );
};

const OBJECT_EXAMPLES = [
  "minimalist black rectangular bag",
  "cushion-cut canary yellow ring diamond",
  "sleek black stylish tech-enhanced sunglasses",
] as const;

const CHARACTER_EXAMPLES = [
  "young and tall blond woman",
  "cute pink panther stuffed toy",
  "55 year-old black man",
] as const;
