import removeBackground from "@imgly/background-removal";
import { fabric } from "fabric";

export const deleteSelectedObjects = (fabricCanvas: fabric.Canvas) => {
  const activeObjects = fabricCanvas.getActiveObjects();
  if (activeObjects.length > 0) {
    fabricCanvas.remove(...activeObjects);
    // Discard the selection bounding box
    fabricCanvas.discardActiveObject();
  }
};

export const clearObjects = (fabricCanvas: fabric.Canvas) => {
  const allObjects = fabricCanvas.getObjects();
  if (allObjects.length > 0) fabricCanvas.remove(...allObjects);
};

export const clearHistory = (fabricCanvas: fabric.Canvas) => {
  // @ts-expect-error history.js is not typed
  fabricCanvas.clearHistory();
};

export const isEmpty = (fabricCanvas: fabric.Canvas) =>
  fabricCanvas.getObjects().length === 0;

export const setBackgroundImage = ({
  fabricCanvas,
  url,
  maxWidth,
  maxHeight,
  cancelRef,
}: {
  fabricCanvas: fabric.Canvas;
  url: string;
  maxWidth: number;
  maxHeight: number;
  cancelRef: { canceled: boolean };
}) => {
  fabric.Image.fromURL(url, (img) => {
    if (cancelRef.canceled) return;
    const scale =
      img.width && img.height && fabricCanvas.width && fabricCanvas.height
        ? Math.min(
            Math.min(maxWidth, img.width) / img.width,
            Math.min(maxHeight, img.height) / img.height,
          )
        : 1;

    if (img.width && img.height) {
      fabricCanvas.setWidth(img.width * scale);
      fabricCanvas.setHeight(img.height * scale);
    }

    fabricCanvas.setBackgroundImage(
      img,
      fabricCanvas.renderAll.bind(fabricCanvas),
      {
        scaleX: scale,
        scaleY: scale,
      },
    );
  });
};

export const removeImageBackground = async ({
  image,
  callback,
}: {
  image: fabric.Image;
  callback: () => void;
}) => {
  const imageURL = image.toDataURL({
    format: "png",
    quality: 1,
  });
  image.set({
    opacity: 0.7,
    stroke: "#029FF5",
    strokeWidth: 4,
    strokeDashArray: [20, 10],
  });
  image.canvas?.requestRenderAll();
  const resultURL = URL.createObjectURL(await removeBackground(imageURL, {}));
  const originalWidth = image.getScaledWidth();
  const originalHeight = image.getScaledHeight();

  image.setSrc(resultURL, () => {
    image.scaleToWidth(originalWidth);
    image.scaleToHeight(originalHeight);
    image.set({ opacity: 1, strokeWidth: 0 });
    image.canvas?.requestRenderAll();
    callback();
  });
};

export const updateActiveObjectsColor = ({
  fabricCanvas,
  color,
}: {
  fabricCanvas: fabric.Canvas;
  color: string;
}) => {
  const activeObjects = fabricCanvas.getActiveObjects();
  for (const activeObject of activeObjects) {
    if (activeObject.type !== "image") {
      if (activeObject.fill) activeObject.set("fill", color);
      if (activeObject.stroke) activeObject.set("stroke", color);
    }
    fabricCanvas.requestRenderAll();
  }
};
