import { useParams } from "react-router";
import { Query } from "../../components/Query/Query.tsx";
import type { Style } from "../types.ts";
import { StyleTypeForm } from "./CreationPage/StyleTypeForm.tsx";
import { ObjectAndCharacterModelCreation } from "./ObjectAndCharacterModelCreation.tsx";
import { StyleCreation } from "./StyleCreation.tsx";

export const ModelCreationView = () => {
  const styleUuid = useParams()["styleUuid"]!;

  return (
    <Query queryKey={`styles/${styleUuid}`}>
      {(style) => <ModelCreation style={style} />}
    </Query>
  );
};

const ModelCreation = ({ style }: { style: Style }) => (
  <div className="w-full h-full">
    {(() => {
      switch (style.type) {
        case null:
          return <StyleTypeForm style={style} />;
        case "style":
          return <StyleCreation style={style} />;
        case "character":
          return (
            <ObjectAndCharacterModelCreation
              style={style}
              styleType={style.type}
            />
          );
        case "object":
          return (
            <ObjectAndCharacterModelCreation
              style={style}
              styleType={style.type}
            />
          );
      }
    })()}
  </div>
);
