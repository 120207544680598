import classNames from "classnames";
import { useEffect, useState } from "react";
import { Accordion } from "../../../components/Accordion/Accordion.tsx";
import { PimentoStar } from "../../../components/Icon/assets/PimentoStar.tsx";
import { CDNImage } from "../../../components/Image/CDNImage.tsx";
import { Image } from "../../../components/Image/Image.tsx";
import { useUser } from "../../../hooks/useUser.ts";
import { useAppMutation } from "../../../http/useAppMutation.ts";
import { useAppQuery } from "../../../http/useAppQuery.ts";
import { Info } from "../../Board/components/Info.tsx";
import { GENERIC_STYLE_UUID, STYLE_IMAGES_KIND } from "../../constants.ts";
import type { Style } from "../../types.ts";
import { StyleTypeDisplay } from "./constants.ts";

export const StyleDescription = ({ styleUuid }: { styleUuid: string }) => {
  const { mutate: updateStyle } = useAppMutation({
    path: "styles/update",
    invalidate: [`styles/${styleUuid}`],
  }).mutation;

  const { data: style } = useAppQuery<Style>({
    queryKey: `styles/${styleUuid}`,
  });

  const { user } = useUser();

  // XXX: User can update style only if the style is its own or if the user has the permission to update curated styles
  const isStyleUpdateDisabled =
    style?.is_curated &&
    !user?.permissions.includes("styles:update-curated-styles");

  const [additionalElements, setAdditionalElements] = useState<string>(
    style?.additional_elements ?? "",
  );
  const [excludeElements, setExcludeElements] = useState<string>(
    style?.exclude_elements ?? "",
  );

  useEffect(() => {
    setAdditionalElements(style?.additional_elements ?? "");
    setExcludeElements(style?.exclude_elements ?? "");
  }, [style]);

  return (
    style && (
      <div className="flex-col flex-fill h-full pt-400 gap-400 text-primary">
        <div className="px-400 flex-row h-1600 items-center justify-between">
          <div className="flex-row items-center gap-400 w-[80%]">
            {style.thumbnail_url ? (
              <CDNImage
                src={style.thumbnail_url}
                className="w-1600 h-1600 aspect-square"
                imageClassName="h-full w-full object-cover object-center"
                srcDimension="small128"
              />
            ) : (
              <div className="w-1600 h-1600 flex-row-center bg-pimento-blue">
                <PimentoStar size={32} className="fill-button-primary-rest" />
              </div>
            )}
            <span
              className={classNames(
                style.name?.isBlank() && "italic",
                "heading-2xl w-[90%] truncate",
              )}
            >
              {style.name ? style.name : "Untitled"}
            </span>
          </div>
          <span className="first-letter:uppercase label-lg-default py-100 px-200 rounded-200 bg-surface-primary-hover">
            {style.status === "created" ? "draft" : style.status}
          </span>
        </div>
        <div className="px-400 pb-400 flex-col flex-fill overflow-auto gap-400">
          {style.uuid === GENERIC_STYLE_UUID && (
            <div className="p-400 bg-input-surface-rest rounded-100 body-md-default">
              Generate any image you want in any style. Be more precise in your
              description than with other models, to get more precise results.
            </div>
          )}
          <Accordion
            accordionTriggerClassName="hover:!no-underline !py-0"
            accordionItemClassName="border-b-0 !p-0"
            iconSize="sm"
            elements={[
              {
                title: (
                  <div className="h-600 items-center body-lg-semibold">
                    Details
                  </div>
                ),
                content: (
                  <div className="flex-col">
                    {style.type === "style" && (
                      <div className="flex-row h-1200 w-full justify-between">
                        <div className="flex-row items-center gap-100">
                          Type
                          <Info content="Your choice will influence your generations. “Other” will not influence your prompt." />
                        </div>
                        <select
                          className="p-100"
                          value={style.images_kind ?? undefined}
                          onChange={(type) => {
                            updateStyle({
                              uuid: styleUuid,
                              images_kind: type.target.value,
                            });
                          }}
                          disabled={isStyleUpdateDisabled}
                        >
                          {STYLE_IMAGES_KIND.map((type, index) => (
                            <option
                              key={index}
                              value={type}
                              className="text-end"
                            >
                              {StyleTypeDisplay[type]}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    <div className="flex-col">
                      <div className="h-1200 flex-row pt-200 items-center gap-100">
                        Style additional elements
                        <Info content="These elements will be automatically added to all your prompts." />
                      </div>
                      <textarea
                        className="w-full resize-none h-[56px] rounded-100 p-200 border placeholder:text-disabled focus:border-primary-rest hover:border-primary-rest"
                        placeholder="motion blur, waterpainting..."
                        value={additionalElements}
                        onChange={(event) => {
                          setAdditionalElements(event.target.value);
                        }}
                        onKeyDown={(e) => {
                          e.stopPropagation();
                        }}
                        onBlur={() =>
                          updateStyle({
                            uuid: style.uuid,
                            additional_elements: additionalElements,
                          })
                        }
                        disabled={isStyleUpdateDisabled}
                      />
                    </div>
                    <div className="flex-col">
                      <div className="h-1200 flex-row pt-200 items-center gap-100">
                        Exclude
                        <Info content="These elements will be automatically added to your exclude section." />
                      </div>
                      <textarea
                        className="w-full resize-none h-[56px] rounded-100 p-200 border placeholder:text-placeholder focus:border-primary-rest hover:border-primary-rest"
                        placeholder="very detailed, low angle, close up, indoor light, dramatic..."
                        value={excludeElements}
                        onChange={(event) => {
                          setExcludeElements(event.target.value);
                        }}
                        onKeyDown={(e) => {
                          e.stopPropagation();
                        }}
                        onBlur={() =>
                          updateStyle({
                            uuid: style.uuid,
                            exclude_elements: excludeElements,
                          })
                        }
                        disabled={isStyleUpdateDisabled}
                      />
                    </div>
                  </div>
                ),
                value: "Details",
              },
            ]}
          />
          <div className="flex-col gap-200">
            <span className="h-400 label-lg-semibold">Initial images</span>
            <div className="grid grid-cols-4 gap-100">
              {style.uuid === GENERIC_STYLE_UUID
                ? GENERIC_FAKE_IMAGES.map((url) => (
                    <div key={url} className="h-full w-full aspect-square">
                      <Image
                        className="h-full w-full aspect-square"
                        imageClassName="h-full w-full object-cover object-center rounded-050"
                        src={url}
                      />
                    </div>
                  ))
                : style.training_images.map((image) => (
                    <div
                      key={image.uuid}
                      className="h-full w-full aspect-square"
                    >
                      <CDNImage
                        className="h-full w-full aspect-square"
                        imageClassName="h-full w-full object-cover object-center rounded-050"
                        src={image.url}
                        srcDimension="medium256"
                      />
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

const GENERIC_FAKE_IMAGES = [
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_1.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_2.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_3.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_4.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_5.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_6.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_7.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_8.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_9.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_10.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_11.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_12.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_13.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_14.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_15.jpg",
] as const;
