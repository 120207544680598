import { useDropzone } from "react-dropzone";
import { loadImage } from "../utils/image.ts";
import { notifier } from "../utils/notifier.ts";
import { pseudoRandomUUID } from "../utils/stackoverflow.ts";

export const useImageDropZone = ({
  multiple,
  minImageSize,
  onImageDrop,
}: {
  multiple: boolean;
  minImageSize: number;
  onImageDrop: (file: { fileToUpload: File; uuid: string } | undefined) => void;
}) =>
  useDropzone({
    multiple,
    noClick: true,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".jpg"],
      "image/webp": [".webp"],
    },
    onDrop: (acceptedFiles) => {
      acceptedFiles.map(
        (acceptedFile) =>
          void loadImage(URL.createObjectURL(acceptedFile)).then((image) => {
            if (image.width < minImageSize || image.height < minImageSize) {
              notifier.error(
                `Your image dimensions should be greater than ${minImageSize}x${minImageSize} pixels`,
              );
            } else {
              onImageDrop({
                uuid: pseudoRandomUUID(),
                fileToUpload: acceptedFile,
              });
            }
          }),
      );
    },
  });
