export const OnboardingCenteredTip = ({
  className,
}: {
  className?: string;
}) => (
  <svg
    width="51"
    height="48"
    viewBox="0 0 51 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="icon">
      <rect
        id="Rectangle 40023"
        x="1.17188"
        y="0.75"
        width="38.5"
        height="38.5"
        rx="4.25"
      />
      <path
        id="Union"
        d="M20.7857 9.93727L20.5304 9.78568L20.2751 9.93727L17.8821 11.3583L17.6373 11.5036V11.7882V13.933V14.0229L17.6687 14.1073L18.2116 15.5671L14.7579 13.5164L14.5026 13.3648L14.2474 13.5164L11.8541 14.9372L11.6094 15.0826L11.6094 15.3672L11.6095 18.2091L11.6095 18.4937L11.8542 18.639L13.6604 19.7114L13.7415 19.7595L13.8346 19.7748L15.345 20.0227L11.8542 22.0954L11.6095 22.2407L11.6095 22.5253L11.6094 25.3672L11.6094 25.6518L11.8541 25.7971L14.2473 27.218L14.5026 27.3695L14.7578 27.218L16.564 26.1456L16.6437 26.0982L16.7012 26.0254L17.6373 24.8394V28.9462V29.2308L17.882 29.3761L20.2751 30.7971L20.5304 30.9487L20.7857 30.7971L23.1788 29.3761L23.4235 29.2308V28.9462V26.8014V26.7114L23.3921 26.6271L22.8492 25.1673L26.303 27.218L26.5583 27.3695L26.8135 27.218L29.2067 25.7971L29.4515 25.6518L29.4515 25.3672L29.4514 22.5253L29.4514 22.2407L29.2067 22.0954L27.4005 21.023L27.3194 20.9748L27.2263 20.9595L25.7159 20.7116L29.2066 18.639L29.4513 18.4937L29.4514 18.2091L29.4514 15.3672L29.4514 15.0826L29.2067 14.9373L26.8135 13.5164L26.5582 13.3648L26.303 13.5164L24.4969 14.5888L24.4172 14.6361L24.3597 14.7089L23.4235 15.895V11.7882V11.5036L23.1788 11.3583L20.7857 9.93727Z"
      />
      <g id="Group 1400003153">
        <circle
          id="Ellipse 1182"
          cx="40.1094"
          cy="37.3672"
          r="10"
          fill="#F8F8F8"
        />
        <path
          id="Vector"
          d="M44.723 34.2891L38.3768 41.2121L35.4922 38.0653"
          stroke="#0890FF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);
