export const ImageSlider = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="18"
    fill="none"
    viewBox="0 0 16 18"
    className={className}
  >
    <g>
      <g>
        <mask id="path-1-inside-1_14130_131378" fill="#fff">
          <path
            d="M0 3a2 2 0 012-2h4.563v16H2a2 2 0 01-2-2V3z"
            stroke="transparent"
          />
        </mask>
        <path
          stroke="transparent"
          d="M-1 3a3 3 0 013-3h4.563v2H2a1 1 0 00-1 1h-2zm7.563 15H2a3 3 0 01-3-3h2a1 1 0 001 1h4.563v2zM2 18a3 3 0 01-3-3V3a3 3 0 013-3v2a1 1 0 00-1 1v12a1 1 0 001 1v2zM6.563 1v16V1z"
          mask="url(#path-1-inside-1_14130_131378)"
        />
      </g>
      <g>
        <mask id="path-3-inside-2_14130_131378" fill="#fff">
          <path
            d="M16 14.934a2 2 0 01-2 2H7v-16h7a2 2 0 012 2v12z"
            stroke="transparent"
          />
        </mask>
        <path
          stroke="transparent"
          fillRule="evenodd"
          d="M8.313 16.434v1.5H7v-2h1.313v.5zm.874-.5v2h2.626v-2H9.187zm3.5 0v2H14c.615 0 1.19-.187 1.668-.506l-.279-.416-.556-.83-.278-.416a.99.99 0 01-.555.168h-1.313zm2.313-1a.99.99 0 01-.168.555l.416.278.83.556.416.278A2.99 2.99 0 0017 14.934v-1.5h-2v1.5zm0-2.5h2v-3h-2v3zm0-4h2v-3h-2v3zm0-4h2v-1.5c0-.616-.186-1.19-.506-1.668l-.415.278-.831.557-.416.278a.99.99 0 01.168.555v1.5zm-1-2.5a.99.99 0 01.555.167l.278-.415.556-.831.279-.416A2.99 2.99 0 0014-.066h-1.313v2H14zm-2.188 0v-2H9.188v2h2.624zm-3.5 0v-2H7v2h1.313z"
          clipRule="evenodd"
          mask="url(#path-3-inside-2_14130_131378)"
        />
      </g>
      <path fill="none" d="M6.625 0L6.625 18" />
    </g>
  </svg>
);
