import type { fabric } from "fabric";
import { convertAlphaToMaskAndDilate } from "../BaseEditor/canvasUtils.ts";

export const setBrushFreeDrawingBrush = ({
  fabricCanvas,
  color,
  size,
}: {
  fabricCanvas: fabric.Canvas;
  color: string;
  size: number;
}) => {
  fabricCanvas.freeDrawingBrush.color = color;
  fabricCanvas.freeDrawingBrush.width = size;
  fabricCanvas.freeDrawingBrush.strokeDashArray = [];
  setBrushCursor({ fabricCanvas, brushColor: color, brushSize: size });
};

export const updateExistingBrushColors = ({
  fabricCanvas,
  color,
}: {
  fabricCanvas: fabric.Canvas;
  color: string;
}) => {
  const fabricObjects = fabricCanvas.getObjects();
  for (const fabricObject of fabricObjects) {
    if (fabricObject.type === "path" && fabricObject.stroke) {
      fabricObject.set("stroke", color);
    }
    fabricCanvas.requestRenderAll();
  }
};

export const generateBrushMask = ({
  fabricCanvas,
}: {
  fabricCanvas: fabric.Canvas;
}) => {
  const backgroundImage = fabricCanvas.backgroundImage;
  fabricCanvas.backgroundImage = undefined;
  fabricCanvas.renderAll();
  const objectsCanvas = fabricCanvas.toCanvasElement();
  if (backgroundImage) {
    fabricCanvas.setBackgroundImage(
      backgroundImage,
      fabricCanvas.renderAll.bind(fabricCanvas),
    );
  }
  fabricCanvas.requestRenderAll();

  return convertAlphaToMaskAndDilate({
    canvas: objectsCanvas,
    dilationWidth: 0,
  });
};

const setBrushCursor = ({
  fabricCanvas,
  brushSize,
  brushColor,
}: {
  fabricCanvas: fabric.Canvas;
  brushSize: number;
  brushColor: string;
}) => {
  const radius = brushSize / 2;
  const brushHead = getBrushHead({ brushSize, brushColor, radius });

  fabricCanvas.freeDrawingCursor = `url('data:image/svg+xml;base64,${btoa(
    brushHead,
  )}') ${radius + 4} ${radius + 4}, crosshair`;
};

const getBrushHead = ({
  brushSize,
  brushColor,
  radius,
}: {
  brushSize: number;
  brushColor: string;
  radius: number;
}) => `
    <svg
      width="${brushSize + 4}px"
      height="${brushSize + 4}px"
      viewBox="0 0 ${brushSize + 4} ${brushSize + 4}"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="50%"
        cy="50%"
        r="${radius}"
        stroke="black"
        stroke-width="2"
        fill="none"
      />
      <circle
        cx="50%"
        cy="50%"
        r="${radius - 2}"
        stroke="${brushColor}"
        stroke-width="2"
        fill="none"
      />
    </svg>
  `;
