import Resizer from "react-image-file-resizer";
import { useAppMutation } from "../../../http/useAppMutation.ts";

export const useUploadImage = ({
  onSuccess,
  describeImage = false,
  maxSize = 10000,
}: {
  onSuccess?: (imageUuid: string) => void;
  describeImage?: boolean;
  maxSize?: number;
}) => {
  const {
    mutation: { mutate: upload, isLoading },
    progress,
  } = useAppMutation({
    path: "contents/upload-png",
    invalidate: [],
    onSuccess: (response: { data: { image_uuid: string } }) =>
      onSuccess?.(response.data.image_uuid),
    config: {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    },
  });

  return {
    mutate: ({ image }: { image: Blob }) => {
      const formData = new FormData();
      if (describeImage) formData.append("describe", "true");
      // XXX: We force the uploaded image to be PNG
      Resizer.imageFileResizer(
        image,
        maxSize,
        maxSize,
        "PNG",
        100,
        0,
        (compressedFile) => {
          formData.append("file", compressedFile as Blob);
          upload(formData);
        },
        "blob",
      );
    },
    isLoading,
    progress,
  };
};
