import { type ImgHTMLAttributes, Suspense, useEffect, useState } from "react";

export type LoadImageData = () => Promise<{ default: string }>;

// FIXME: This component may not be completely necessary. According to vite documentation
//  https://vitejs.dev/guide/assets.html#the-public-directory it may be possible to generate direct urls via import
//  command but it was never tested.
//  Other solution should be to use public url (via public bucket, ...) and remove the different assets from the bundle.
export const EmbeddedImage = ({
  loadData,
  ...props
}: Omit<ImgHTMLAttributes<HTMLImageElement>, "src" | "ref"> & {
  loadData: LoadImageData;
}) => {
  const [imageData, setImageData] = useState<string>();

  useEffect(() => {
    void loadData().then((data) => {
      setImageData(data.default);
    });
  }, [loadData]);

  return (
    <Suspense fallback={null}>
      <img src={imageData} alt="" {...props} />
    </Suspense>
  );
};
