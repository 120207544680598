export const Pimento = ({
  className,
  size,
}: {
  className?: string;
  size: number;
}) => (
  <svg
    width={size}
    height={(size * 673) / 2808}
    viewBox="0 0 2808 673"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2695 0L2662.89 18.4731V46.3553L2680.92 93.334L2662.59 74.4111L2614.11 46.5289L2582 65L2582 101.944L2606.24 115.885L2656.09 123.813L2630.47 130.174L2582 158.056L2582 195L2614.11 213.471L2638.35 199.53L2670.17 160.479L2662.89 185.763V241.527L2695 260L2727.11 241.527V213.645L2709.08 166.665L2727.41 185.589L2775.89 213.471L2808 195L2808 158.056L2783.76 144.115L2733.91 136.187L2759.53 129.826L2808 101.944L2808 65L2775.89 46.529L2751.65 60.47L2719.83 99.5211L2727.11 74.2374V18.4731L2695 0Z" />
    <path d="M484.14 1.57617C451.384 1.57617 424.989 28.391 424.989 61.0946C424.989 93.7982 451.331 121.244 484.14 121.244C516.948 121.244 542.607 94.4291 542.607 61.0946C542.607 27.7601 516.265 1.57617 484.14 1.57617Z" />
    <path d="M981.317 130.868C932.472 130.868 893.301 151.058 871.691 189.808C850.765 152.477 815.854 130.973 769.848 130.973C738.459 130.973 697.973 141.015 673.577 189.124V145.327H570.997L570.524 531.303H673.104L673.577 313.314C673.577 261.629 695.187 227.874 735.672 227.874C769.164 227.874 789.407 251.587 789.407 296.751L788.934 531.25H891.513L891.987 313.261C891.987 257.265 911.546 227.822 951.978 227.822C988.257 227.822 1007.82 251.534 1007.82 296.699L1007.34 531.197H1109.92L1110.4 280.926C1110.4 184.024 1063.65 130.868 981.317 130.868Z" />
    <path d="M432.034 531.303H534.561L535.087 145.327H432.507L432.034 531.303Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1329.31 130.973C1220.48 130.986 1140.26 214.213 1140.26 331.979C1140.26 449.754 1218.39 529.41 1337.69 529.41C1406.78 529.41 1468.88 497.074 1505.84 446.126L1424.03 397.123C1406.73 425.936 1382.81 443.234 1341.16 443.234C1294.42 443.234 1255.36 420.994 1248.36 363.578H1509.99C1512.09 347.752 1512.78 337.026 1512.78 324.092C1512.78 209.954 1440.23 130.986 1329.31 130.973ZM1249.1 291.757C1257.46 241.492 1291.63 217.096 1330.75 217.096C1375.39 217.096 1401.94 247.959 1403.31 291.757H1249.1Z"
    />
    <path d="M1759.16 130.973C1713.49 130.973 1672.96 150.464 1649.59 190.544V145.327H1547.01L1546.53 531.303H1649.11L1649.59 322.62C1649.59 264.469 1675.4 228.61 1724.93 228.61C1770.31 228.61 1788.45 254.426 1788.45 319.781L1787.97 531.303H1890.55L1891.03 304.691C1891.03 190.544 1845.65 130.973 1759.16 130.973Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M203.793 130.604C160.153 130.604 117.144 143.38 81.1281 169.091L0 119.615V673H104.578V505.433C133.496 522.101 167.619 531.722 203.793 531.722C318.255 531.722 398.542 446.441 398.542 330.821C398.542 215.202 318.255 130.604 203.793 130.604ZM196.852 429.931C141.435 429.931 101.108 388.184 101.108 330.821C101.108 273.459 141.435 231.712 196.852 231.712C252.27 231.712 292.597 273.459 292.597 330.821C292.597 388.184 252.27 429.931 196.852 429.931Z"
    />
    <path d="M2033.51 17.5403V146.062H2133.36V237.128H2033.51V385.03C2033.51 404.326 2036.46 416.419 2043.19 423.727C2049.92 430.983 2060.69 433.875 2077.26 433.875C2095.12 433.875 2113.18 431.02 2130.57 427.092V521.575C2108.24 529.255 2086.24 531.355 2062.69 531.355C2019.21 531.355 1985.72 521.628 1963.11 500.123C1940.5 478.566 1928.99 445.495 1928.99 399.331V80.2345L2033.51 17.5403Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2146.66 331.137C2146.66 215.518 2233.99 130.236 2348.61 130.236C2463.23 130.236 2550.57 216.254 2550.57 331.137C2550.57 446.02 2463.23 531.302 2348.61 531.302C2233.99 531.302 2146.66 446.756 2146.66 331.137ZM2252.71 330.822C2252.71 388.184 2293.04 429.931 2348.46 429.931C2403.87 429.931 2444.2 388.184 2444.2 330.822C2444.2 273.459 2403.87 231.712 2348.46 231.712C2293.04 231.712 2252.71 273.459 2252.71 330.822Z"
    />
  </svg>
);
