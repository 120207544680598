import { BaseButton } from "../../../../components/Button/BaseButton.tsx";
import { Icon, type IconName } from "../../../../components/Icon/Icon.tsx";

export const ActionTileButton = ({
  title,
  description,
  iconName,
  onClick,
  isLoading = false,
}: {
  title: string;
  description?: string;
  iconName: IconName;
  onClick: () => void;
  isLoading?: boolean;
}) => (
  <BaseButton
    onClick={onClick}
    className="w-[270px] flex-row items-center gap-400"
    loading={isLoading}
  >
    <div className="flex-col-center bg-primary h-[50px] aspect-square rounded-200">
      <Icon name={iconName} />
    </div>
    <div className="flex-col">
      <span className="label-lg-semibold text-primary">{title}</span>
      {description && (
        <span className="label-md-default text-secondary">{description}</span>
      )}
    </div>
  </BaseButton>
);
