import * as SwitchPrimitives from "@radix-ui/react-switch";
import classNames from "classnames";
import React from "react";
import { wrapFormComponentHOC } from "../Form/wrapFormComponentHOC.tsx";

type SwitchProps = Omit<
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>,
  "checked" | "onCheckedChange" | "value" | "onChange"
> & {
  value: boolean;
  onChange: (value: boolean) => void;
  error?: string | undefined;
};

export const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  SwitchProps
>(({ className, value, onChange, error: _, ...props }, ref) => (
  <SwitchPrimitives.Root
    className={classNames(
      "peer inline-flex h-[14px] w-[26px] shrink-0 cursor-pointer items-center rounded-400 border-025 transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-surface-primary-active data-[state=unchecked]:bg-surface-primary-rest",
      className,
    )}
    checked={value}
    onCheckedChange={onChange}
    {...props}
    ref={ref}
  >
    <SwitchPrimitives.Thumb
      className={classNames(
        "pointer-events-none h-[10px] w-[10px] rounded-full shadow-300 transition-transform data-[state=checked]:bg-surface-primary-rest data-[state=unchecked]:bg-surface-primary-active data-[state=checked]:translate-x-[13px] data-[state=unchecked]:translate-x-[1px]",
      )}
    />
  </SwitchPrimitives.Root>
));
Switch.displayName = SwitchPrimitives.Root.displayName;

export const FormSwitch = wrapFormComponentHOC<boolean, SwitchProps>(Switch);
