import { useAuth0 } from "@auth0/auth0-react";
import classNames from "classnames";
import { useState } from "react";
import { Link } from "react-router-dom";
import { BaseButton } from "../../../components/Button/BaseButton.tsx";
import { Icon, type IconName } from "../../../components/Icon/Icon.tsx";
import { Popover } from "../../../components/Popover/Popover.tsx";
import { Spinner } from "../../../components/Spinner/Spinner.tsx";
import { useUser } from "../../../hooks/useUser.ts";
import { ADMIN } from "../../../routes.ts";
import { isUserAllowedTo } from "../../../types.ts";
import { useCreateCustomerPortalUrl } from "../HomeIndex/hooks/useCreateCustomerPortalUrl.ts";
import { ProfileSettingsDialog } from "./ProfileSettingsDialog.tsx";

export const UserMenu = () => {
  const { user } = useUser();
  const { logout } = useAuth0();
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isProfileSettingsDialogOpen, setIsProfileSettingsDialogOpen] =
    useState(false);
  const { createCustomerPortalUrl, isCreateCustomerPortalUrlLoading } =
    useCreateCustomerPortalUrl();
  // FIXME: keep the popover open when Profile settings dialog is open
  return (
    <>
      <Popover
        isOpen={isUserMenuOpen}
        onOpenChange={setIsUserMenuOpen}
        align="center"
        side="top"
        className="!p-200 w-[200px]"
        content={
          <div className="flex-col">
            {user && isUserAllowedTo(user, "styles:copy") && (
              <Link to={ADMIN}>
                <UserMenuItem iconName="Lock" title="Admin" />
              </Link>
            )}
            <ClickableUserMenuItem
              iconName="Settings"
              title="Profile settings"
              onClick={() => setIsProfileSettingsDialogOpen(true)}
            />
            {user && isUserAllowedTo(user, "subscription:view") && (
              <ClickableUserMenuItem
                iconName="CreditCard"
                title="Manage subscription"
                externalLink
                onClick={() => createCustomerPortalUrl({})}
                isLoading={isCreateCustomerPortalUrlLoading}
              />
            )}
            <a href="https://pimento.crisp.help/en/" target="_blank">
              <UserMenuItem
                iconName="LifeBuoy"
                title="Help center"
                externalLink
              />
            </a>
            <a href="https://gopimento.notion.site/" target="_blank">
              <UserMenuItem
                iconName="ScrollText"
                title="Terms and conditions"
                externalLink
              />
            </a>
            <ClickableUserMenuItem
              iconName="LogOut"
              title="Log out"
              onClick={() => logout({ returnTo: window.location.origin })}
            />
          </div>
        }
      >
        <BaseButton className="px-150 w-full h-800 flex-row items-center justify-between rounded-150 text-secondary label-md-default hover:bg-surface-primary-hover focus:text-primary focus:label-md-semibold focus:bg-surface-primary-active">
          <div className="flex-fill flex-row items-center gap-150">
            <div className="h-500 w-500 rounded-100 border bg-surface-caution-primary-rest label-md-semibold text-primary capitalize flex-row-center">
              {user?.first_name && user.last_name
                ? `${user.first_name[0]}${user.last_name[0]}`
                : ""}
            </div>
            <div className="flex-fill truncate">
              {user?.first_name && user.last_name
                ? `${user.first_name} ${user.last_name}`
                : user?.email}
            </div>
          </div>
          <Icon
            name="ChevronUp"
            size="sm"
            className={classNames(
              "transition-transform",
              isUserMenuOpen && "-rotate-180",
            )}
          />
        </BaseButton>
      </Popover>
      <ProfileSettingsDialog
        isOpen={isProfileSettingsDialogOpen}
        onOpenChange={(open) => setIsProfileSettingsDialogOpen(open)}
      />
    </>
  );
};
const UserMenuItem = ({
  title,
  iconName,
  externalLink = false,
  isLoading = false,
}: {
  title: string;
  iconName: IconName;
  externalLink?: boolean;
  isLoading?: boolean;
}) => (
  <div
    className={classNames(
      "px-200 py-100 rounded-150 flex-row items-center gap-200",
      isLoading
        ? "bg-surface-primary-hover"
        : "bg-surface-primary-rest hover:bg-surface-primary-hover",
    )}
  >
    <div className="flex-fill flex-row items-center gap-200">
      <div className="flex-row-center w-400">
        {isLoading ? <Spinner size="xs" /> : <Icon name={iconName} size="sm" />}
      </div>
      <div className="label-md-default">{title}</div>
    </div>
    {externalLink && <Icon name="ExternalLink" size="sm" />}
  </div>
);

const ClickableUserMenuItem = ({
  title,
  iconName,
  externalLink = false,
  onClick,
  isLoading = false,
}: {
  title: string;
  iconName: IconName;
  externalLink?: boolean;
  onClick: () => void;
  isLoading?: boolean;
}) => (
  <BaseButton
    onClick={onClick}
    loading={isLoading}
    loadingContent={
      <UserMenuItem
        title={title}
        iconName={iconName}
        externalLink={externalLink}
        isLoading
      />
    }
  >
    <UserMenuItem
      title={title}
      iconName={iconName}
      externalLink={externalLink}
    />
  </BaseButton>
);
