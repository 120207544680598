import classNames from "classnames";
import type { ReactNode } from "react";
import { ClickableIcon } from "../Icon/ClickableIcon.tsx";
import { BaseDialog } from "./BaseDialog.tsx";

export const Dialog = ({
  content,
  title,
  isOpen,
  onOpenChange,
  className,
}: {
  content: ReactNode;
  title?: string;
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  className?: string;
}) => (
  <BaseDialog
    isOpen={isOpen}
    onOpenChange={onOpenChange}
    clickOutsideAuthorized
    displayCloseButton={false}
    className={classNames(
      "rounded-300 bg-surface-primary-rest py-100",
      className,
    )}
    content={
      isOpen && (
        <div className="flex-col">
          <div className="px-400 py-300 flex-row items-center justify-between gap-200">
            <div className="label-lg-semibold">{title}</div>
            <ClickableIcon
              name="X"
              variant="tertiary"
              size="sm"
              onClick={() => onOpenChange(false)}
            />
          </div>
          {content}
        </div>
      )
    }
  />
);
