import { useRef } from "react";
import { useOn } from "./useOn.ts";

export const useClickOutside = <Element extends HTMLElement = HTMLDivElement>(
  onClickOutside: (event: MouseEvent) => void,
  targetElement?: Element | null,
) => {
  const ref = useRef<Element>(null);
  useOn("click", (event) => {
    const element = targetElement ?? ref.current;
    if (!element) return;
    if (
      event
        .composedPath()
        .some((target) => target instanceof Element && element.contains(target))
    ) {
      return;
    }
    onClickOutside(event);
  });
  return ref;
};
