import { useEffect, useRef } from "react";

export const useIsMounted = () => {
  const ref = useRef(true);

  useEffect(() => {
    ref.current = true; // Needed for fast refresh
    return () => {
      ref.current = false;
    };
  }, []);

  return ref;
};
