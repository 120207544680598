import axios from "axios";

export let userIp = null as string | null;

export const getUserIp = async () => {
  // XXX: User IP computed only once per session to avoid unnecessary requests that could slow down the app.
  // FIXME: Instead of getting the IP from the client, we could rely on our k8s load balancer to provide the real IP in
  //  the header via the `X-Forwarded-For` header. This will probably requires some changes in the uvicorn configuration
  //  to activate the `proxy-headers` option.
  //  https://stackoverflow.com/questions/60098005/fastapi-starlette-get-client-real-ip
  if (userIp) return userIp;
  // XXX: Inspired by blog https://medium.com/how-to-react/how-to-get-user-ip-address-in-react-js-73eb295720d0
  await axios
    .get("https://api.ipify.org/?format=json")
    .then((res) => res.data)
    .then((data) => {
      userIp = data.ip;
    })
    .catch(() => {
      userIp = null;
    });

  return userIp;
};
