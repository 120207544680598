import { createStore } from "../../../../utils/createStore.ts";

export const assetDetailDialogStore = createStore(
  {
    isAssetDetailDialogOpen: false,
  },
  ({ setter }) => ({
    setIsAssetDetailDialogOpen: setter("isAssetDetailDialogOpen"),
  }),
);
