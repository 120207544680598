import { useEffect, useRef, useState } from "react";
import { z } from "zod";
import { UrlAnimation } from "../../../../../../components/Animation/UrlAnimation.tsx";
import { Form } from "../../../../../../components/Form/Form.tsx";
import { useForm } from "../../../../../../components/Form/FormContext.ts";
import { useUser } from "../../../../../../hooks/useUser.ts";
import { useAppQuery } from "../../../../../../http/useAppQuery.ts";
import { isUserAllowedTo } from "../../../../../../types.ts";
import { addNullInput } from "../../../../../../utils/validations.ts";
import type { Style } from "../../../../../types.ts";
import { LearnMoreLink } from "../../../../components/LearnMoreLink.tsx";
import { PresetButton } from "../../../../components/PresetButton.tsx";
import { useBoard } from "../../../../hooks/useBoard.ts";
import { useSelectedAsset } from "../../../../hooks/useSelectedAsset.ts";
import { GenerationBar } from "../../../components/GenerationBar/GenerationBar.tsx";
import { CollapsableSettingsSectionWrapper } from "../../../components/SettingsMenu/CollapsableSettingsSectionWrapper.tsx";
import { QualityPresetSection } from "../../../components/SettingsMenu/QualityPresetSection.tsx";
import { SettingsSectionWrapper } from "../../../components/SettingsMenu/SettingsSectionWrapper.tsx";
import { SliderSection } from "../../../components/SettingsMenu/SliderSection.tsx";
import { StyleIntensitySection } from "../../../components/SettingsMenu/StyleIntensitySection.tsx";
import { SwitchSection } from "../../../components/SettingsMenu/SwitchSection.tsx";
import { TextSection } from "../../../components/SettingsMenu/TextSection.tsx";
import { SettingsMenuLayout } from "../../../components/SettingsMenuLayout.tsx";
import { useVariationGeneration } from "./hooks/useVariationGeneration.ts";
import { useVariationSettings } from "./hooks/useVariationSettings.ts";
import { VariationCreativityStrengthPresetSection } from "./VariationCreativityStrengthPresetSection.tsx";

export const VariationSettings = () => {
  const { mutation: variationMutate, isLoading: isGenerationLoading } =
    useVariationGeneration();

  return (
    <Form
      className="flex-col flex-fill"
      schema={zVariationSettings}
      initialValues={{
        selectedAssetUuid: "",
        prompt: "",
        selectedStyleUuid: null,
        isSelectedImageSucceeded: false,
      }}
      onSubmit={(values) => {
        // FIXME: make the mutation async
        variationMutate({
          prompt: values.prompt,
          styleUuid: values.selectedStyleUuid,
          assetUuid: values.selectedAssetUuid,
        });
        return Promise.resolve(true);
      }}
    >
      <VariationSettingsFormContent isGenerationLoading={isGenerationLoading} />
    </Form>
  );
};

const zVariationSettings = z.object({
  selectedAssetUuid: z
    .string({ required_error: "Please upload an image in the canvas" })
    .uuid(),
  selectedStyleUuid: addNullInput(z.string().uuid(), "Please select a style"),
  prompt: z.string().min(1, { message: "Please enter a description" }),
  isSelectedImageSucceeded: z.boolean().refine((val) => val, {
    message: "Please wait for your image to be generated",
  }),
});
type VariationSettingsValues = z.input<typeof zVariationSettings>;

export const VariationSettingsFormContent = ({
  isGenerationLoading,
}: {
  isGenerationLoading: boolean;
}) => {
  const { board } = useBoard();
  const { variationSettings, setVariationSettings } = useVariationSettings();
  const { selectedAsset } = useSelectedAsset();
  const { user } = useUser();

  const { useValue, setValues, submit, useError } =
    useForm<VariationSettingsValues>();
  const prompt = useValue((v) => v.prompt);
  const missingStyleError = useError((v) => v.selectedStyleUuid);
  const missingAssetError = useError((v) => v.selectedAssetUuid);
  let imageNotSucceededError = useError((v) => v.isSelectedImageSucceeded);
  if (missingAssetError) imageNotSucceededError = undefined;

  useEffect(() => {
    setValues({ selectedStyleUuid: board.last_used_style_uuid });
  }, [setValues, board.last_used_style_uuid]);

  useEffect(() => {
    setValues({ selectedAssetUuid: selectedAsset?.uuid });
  }, [setValues, selectedAsset?.uuid]);

  useEffect(() => {
    setValues({
      isSelectedImageSucceeded: selectedAsset?.content.status === "succeeded",
    });
  }, [setValues, selectedAsset?.content.status]);

  const { data: style } = useAppQuery<Style>({
    queryKey: board.last_used_style_uuid
      ? `styles/${board.last_used_style_uuid}`
      : null,
    enabled: !!board.last_used_style_uuid,
  });

  const [advancedSectionOpen, setAdvancedSectionOpen] = useState(false);
  useEffect(() => {
    if (variationSettings.creativity_strength_preset === "custom") {
      setAdvancedSectionOpen(true);
    }
  }, [variationSettings.creativity_strength_preset]);

  const previousImageUuid = useRef<string>();
  const [isWaitingForDescription, setIsWaitingForDescription] = useState(false);

  useEffect(() => {
    // XXX: the description and its loading state updates differently if the user selects another image.
    // To detect if the user has changed the selected image we use a ref previousImageUuid.
    // Case where the user is still on the same image:
    if (previousImageUuid.current === selectedAsset?.content.uuid) {
      if (isWaitingForDescription && selectedAsset?.content.description) {
        setValues({ prompt: selectedAsset.content.description });
        setIsWaitingForDescription(false);
      }
    }
    // Case where the user selects another image:
    else {
      previousImageUuid.current = selectedAsset?.content.uuid;
      if (selectedAsset?.content.description) {
        setValues({ prompt: selectedAsset.content.description });
        setIsWaitingForDescription(false);
      } else {
        setIsWaitingForDescription(true);
      }
    }
  }, [
    isWaitingForDescription,
    selectedAsset?.content.description,
    selectedAsset?.content.uuid,
    setValues,
  ]);

  return (
    <SettingsMenuLayout
      body={
        <div className="flex-col">
          <SettingsSectionWrapper
            name="Creativity"
            content={<VariationCreativityStrengthPresetSection />}
            infoContent={
              <ul className="text-primary body-md-default list-disc">
                <li>
                  Low Creativity is adapted for light modifications, results
                  will be close to your initial image.
                </li>
                <li>
                  High Creativity is adapted for strong modifications, results
                  can be very different from your initial image.
                </li>
              </ul>
            }
          />
          <CollapsableSettingsSectionWrapper
            name="Advanced"
            open={advancedSectionOpen}
            onOpenChange={setAdvancedSectionOpen}
            content={
              <div className="flex-col">
                <StyleIntensitySection
                  loraScale={variationSettings.lora_scale}
                  onLoraScaleChange={(loraScale: number) =>
                    setVariationSettings({
                      lora_scale: loraScale,
                    })
                  }
                />
                <SliderSection
                  min={0}
                  max={100}
                  value={Math.round(variationSettings.prompt_strength * 100)}
                  onChange={(promptStrengthPercentage) =>
                    setVariationSettings({
                      prompt_strength: promptStrengthPercentage / 100,
                      creativity_strength_preset: "custom",
                    })
                  }
                  sliderName="Creativity"
                  sliderInformationSection={
                    <div className="flex-col body-md-default text-primary gap-400">
                      <div>
                        <span className="body-md-semibold">Creativity</span> -
                        Lower values will lead to similar images. Higher values
                        will lead to more creative images.
                      </div>
                      <UrlAnimation url="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/f4520585-172c-481c-ac9e-f81768893251" />
                      <LearnMoreLink
                        url="https://pimento.crisp.help/en/article/use-an-image-as-reference-195wk5u/"
                        className="self-end"
                      />
                    </div>
                  }
                />
                {/* FIXME: We currently display this option only for photographic flux style as we didn't test it for other type of styles. This can be extended in a second phase. */}
                {style?.images_kind === "photography" &&
                  style.ml_model_architectures.includes("flux") && (
                    <SwitchSection
                      switchName="Style Enhancement"
                      switchInformationSection="Activate it to better catch your photographic style details such as colors ... This is still experimental and can lead to some prompt inconsistencies."
                      value={variationSettings.enable_flux_prompt_enhancement}
                      onChange={(enable) =>
                        setVariationSettings({
                          enable_flux_prompt_enhancement: enable,
                        })
                      }
                      isBeta
                    />
                  )}
                <TextSection
                  title="Exclude"
                  valuePlaceholder="illustration, wonderful chilli pepper, vivid colors..."
                  value={variationSettings.negative_prompt}
                  onValueChange={(negativePrompt: string) =>
                    setVariationSettings({ negative_prompt: negativePrompt })
                  }
                />
              </div>
            }
          />
          {user && isUserAllowedTo(user, "mode:debug") && (
            <div className="border-t">
              <CollapsableSettingsSectionWrapper
                name="Debug mode"
                content={
                  <div className="flex-col gap-200">
                    {style && (
                      <div className="flex-col text-primary body-md-default gap-200">
                        Model Architecture
                        <div className="flex-row-center gap-100">
                          {style.ml_model_architectures.map((architecture) => (
                            <PresetButton
                              key={architecture}
                              isSelected={
                                variationSettings.ml_model_architecture ===
                                architecture
                              }
                              onClick={() =>
                                setVariationSettings({
                                  ml_model_architecture: architecture,
                                })
                              }
                            >
                              {architecture}
                            </PresetButton>
                          ))}
                          <PresetButton
                            key="default"
                            isSelected={
                              variationSettings.ml_model_architecture ===
                              undefined
                            }
                            onClick={() =>
                              setVariationSettings({
                                ml_model_architecture: undefined,
                              })
                            }
                          >
                            Default
                          </PresetButton>
                        </div>
                      </div>
                    )}
                    <QualityPresetSection
                      value={variationSettings.quality_preset}
                      onChange={(preset) =>
                        setVariationSettings({ quality_preset: preset })
                      }
                    />
                    <div className="flex-col gap-200">
                      <SliderSection
                        value={Math.round(
                          variationSettings.guidance_scale * 100,
                        )}
                        min={0}
                        max={100}
                        onChange={(guidanceScalePercentage) =>
                          setVariationSettings({
                            guidance_scale: guidanceScalePercentage / 100,
                          })
                        }
                        sliderName="Guidance scale"
                        defaultValue={
                          variationSettings.guidance_scale
                            ? variationSettings.guidance_scale * 100
                            : undefined
                        }
                        sliderInformationSection={
                          <div className="flex-col text-primary body-md-default gap-200">
                            <div>
                              Following range will be applied to the guidance
                              scale:
                            </div>
                            <div>
                              - <span className="body-md-semibold">SDXL</span>:
                              0% : 5, 100% : 9
                            </div>
                            <div>
                              -{" "}
                              <span className="body-md-semibold">
                                Flux (Photo)
                              </span>
                              : 0% : 2.5, 100% : 4.5
                            </div>
                            <div>
                              -{" "}
                              <span className="body-md-semibold">
                                Flux (Illustration)
                              </span>
                              : 0% : 4, 100% : 6
                            </div>
                          </div>
                        }
                      />
                    </div>
                  </div>
                }
              />
            </div>
          )}
        </div>
      }
      footer={
        <GenerationBar
          isLoading={isGenerationLoading}
          onGenerate={submit}
          prompt={prompt}
          setPrompt={(newPrompt) => {
            setValues({ prompt: newPrompt });
          }}
          promptError={useError((v) => v.prompt)}
          isPromptLoading={isWaitingForDescription}
          onPromptLoadingChange={(loading) => {
            setIsWaitingForDescription(loading);
            if (!loading) setValues({ prompt: "" });
          }}
          sectionTitle="Image description"
          buttonContent="Generate variations"
          extraErrors={[
            missingAssetError,
            missingStyleError,
            imageNotSucceededError,
          ].filter((error): error is string => error !== undefined)}
        />
      }
    />
  );
};
