import classNames from "classnames";
import type { ReactNode } from "react";
import { BaseButton } from "../Button/BaseButton.tsx";

const TOGGLE_POSITIONS = ["left", "right"] as const;
type TogglePositionType = (typeof TOGGLE_POSITIONS)[number];

//  XXX: this component can be extended to more than 2 inputs. May be better to use the radix Toggle Group
export const Toggle = ({
  leftItem,
  rightItem,
  value,
  onValueChange,
}: {
  leftItem: ReactNode;
  rightItem: ReactNode;
  value: TogglePositionType;
  onValueChange: (value: TogglePositionType) => void;
}) => (
  <div className="flex-row items-center p-050 rounded-100 border">
    {TOGGLE_POSITIONS.map((position) => (
      <BaseButton
        key={position}
        className={classNames(
          value === position ? "bg-surface-primary-active" : "",
          "px-200 rounded-050",
        )}
        onClick={() => onValueChange(position)}
      >
        {position === "right" ? rightItem : leftItem}
      </BaseButton>
    ))}
  </div>
);
