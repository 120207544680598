import { Button } from "../../../components/Button/Button.tsx";
import { useDownload } from "../../../hooks/useDownload.ts";
import { useUser } from "../../../hooks/useUser.ts";
import { useAppMutation } from "../../../http/useAppMutation.ts";
import { upgradeDialogStore } from "../../../singletons/UpgradeDialog/upgradeDialogStore.ts";
import { notifier } from "../../../utils/notifier.ts";
import { trackEvent } from "../../../utils/trackEvent.ts";
import { ImageTile } from "../components/ImageTile.tsx";
import type { BoardAsset } from "../types.ts";

// FIXME: this price should be determined by the backend
const WATERMARK_COST = 30;

export const ImagePurchaseMenu = ({
  asset,
  onDownload,
}: {
  asset: BoardAsset;
  onDownload: () => void;
}) => {
  const { user } = useUser();
  const [download, isDownloading] = useDownload();
  const purchaseMutation = useAppMutation({
    path: "contents/purchase",
    invalidate: [`assets/${asset.uuid}`, "users/me"],
    onSuccess: (response: { data: { uuid: string; url: string } }) => {
      notifier.success("Watermark removed successfully!");
      download({
        url: response.data.url,
        filename: `${response.data.uuid}.png`,
        onSuccess: onDownload,
        onError: () => {
          notifier.error(
            `Failed to download image from URL ${response.data.url}`,
          );
        },
      });
    },
  }).mutation;

  if (!user) return null;
  if (asset.content.purchased_at) return null;

  return (
    <div className="flex-col w-[600px] gap-400 p-400 text-primary bg-surface-primary-rest">
      <div className="body-lg-semibold">Download image without watermark</div>
      <div className="flex-row bg-surface-inverse-rest p-200 rounded-200">
        <div className="flex-col-center w-[50%]">
          <div className="text-white body-md-semibold">Current asset</div>
          <div className="relative">
            <ImageTile
              image={asset.content}
              fit="width"
              srcDimension="medium256"
            />
            <div
              className="absolute inset-0 opacity-25"
              style={{
                backgroundImage:
                  "url('https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/watermark/watermark_45_degrees.png')",
                backgroundRepeat: "repeat",
                backgroundSize: "50px 50px",
              }}
            />
          </div>
        </div>
        <div className="flex-col-center w-[50%]">
          <div className="text-white body-md-semibold">
            Original - without watermark
          </div>
          <div className="relative">
            <ImageTile
              image={asset.content}
              fit="width"
              srcDimension="medium256"
            />
          </div>
        </div>
      </div>
      {user.total_credits_remaining_amount < WATERMARK_COST ? (
        <div className="flex-col gap-200 items-end">
          <div className="body-md-default text-secondary">
            {user.total_credits_remaining_amount < WATERMARK_COST ? (
              <>
                You need {WATERMARK_COST} credits download an image without
                watermark. You have {user.total_credits_remaining_amount}{" "}
                credits left.
              </>
            ) : (
              <>{user.total_credits_remaining_amount} credits remaining.</>
            )}
          </div>
          <div className="flex-row justify-end items-center gap-200">
            <DownloadWithWatermarkButton
              asset={asset}
              onDownload={onDownload}
            />
            <Button
              onClick={() => {
                upgradeDialogStore.openDialog();
                trackEvent("download_content:upgrade_plan", {
                  content_uuid: asset.content.uuid,
                });
              }}
              size="md"
            >
              Upgrade plan
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex-col items-end gap-200">
          <div className="body-md-default text-secondary">
            {user.total_credits_remaining_amount} credits remaining.
          </div>
          {/* FIXME: the gap should be -md, but the `base` variant of the */}
          {/* button does not have the correct padding */}
          <div className="flex-row items-center justify-end gap-200">
            <DownloadWithWatermarkButton
              asset={asset}
              onDownload={onDownload}
            />
            <Button
              loading={purchaseMutation.isLoading || isDownloading}
              onClick={() => {
                purchaseMutation.mutate({ uuid: asset.content.uuid });
                trackEvent("download_content:without_watermark", {
                  content_uuid: asset.content.uuid,
                });
              }}
              size="md"
            >
              Download for {WATERMARK_COST} credits
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

const DownloadWithWatermarkButton = ({
  asset,
  onDownload,
}: {
  asset: BoardAsset;
  onDownload: () => void;
}) => {
  const [download, isDownloading] = useDownload();
  return (
    <Button
      variant="tertiary"
      size="md"
      loading={isDownloading}
      onClick={() => {
        download({
          url: asset.content.url,
          filename: `${asset.content.uuid}.png`,
          onSuccess: onDownload,
          onError: () => {
            notifier.error(
              `Failed to download image from URL ${asset.content.url}`,
            );
          },
        });
        trackEvent("download_content:with_watermark", {
          content_uuid: asset.content.uuid,
        });
      }}
    >
      Download with watermark
    </Button>
  );
};
