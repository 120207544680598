import { PresetButton } from "../../../../components/PresetButton.tsx";
import { IMAGINATION_STRENGTH_PRESET } from "./constants.ts";
import { useUpscaleSettings } from "./hooks/useUpscaleSettings.ts";

export const ImaginationStrengthSection = () => {
  const { upscaleSettings, setUpscaleSettings } = useUpscaleSettings();

  return (
    <div className="flex-row-center gap-100">
      {IMAGINATION_STRENGTH_PRESET.map((preset) => (
        <PresetButton
          key={preset}
          isSelected={upscaleSettings.imagination_strength_preset === preset}
          onClick={() =>
            setUpscaleSettings({ imagination_strength_preset: preset })
          }
        >
          {preset}
        </PresetButton>
      ))}
    </div>
  );
};
