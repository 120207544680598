import * as ProgressPrimitive from "@radix-ui/react-progress";
import classNames from "classnames";

export const Progress = ({
  value,
  rootClassName,
  indicatorClassName,
}: {
  value: number;
  rootClassName?: string;
  indicatorClassName?: string;
}) => (
  <ProgressPrimitive.Root
    className={classNames(
      "h-[5px] w-full bg-surface-primary-rest overflow-hidden",
      rootClassName,
    )}
    value={value}
  >
    <ProgressPrimitive.Indicator
      className={classNames("h-[5px] bg-pimento-blue", indicatorClassName)}
      style={{
        transform: `translateX(-${100 - value * 100}%)`,
        transition: "transform 660ms cubic-bezier(0.65, 0, 0.35, 1)",
      }}
    />
  </ProgressPrimitive.Root>
);
