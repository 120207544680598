import classNames from "classnames";
import type { ReactNode } from "react";

export const SectionDetails = ({
  children,
  name,
  variant = "light",
  nextToTitleComponent,
}: {
  children: ReactNode;
  name: string;
  variant?: "light" | "dark";
  nextToTitleComponent?: ReactNode;
}) => (
  <div className={classNames("flex-col justify-start gap-150 px-400 py-300")}>
    <div className="flex-row items-center justify-between">
      <div
        className={classNames(
          "h-600 label-md-semibold content-center",
          variant === "dark" ? "text-inverse-primary" : "text-primary",
        )}
      >
        {name}
      </div>
      {nextToTitleComponent !== undefined && nextToTitleComponent}
    </div>
    <div className="flex-col justify-center">{children}</div>
  </div>
);

export const ItemSectionDetails = ({
  name,
  variant = "light",
  children,
}: {
  name: string;
  variant?: "light" | "dark";
  children: ReactNode;
}) => (
  <div className="grid grid-cols-2 grid-flow-row py-150 gap-200">
    <div
      className={classNames(
        "label-md-default",
        variant === "dark" ? "text-inverse-secondary" : "text-secondary",
      )}
    >
      {name}
    </div>
    <div>{children}</div>
  </div>
);

export const TextItemSectionDetails = ({
  name,
  value,
  variant = "light",
}: {
  name: string;
  value: string;
  variant?: "light" | "dark";
}) => (
  <ItemSectionDetails name={name} variant={variant}>
    <div
      className={classNames(
        "label-md-default first-letter:uppercase overflow-x-hidden text-ellipsis",
        variant === "dark" ? "text-inverse-primary" : "text-primary",
      )}
    >
      {value}
    </div>
  </ItemSectionDetails>
);
