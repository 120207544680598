import { useQuery } from "@tanstack/react-query";
import { createClient } from "contentful";
import { z } from "zod";
import { Carousel } from "../../../components/Carousel/Carousel.tsx";

const zContentfulTutorial = z.object({
  fields: z.object({
    title: z.string(),
    link: z.string(),
    order: z.number(),
    coverImage: z.object({
      fields: z.object({
        file: z.object({
          url: z.string(),
        }),
      }),
    }),
  }),
});

export const HowToSection = () => {
  const { data: tutorials } = useQuery({
    queryKey: ["contentful-tutorials"],
    onError: (error: Error) => {
      console.error(
        `Unable to retrieve data from Contentful. Got error: ${error.message}`,
      );
    },
    queryFn: async () => {
      const contentfulClient = createClient({
        accessToken: "8z3yTRjrNvjXoDFdLW-g6ybMa00aNhGL9qf6dnY6Cww",
        space: "3k7t73zewshw",
      });
      const fetchedResults = await contentfulClient.getEntries({
        content_type: "tutorial",
        select: [
          "fields.title",
          "fields.link",
          "fields.order",
          "fields.coverImage",
        ],
      });
      return z
        .array(zContentfulTutorial)
        .parse(fetchedResults.items as any[])
        .map((item) => ({
          title: item.fields.title,
          link: item.fields.link,
          imageUrl: item.fields.coverImage.fields.file.url,
          order: item.fields.order,
        }))
        .sortAsc((item) => item.order);
    },
  });
  if (!tutorials) return null;
  if (tutorials.length === 0) return null;

  return (
    <div className="flex-col">
      <div className="min-h-1600 px-800 py-400 heading-xl flex-row items-center">
        Guides
      </div>
      <Carousel
        items={tutorials.map((tutorial, index) => (
          <a key={index} href={tutorial.link} target="_blank">
            <div
              style={{
                background: `linear-gradient(180deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.70) 100%), url(${tutorial.imageUrl}) center center/cover no-repeat`,
              }}
              className="flex-col h-[240px] w-[240px] object-cover object-center hover:opacity-50 justify-end"
            >
              <span className="px-800 mb-500 body-lg-semibold text-white">
                {tutorial.title}
              </span>
            </div>
          </a>
        ))}
        className="px-800 gap-400"
        leftButtonClassName="top-[96px] left-1000"
        rightButtonClassName="top-[96px] right-1000"
      />
    </div>
  );
};
